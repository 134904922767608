import styled from 'styled-components';
import StyledButton from '../../../components/Button/button.styled';

export const ExpandedHeader = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  color: ${({ theme }) => theme.colors.text};
`;

export const ChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => theme.spacings.medium};

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    grid-template-columns: 1fr;
  }
`;

export const ChannelCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

export const ChannelName = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  color: ${({ theme }) => theme.colors.text};
`;

export const ChannelDetails = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

export const DetailRow = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.small};
  color: ${({ theme }) => theme.colors.text};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const EmptyChannelMessage = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacings.large} 0;
`;

export const EmptyMessage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ChannelButton = styled(StyledButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

export const AddButton = styled(StyledButton)`
  min-width: 150px;
`;

export const ChannelCard = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacings.medium};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.small};
  border: 1px solid ${({ theme }) => theme.colors.divider};

  &:hover {
    border-color: ${({ theme }) => theme.colors.hover};
  }
`; 