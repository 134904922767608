import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Card, Skeleton, Input, Select, Button, Badge, Tooltip, Modal, Space, Dropdown } from 'antd';
import { EditOutlined, FilterOutlined, SearchOutlined, PlusOutlined, InfoCircleOutlined, MoreOutlined, ClockCircleOutlined, CustomerServiceOutlined, DesktopOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import type { TableProps, TablePaginationConfig } from 'antd';
import { fetchProductsRequest, updateProductStatusRequest, deleteProductRequest, bulkUpdateStatusRequest, bulkDeleteRequest } from './products.action';
import { ProductsContainer, TableWrapper, HeaderSection, SearchSection, ExpandedContent } from './products.styled';
import { IProduct, IProductsState, IFetchProductsRequest, IChannel } from './products.d';
import { useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd';
import { BulkActions } from './components/BulkActions';
import { ProductTable } from './components/ProductTable';
import { SearchFilters } from './components/SearchFilters';

// Kategori listesini interface ile tanımlayalım
interface ICategory {
  id: string;
  name: string;
}

// Kategorileri sabit olarak tanımlayalım
const categories: ICategory[] = [
  { id: 'electronics', name: 'Elektronik' },
  { id: 'home', name: 'Ev & Yaşam' },
  { id: 'fashion', name: 'Moda' },
  { id: 'beauty', name: 'Güzellik & Kişisel Bakım' },
  { id: 'sports', name: 'Spor & Outdoor' }
] as const;

const channels = [
  { id: 'trendyol', name: 'Trendyol' },
  { id: 'hepsiburada', name: 'Hepsiburada' },
  { id: 'amazon', name: 'Amazon' },
  { id: 'n11', name: 'N11' },
] as const;

const getProductIcon = (imageType: string, rotation: number = 0) => {
  const style = { fontSize: '64px', transform: `rotate(${rotation}deg)` };
  
  switch (imageType) {
    case 'watch':
      return <ClockCircleOutlined style={style} />;
    case 'headphone':
      return <CustomerServiceOutlined style={style} />;
    case 'desktop':
      return <DesktopOutlined style={style} />;
    default:
      return null;
  }
};

const ProductsComponent: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation('products');
  const navigate = useNavigate();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [searchDebounced] = useDebounce(500);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const hasSelected = selectedRowKeys.length > 0;

  const { products, loading, pagination, filters } = useSelector((state: { products: IProductsState }) => ({
    products: state.products.products,
    loading: state.products.loading,
    pagination: state.products.pagination,
    filters: state.products.filters
  }));

  // Filtreleme ve arama için state'ler
  const [searchValue, setSearchValue] = useState(filters.search);
  const [selectedCategory, setSelectedCategory] = useState(filters.category);
  const [selectedStatus, setSelectedStatus] = useState(filters.status);

  const fetchProducts = useCallback((params: IFetchProductsRequest) => {
    dispatch(fetchProductsRequest(params));
  }, [dispatch]);

  // İlk yükleme
  useEffect(() => {
    fetchProducts({
      page: pagination.current,
      pageSize: pagination.pageSize,
      search: filters.search,
      category: filters.category,
      status: filters.status
    });
  }, []);

  // Arama değişikliğini handle et
  const handleSearch = useCallback((value: string) => {
    setSearchValue(value);
    searchDebounced(() => {
      fetchProducts({
        ...pagination,
        page: 1, // Arama yapıldığında ilk sayfaya dön
        search: value,
        category: selectedCategory,
        status: selectedStatus
      });
    });
  }, [pagination, selectedCategory, selectedStatus, searchDebounced, fetchProducts]);

  // Kategori değişikliğini handle et
  const handleCategoryChange = useCallback((value: string) => {
    setSelectedCategory(value);
    fetchProducts({
      ...pagination,
      page: 1,
      search: searchValue,
      category: value,
      status: selectedStatus
    });
  }, [pagination, searchValue, selectedStatus, fetchProducts]);

  // Durum değişikliğini handle et
  const handleStatusChange = useCallback((value: 'active' | 'inactive' | undefined) => {
    setSelectedStatus(value);
    fetchProducts({
      ...pagination,
      page: 1,
      search: searchValue,
      category: selectedCategory,
      status: value
    });
  }, [pagination, searchValue, selectedCategory, fetchProducts]);

  // Sayfalama değişikliğini handle et
  const handleTableChange = useCallback((pagination: TablePaginationConfig) => {
    fetchProducts({
      page: pagination.current || 1,
      pageSize: pagination.pageSize || 10,
      search: searchValue,
      category: selectedCategory,
      status: selectedStatus
    });
  }, [searchValue, selectedCategory, selectedStatus, fetchProducts]);

  const renderChannelCell = useCallback((channelData: IChannel | undefined, channelId: string) => {
    if (!channelData) {
      return (
        <Badge 
          status="default" 
          text={t('channel.cell.status.unlisted')}
        />
      );
    }

    return (
      <div 
        style={{ 
          backgroundColor: channelData.active ? 'rgba(82, 196, 26, 0.1)' : 'rgba(255, 77, 79, 0.1)',
          padding: '12px',
          borderRadius: '6px',
          textAlign: 'center'
        }}
      >
        <div>
          <Badge 
            status={channelData.active ? 'success' : 'error'} 
            text={channelData.active ? 
              t('channel.cell.status.active') : 
              t('channel.cell.status.inactive')} 
          />
        </div>
        <div style={{ 
          marginTop: '8px', 
          fontSize: '14px', 
          fontWeight: 'bold' 
        }}>
          ₺{channelData.price.toFixed(2)}
        </div>
        <div style={{ 
          fontSize: '12px', 
          color: '#666',
          marginTop: '4px'
        }}>
          ({channelData.stock})
        </div>
      </div>
    );
  }, [t]);

  const handleExpand = (expanded: boolean, record: IProduct) => {
    setExpandedRowKeys(expanded ? [record.id] : []);
  };

  const handleNewProduct = useCallback(() => {
    navigate('/products/new');
  }, [navigate]);

  const handleBulkUpdateStatus = useCallback((status: 'active' | 'inactive') => {
    Modal.confirm({
      title: t('modal.bulkUpdateStatus.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('modal.bulkUpdateStatus.content', { count: selectedRowKeys.length }),
      okText: t('modal.bulkUpdateStatus.ok'),
      cancelText: t('modal.common.cancel'),
      onOk: () => {
        dispatch(bulkUpdateStatusRequest({ ids: selectedRowKeys, status }));
        setSelectedRowKeys([]);
      }
    });
  }, [dispatch, selectedRowKeys, t]);

  const handleBulkDelete = useCallback(() => {
    Modal.confirm({
      title: t('modal.bulkDelete.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('modal.bulkDelete.content', { count: selectedRowKeys.length }),
      okText: t('modal.bulkDelete.ok'),
      okType: 'danger',
      cancelText: t('modal.common.cancel'),
      onOk: () => {
        dispatch(bulkDeleteRequest({ ids: selectedRowKeys }));
        setSelectedRowKeys([]);
      }
    });
  }, [dispatch, selectedRowKeys, t]);

  const columns = useMemo(() => [
    {
      title: t('table.product'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left' as const,
      width: 200,
    },
    {
      title: t('table.category'),
      dataIndex: 'category',
      key: 'category',
      width: 120,
      render: (category: string) => (
        <Badge 
          color={category === 'Elektronik' ? 'blue' : 'green'} 
          text={category} 
        />
      ),
    },
    {
      title: t('table.basePrice'),
      dataIndex: 'price',
      key: 'price',
      width: 120,
      render: (price: number) => (
        <span>₺{price.toFixed(2)}</span>
      ),
    },
    {
      title: t('table.stock'),
      dataIndex: 'stock',
      key: 'stock',
      width: 80,
      align: 'center' as const,
    },
    ...channels.map(channel => ({
      title: t(`table.channels.${channel.id}`),
      key: channel.id,
      width: 150,
      render: (_: unknown, record: IProduct) => {
        const channelData = record.channels.find((c: IChannel) => c.id === channel.id);
        return renderChannelCell(channelData, channel.id);
      },
    })),
    {
      title: t('table.actions'),
      key: 'actions',
      fixed: 'right' as const,
      width: 120,
      render: (_: unknown, record: IProduct) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          <Tooltip title={t('actions.info')}>
            <Button 
              icon={<InfoCircleOutlined />} 
              type="text"
              onClick={() => handleExpand(!expandedRowKeys.includes(record.id), record)}
            />
          </Tooltip>
          <Tooltip title={t('actions.edit')}>
            <Button 
              icon={<EditOutlined />} 
              type="text"
              onClick={() => navigate(`/products/${record.id}`)}
            />
          </Tooltip>
          <Tooltip title={t('actions.more')}>
            <Button 
              icon={<MoreOutlined />} 
              type="text"
            />
          </Tooltip>
        </div>
      ),
    },
  ], [t, navigate, expandedRowKeys, handleExpand, renderChannelCell]);

  const renderProductImages = (imageType: string) => {
    return [0, 90, 180, 270].map((rotation) => (
      getProductIcon(imageType, rotation)
    ));
  };

  return (
    <ProductsContainer>
      <HeaderSection>
        <h1>{t('header')}</h1>
        <SearchSection>
          <SearchFilters
            searchValue={searchValue}
            selectedCategory={selectedCategory}
            selectedStatus={selectedStatus}
            onSearch={handleSearch}
            onCategoryChange={handleCategoryChange}
            onStatusChange={handleStatusChange}
            categories={categories}
          />
          <BulkActions
            selectedRowKeys={selectedRowKeys}
            onBulkUpdateStatus={handleBulkUpdateStatus}
            onBulkDelete={handleBulkDelete}
          />
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={() => navigate('/products/new')}
          >
            {t('product.add')}
          </Button>
        </SearchSection>
      </HeaderSection>

      <ProductTable
        products={products}
        loading={loading}
        pagination={pagination}
        selectedRowKeys={selectedRowKeys}
        expandedRowKeys={expandedRowKeys}
        onSelectChange={(selectedKeys) => setSelectedRowKeys(selectedKeys)}
        onExpand={handleExpand}
        onTableChange={handleTableChange}
        channels={channels}
      />
    </ProductsContainer>
  );
});

ProductsComponent.displayName = 'ProductsComponent';

// useDebounce hook'u ekleyelim
function useDebounce(delay: number) {
  const [debouncedFunction, setDebouncedFunction] = useState<NodeJS.Timeout>();

  const debounce = useCallback(
    (func: () => void) => {
      if (debouncedFunction) {
        clearTimeout(debouncedFunction);
      }
      setDebouncedFunction(setTimeout(func, delay));
    },
    [delay]
  );

  return [debounce] as const;
}

// ProductsComponent'i doğrudan export edelim
export const Products = ProductsComponent;
