import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../redux/reducers';

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const user = useSelector((state: IGlobalState) => state.LOGIN.user);
  const location = useLocation();

  if (user) {
    // Kullanıcı zaten giriş yapmışsa, dashboard'a yönlendir
    return <Navigate to="/dashboard" replace />;
  }

  // Kullanıcı giriş yapmamışsa, login sayfasını göster
  return <>{children}</>;
};

export default AuthWrapper;
