import { ReactNode } from 'react';

export interface IChannel {
  id: string;
  active: boolean;
  price: number;
  stock: number;
  priceRule: string;
  stockRule: string;
}

export interface IVariantOption {
  id: string;
  name: string;
  value: string;
}

export interface IVariantImage {
  id: string;
  url: string;
  isDefault: boolean;
  order: number;
  tags?: string[];
}

export interface IVariant {
  id?: string;
  sku: string;
  price: number;
  stock: number;
  isActive: boolean;
  options: IVariantOption[];
  images: IVariantImage[];
}

export interface IProductForm {
  name: string;
  description: string;
  category: string;
  price: number;
  stock: number;
  status: 'active' | 'inactive';
  channels: IChannel[];
  features: string[];
  metadata: IMetadata;
  attributeValues: IAttributeValue[];
  variants: IVariant[];
  hasVariants: boolean;
  variantOptions: {
    [key: string]: string[]; // örn: { "color": ["red", "blue"], "size": ["S", "M", "L"] }
  };
}

export interface IMetadata {
  id?: string;
  channelId: string;
  familyId: string;
  type: 'Product' | 'ProductModel' | 'ProductVariant' | 'ProductBundle';
  origin: string;
  images: string[];
  isActive: boolean;
  createdAt?: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
}

export interface IAttributeValue {
  id?: string;
  attributeId: string;
  metadataId: string;
  value: string;
  attribute?: {
    id: string;
    name: string;
    type: string;
    defaultValue?: string;
  };
}

export interface IProductDetailState {
  product: IProductForm | null;
  loadingStates: {
    main: boolean;
    saving: boolean;
    uploadingImages: boolean;
    deletingImage: boolean;
    createMetadata: boolean;
    addAttributeValue: boolean;
    addImage: boolean;
    manageActive: boolean;
  };
  error: string | null;
  metadata?: {
    id: string;
    isActive: boolean;
    images: string[];
  };
  attributeValues: Array<{
    attributeId: string;
    value: string;
  }>;
}

export interface IProductDetailProps {
  mode?: 'create' | 'edit';
  id?: string;
}

export const initialProductForm: IProductForm = {
  name: '',
  description: '',
  category: '',
  price: 0,
  stock: 0,
  status: 'inactive',
  channels: [],
  features: [],
  metadata: {
    channelId: '',
    familyId: '',
    type: 'Product',
    origin: '',
    images: [],
    isActive: false,
    createdBy: '',
  },
  attributeValues: [],
  variants: [],
  hasVariants: false,
  variantOptions: {}
};

export interface ICreateMetadataRequest {
  tenantId: string;
  channelId: string;
  familyId: string;
  origin: string;
  createdBy: string;
}

export interface IMetadataResponse {
  metadata: {
    id: string;
    channelId: string;
    familyId: string;
    type: string;
    origin: string;
    createdBy: string;
    createdAt: string;
    updatedBy?: string;
    updatedAt?: string;
    isActive: boolean;
    images: string[];
  };
}

export interface IAddAttributeValueRequest {
  tenantId: string;
  attributeId: string;
  metadataId: string;
  value: string;
}

export interface IAttributeValueResponse {
  attributeValue: {
    id: string;
    attributeId: string;
    metadataId: string;
    value: string;
  };
}

export interface IAddImageRequest {
  tenantId: string;
  metadataId: string;
  image: string;
  updatedBy: string;
}

export interface IManageActiveRequest {
  tenantId: string;
  metadataId: string;
  isActive: boolean;
  updatedBy: string;
}

export const dummyProduct: IProductForm = {
  name: 'iPhone 14 Pro Max',
  description: 'Apple iPhone 14 Pro Max 256GB Uzay Siyahı',
  category: 'electronics',
  price: 64999,
  stock: 100,
  status: 'active',
  channels: [
    {
      id: 'trendyol',
      active: true,
      price: 65999,
      stock: 95,
      priceRule: 'basePrice * 1.02',
      stockRule: 'baseStock - 5'
    },
    {
      id: 'hepsiburada',
      active: true,
      price: 66499,
      stock: 90,
      priceRule: 'basePrice * 1.03',
      stockRule: 'baseStock - 10'
    }
  ],
  features: [
    '6.7 inç Super Retina XDR OLED ekran',
    'A16 Bionic çip',
    '48MP Ana Kamera',
    'Dynamic Island teknolojisi',
    'Always-On ekran'
  ],
  metadata: {
    channelId: 'TR',
    familyId: 'PHONES',
    type: 'Product',
    origin: 'Turkey',
    images: [
      'https://example.com/iphone14-1.jpg',
      'https://example.com/iphone14-2.jpg',
      'https://example.com/iphone14-3.jpg'
    ],
    isActive: true,
    createdBy: 'admin',
  },
  attributeValues: [
    {
      attributeId: 'brand',
      metadataId: 'meta123',
      value: 'Apple'
    },
    {
      attributeId: 'model',
      metadataId: 'meta123',
      value: 'iPhone 14 Pro Max'
    },
    {
      attributeId: 'color',
      metadataId: 'meta123',
      value: 'Space Black'
    }
  ],
  variants: [
    {
      id: 'var1',
      sku: 'IP14PM-256-SB',
      price: 64999,
      stock: 50,
      isActive: true,
      options: [
        { id: 'opt1', name: 'storage', value: '256GB' },
        { id: 'opt2', name: 'color', value: 'Space Black' }
      ],
      images: [
        {
          id: 'img1',
          url: 'https://example.com/iphone14-black-1.jpg',
          isDefault: true,
          order: 1
        }
      ]
    },
    {
      id: 'var2',
      sku: 'IP14PM-512-SB',
      price: 74999,
      stock: 30,
      isActive: true,
      options: [
        { id: 'opt3', name: 'storage', value: '512GB' },
        { id: 'opt4', name: 'color', value: 'Space Black' }
      ],
      images: [
        {
          id: 'img2',
          url: 'https://example.com/iphone14-black-2.jpg',
          isDefault: true,
          order: 1
        }
      ]
    }
  ],
  hasVariants: true,
  variantOptions: {
    storage: ['256GB', '512GB', '1TB'],
    color: ['Space Black', 'Deep Purple', 'Gold', 'Silver']
  }
}; 