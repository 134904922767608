import { createReducer } from '@reduxjs/toolkit';
import * as actions from './productDetail.action';
import { IProductDetailState, dummyProduct } from './productDetail.d';

const initialState: IProductDetailState = {
  product: dummyProduct,
  loadingStates: {
    main: false,
    saving: false,
    uploadingImages: false,
    deletingImage: false,
    createMetadata: false,
    addAttributeValue: false,
    addImage: false,
    manageActive: false
  },
  error: null,
  metadata: undefined,
  attributeValues: []
};

export const productDetailReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.fetchProductDetailRequest, (state) => {
      state.loadingStates.main = true;
      state.error = null;
    })
    .addCase(actions.fetchProductDetailSuccess, (state, action) => {
      state.loadingStates.main = false;
      state.product = action.payload;
      state.error = null;
    })
    .addCase(actions.fetchProductDetailFailure, (state, action) => {
      state.loadingStates.main = false;
      state.error = action.payload;
    })
    .addCase(actions.saveProductRequest, (state) => {
      state.loadingStates.saving = true;
      state.error = null;
    })
    .addCase(actions.saveProductSuccess, (state, action) => {
      state.loadingStates.saving = false;
      state.product = action.payload;
      state.error = null;
    })
    .addCase(actions.saveProductFailure, (state, action) => {
      state.loadingStates.saving = false;
      state.error = action.payload;
    })
    .addCase(actions.uploadImageRequest, (state) => {
      state.loadingStates.uploadingImages = true;
      state.error = null;
    })
    .addCase(actions.uploadImageSuccess, (state, action) => {
      state.loadingStates.uploadingImages = false;
      if (state.product?.metadata) {
        state.product.metadata.images = [...state.product.metadata.images, action.payload];
      }
      state.error = null;
    })
    .addCase(actions.uploadImageFailure, (state, action) => {
      state.loadingStates.uploadingImages = false;
      state.error = action.payload;
    })
    .addCase(actions.deleteImageRequest, (state) => {
      state.loadingStates.deletingImage = true;
      state.error = null;
    })
    .addCase(actions.deleteImageSuccess, (state, action) => {
      state.loadingStates.deletingImage = false;
      if (state.product?.metadata) {
        state.product.metadata.images = state.product.metadata.images.filter(
          (img) => img !== action.payload
        );
      }
      state.error = null;
    })
    .addCase(actions.deleteImageFailure, (state, action) => {
      state.loadingStates.deletingImage = false;
      state.error = action.payload;
    })
    .addCase(actions.createMetadataRequest, (state) => {
      state.loadingStates.createMetadata = true;
      state.error = null;
    })
    .addCase(actions.createMetadataSuccess, (state, action) => {
      state.loadingStates.createMetadata = false;
      state.metadata = {
        id: action.payload.metadata.id,
        isActive: action.payload.metadata.isActive,
        images: action.payload.metadata.images
      };
    })
    .addCase(actions.createMetadataFailure, (state, action) => {
      state.loadingStates.createMetadata = false;
      state.error = action.payload;
    })
    .addCase(actions.addAttributeValueRequest, (state) => {
      state.loadingStates.addAttributeValue = true;
      state.error = null;
    })
    .addCase(actions.addAttributeValueSuccess, (state, action) => {
      state.loadingStates.addAttributeValue = false;
      state.attributeValues.push({
        attributeId: action.payload.attributeValue.attributeId,
        value: action.payload.attributeValue.value
      });
    })
    .addCase(actions.addAttributeValueFailure, (state, action) => {
      state.loadingStates.addAttributeValue = false;
      state.error = action.payload;
    })
    .addCase(actions.addImageRequest, (state) => {
      state.loadingStates.addImage = true;
      state.error = null;
    })
    .addCase(actions.addImageSuccess, (state, action) => {
      state.loadingStates.addImage = false;
      if (state.metadata) {
        state.metadata.images.push(action.payload.imageUrl);
      }
    })
    .addCase(actions.addImageFailure, (state, action) => {
      state.loadingStates.addImage = false;
      state.error = action.payload;
    })
    .addCase(actions.manageActiveRequest, (state) => {
      state.loadingStates.manageActive = true;
      state.error = null;
    })
    .addCase(actions.manageActiveSuccess, (state, action) => {
      state.loadingStates.manageActive = false;
      if (state.metadata) {
        state.metadata.isActive = action.payload.isActive;
      }
    })
    .addCase(actions.manageActiveFailure, (state, action) => {
      state.loadingStates.manageActive = false;
      state.error = action.payload;
    });
});

export default productDetailReducer; 