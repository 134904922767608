import { configureAppStore, AppState } from './configureStore';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { Persistor } from 'redux-persist';

// Initialize Store
const { store, persistor } = configureAppStore();

// Define RootState using the new AppState type
export type RootState = AppState;

// Define AppDispatch Type
export type AppDispatch = typeof store.dispatch;

// Custom Hooks for Dispatch and Selector with Typed Hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Export Store and Persistor
export { store, persistor };
