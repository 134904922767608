import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, Lock, Eye, EyeOff, AlertCircle, CheckCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Text } from '../../../components/Design/design.styled';
import { resetPasswordActionCreator, resetLoginStateActionCreator } from '../login.action';
import { resetSchema } from '../login.validations';
import { useFormik } from 'formik';
import { TextInputLazy } from '../../../components/Input/TextInput/text-input.lazy';
import { ModernBackground } from '../UserLogin/ModernBackground';
import {
  ResetContainer,
  ResetFormWrapper,
  ResetForm,
  FormContainer,
  Title,
  Description,
  ActionButton,
  LinkContainer,
  StyledLink
} from './resetPassword.styled';
import { jwtDecode } from 'jwt-decode';
import { SET_NOTIFICATION } from '../../../components/Notification/notification.actions';
import styled from 'styled-components';
import { ILoginState } from '../login.reducer';
import { IGlobalState } from '../../../redux/reducers';

// Success ikonu için styled component ekleyelim
const SuccessIcon = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.success};

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation('login');
  const theme = useTheme();
  const { isFetching, error } = useSelector<IGlobalState, ILoginState>(state => state.LOGIN);

  // URL'den token'ı al
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  // Component mount olduğunda state'i sıfırla
  useEffect(() => {
    dispatch(resetLoginStateActionCreator());
    return () => {
      // Component unmount olduğunda da state'i sıfırla
      dispatch(resetLoginStateActionCreator());
    };
  }, [dispatch]);

  // Token kontrolü
  useEffect(() => {
    if (!token) {
      navigate('/sifre-sifirlama');
      return;
    }

    try {
      const decodedToken = jwtDecode<{ exp: number }>(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        navigate('/sifre-sifirlama');
        dispatch(SET_NOTIFICATION({
          message: t('tokenExpired'),
          type: 'error',
          description: t('requestNewResetLink'),
        }));
      }
    } catch (error) {
      navigate('/sifre-sifirlama');
      dispatch(SET_NOTIFICATION({
        message: t('invalidToken'),
        type: 'error',
        description: t('requestNewResetLink'),
      }));
    }
  }, [token, navigate, dispatch, t]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetSchema,
    onSubmit: (values) => {
      if (!token) return;
      dispatch(resetPasswordActionCreator(values.password, token));
    },
  });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  // Hata mesajını kullanıcı dostu hale getiren fonksiyon
  const getErrorMessage = (error: string): string => {
    if (error.toLowerCase().includes('no refresh token available')) {
      return t('resetLinkExpired');
    }
    if (error.toLowerCase().includes('invalid token')) {
      return t('invalidToken');
    }
    if (error.toLowerCase().includes('expired')) {
      return t('tokenExpired');
    }
    return error;
  };

  // Form state'ini sıfırlama fonksiyonu
  const resetForm = () => {
    formik.resetForm();
    dispatch(resetLoginStateActionCreator());
  };

  return (
    <ResetContainer>
      <ModernBackground />
      <ResetFormWrapper>
        <ResetForm
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <AnimatePresence mode="wait">
            {!error && !isFetching ? (
              <motion.div
                key="form"
                variants={container}
                initial="hidden"
                animate="show"
                exit={{ opacity: 0, y: -20 }}
              >
                <Title variants={item}>{t('resetPasswordTitle')}</Title>
                <Description variants={item}>{t('resetPasswordDescription')}</Description>

                <FormContainer onSubmit={formik.handleSubmit}>
                  <div className="input-wrapper">
                    <TextInputLazy
                      label={t('password')}
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      required
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && formik.errors.password}
                      icon="lock"
                      aria-label={t('password')}
                      endAdornment={
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label={showPassword ? t('hidePassword') : t('showPassword')}
                        >
                          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                        </button>
                      }
                    />
                  </div>

                  <div className="input-wrapper">
                    <TextInputLazy
                      label={t('confirmPassword')}
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      required
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={formik.touched.confirmPassword && formik.errors.confirmPassword}
                      icon="lock"
                      aria-label={t('confirmPassword')}
                      endAdornment={
                        <button
                          type="button"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          aria-label={showConfirmPassword ? t('hidePassword') : t('showPassword')}
                        >
                          {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                        </button>
                      }
                    />
                  </div>

                  <motion.div variants={item}>
                    <ActionButton
                      type="submit"
                      disabled={isFetching || formik.isSubmitting}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {isFetching ? t('resettingPassword') : t('resetPassword')}
                      {!isFetching && <ArrowRight size={20} />}
                    </ActionButton>
                  </motion.div>
                </FormContainer>
              </motion.div>
            ) : error ? (
              <motion.div
                key="error"
                variants={container}
                initial="hidden"
                animate="show"
                exit={{ opacity: 0, y: -20 }}
              >
                <SuccessIcon variants={item} style={{ color: theme.colors.error }}>
                  <AlertCircle />
                </SuccessIcon>
                
                <Title variants={item}>{t('resetPasswordFailed')}</Title>
                <Description variants={item}>{getErrorMessage(error)}</Description>

                <motion.div variants={item}>
                  {error.toLowerCase().includes('no refresh token available') || 
                   error.toLowerCase().includes('invalid token') || 
                   error.toLowerCase().includes('expired') ? (
                    <ActionButton
                      onClick={() => navigate('/sifre-sifirlama')}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <span>{t('requestNewLink')}</span>
                      <ArrowRight size={20} />
                    </ActionButton>
                  ) : (
                    <ActionButton
                      onClick={resetForm}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <span>{t('tryAgain')}</span>
                      <ArrowRight size={20} />
                    </ActionButton>
                  )}
                </motion.div>
              </motion.div>
            ) : null}
          </AnimatePresence>

          <LinkContainer>
            <Text size="small" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {t('rememberPassword')}{' '}
              <StyledLink to="/giris">
                {t('loginInstead')}
              </StyledLink>
            </Text>
          </LinkContainer>
        </ResetForm>
      </ResetFormWrapper>
    </ResetContainer>
  );
};

export default ResetPassword;
