import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';
import { Card, Badge, Tabs, Input, Select, Table, Switch } from 'antd';
import { BoxPlotOutlined, ShoppingCartOutlined, DollarOutlined, LineChartOutlined, PlusOutlined } from '@ant-design/icons';
import { ChannelDetailContainer, ChannelHeader, ChannelMain, ChannelGrid, ChannelCardContent, TabContent, FlexContainer } from './channelDetail.styled';
import { Button, H1, H2, H3, Text, Bold } from '../../components/Design';
import anime from 'animejs';

const { TabPane } = Tabs;
const { Option } = Select;

const ChannelDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('channels');
  const channel = useSelector((state: IGlobalState) => 
    state.channels.channels.find(c => c.id === Number(id))
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current && gridRef.current && tabsRef.current) {
      // Header animation
      anime({
        targets: headerRef.current,
        translateY: [-30, 0],
        opacity: [0, 1],
        duration: 600,
        easing: 'easeOutExpo'
      });

      // Grid animation
      anime({
        targets: gridRef.current.children,
        scale: [0.8, 1],
        opacity: [0, 1],
        translateY: [30, 0],
        delay: anime.stagger(100),
        duration: 800,
        easing: 'easeOutElastic(1, 0.5)'
      });

      // Tabs animation
      anime({
        targets: tabsRef.current,
        translateY: [20, 0],
        opacity: [0, 1],
        duration: 600,
        delay: 300,
        easing: 'easeOutQuad'
      });
    }
  }, []);

  if (!channel) {
    return <Text>{t('channelNotFound')}</Text>;
  }

  const renderProductsTab = () => (
    <TabContent>
      <FlexContainer>
        <H2>{t('productManagement')}</H2>
        <Button $buttonstyle="primary" icon={<PlusOutlined />}>{t('addNewProduct')}</Button>
      </FlexContainer>
      <FlexContainer>
        <Input placeholder={t('searchProduct')} style={{ width: 200 }} />
        <Select defaultValue="all" style={{ width: 150 }}>
          <Option value="all">{t('allCategories')}</Option>
          <Option value="electronics">{t('electronics')}</Option>
          <Option value="clothing">{t('clothing')}</Option>
        </Select>
        <Select defaultValue="all" style={{ width: 150 }}>
          <Option value="all">{t('allStatuses')}</Option>
          <Option value="active">{t('active')}</Option>
          <Option value="inactive">{t('inactive')}</Option>
        </Select>
      </FlexContainer>
      <Table
        columns={[
          { title: t('productName'), dataIndex: 'name', key: 'name' },
          { title: 'SKU', dataIndex: 'sku', key: 'sku' },
          { title: t('price'), dataIndex: 'price', key: 'price' },
          { title: t('stock'), dataIndex: 'stock', key: 'stock' },
          { title: t('status'), dataIndex: 'status', key: 'status', render: (status) => <Badge status={status === 'active' ? 'success' : 'error'} text={t(`status.${status}`)} /> },
        ]}
        dataSource={[
          { key: '1', name: 'iPhone 13 Pro', sku: 'IP13-PRO-256', price: '₺24.999', stock: 50, status: 'active' },
          { key: '2', name: 'Samsung Galaxy S21', sku: 'SAM-S21-128', price: '₺12.999', stock: 30, status: 'active' },
          { key: '3', name: 'MacBook Air M1', sku: 'MBA-M1-256', price: '₺18.999', stock: 0, status: 'inactive' },
        ]}
      />
    </TabContent>
  );

  const renderOrdersTab = () => (
    <TabContent>
      <FlexContainer>
        <h2>{t('orderManagement')}</h2>
        <Select defaultValue="all" style={{ width: 200 }}>
          <Option value="all">{t('allOrders')}</Option>
          <Option value="new">{t('new')}</Option>
          <Option value="processing">{t('processing')}</Option>
          <Option value="shipped">{t('shipped')}</Option>
        </Select>
      </FlexContainer>
      <Table
        columns={[
          { title: t('orderNo'), dataIndex: 'orderNo', key: 'orderNo' },
          { title: t('customer'), dataIndex: 'customer', key: 'customer' },
          { title: t('date'), dataIndex: 'date', key: 'date' },
          { title: t('amount'), dataIndex: 'amount', key: 'amount' },
          { title: t('status'), dataIndex: 'status', key: 'status', render: (status) => <Badge status={status === 'shipped' ? 'success' : 'processing'} text={t(`status.${status}`)} /> },
        ]}
        dataSource={[
          { key: '1', orderNo: '#12345', customer: 'Ahmet Yılmaz', date: '2023-06-15', amount: '₺1.299', status: 'shipped' },
          { key: '2', orderNo: '#12346', customer: 'Ayşe Demir', date: '2023-06-14', amount: '₺2.499', status: 'processing' },
          { key: '3', orderNo: '#12347', customer: 'Mehmet Kaya', date: '2023-06-13', amount: '₺899', status: 'shipped' },
        ]}
      />
    </TabContent>
  );

  const renderPerformanceTab = () => (
    <TabContent>
      <h2>{t('performanceMetrics')}</h2>
      <ChannelGrid>
        <Card title={t('salesTrend')}>
          <div style={{ height: 200, background: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {t('salesTrendChart')}
          </div>
        </Card>
        <Card title={t('topSellingProducts')}>
          <ul>
            <li>iPhone 13 Pro - 150 {t('units')}</li>
            <li>Samsung Galaxy S21 - 120 {t('units')}</li>
            <li>MacBook Air M1 - 80 {t('units')}</li>
          </ul>
        </Card>
        <Card title={t('customerSatisfaction')}>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ fontSize: '3rem' }}>4.8</h3>
            <p>{t('outOf5')}</p>
          </div>
        </Card>
      </ChannelGrid>
    </TabContent>
  );

  const renderSettingsTab = () => (
    <TabContent>
      <h2>{t('channelSettings')}</h2>
      <Card title={t('generalSettings')}>
        <div>
          <label>{t('channelName')}</label>
          <Input defaultValue={channel.name} />
        </div>
        <div>
          <label>{t('apiKey')}</label>
          <Input.Password value="••••••••••••••••" readOnly />
        </div>
        <div>
          <label>{t('webhookUrl')}</label>
          <Input placeholder="https://example.com/webhook" />
        </div>
        <Button $buttonstyle="primary">{t('saveSettings')}</Button>
      </Card>
      <Card title={t('integrationSettings')}>
        <div>
          <h3>{t('accountingSoftware')}</h3>
          <p>{t('accountingSoftwareDesc')}</p>
          <Switch />
        </div>
        <div>
          <h3>{t('shippingIntegration')}</h3>
          <p>{t('shippingIntegrationDesc')}</p>
          <Switch />
        </div>
      </Card>
    </TabContent>
  );

  const renderFinanceTab = () => (
    <TabContent>
      <h2>{t('financialInformation')}</h2>
      <ChannelGrid>
        <Card title={t('revenueSummary')}>
          <div>
            <p>{t('thisMonth')}: ₺45.000</p>
            <p>{t('lastMonth')}: ₺38.500</p>
            <p>{t('yearlyTotal')}: ₺520.000</p>
          </div>
        </Card>
        <Card title={t('feesAndCommissions')}>
          <div>
            <p>{t('platformCommission')}: 8%</p>
            <p>{t('paymentProcessingFee')}: 1.5% + ₺0.50</p>
            <p>{t('totalFeeThisMonth')}: ₺4.275</p>
          </div>
        </Card>
      </ChannelGrid>
      <Card title={t('paymentHistory')}>
        <Table
          columns={[
            { title: t('date'), dataIndex: 'date', key: 'date' },
            { title: t('description'), dataIndex: 'description', key: 'description' },
            { title: t('amount'), dataIndex: 'amount', key: 'amount' },
            { title: t('status'), dataIndex: 'status', key: 'status', render: (status) => <Badge status="success" text={t(`status.${status}`)} /> },
          ]}
          dataSource={[
            { key: '1', date: '2023-06-01', description: t('mayPayment'), amount: '₺42.500', status: 'paid' },
            { key: '2', date: '2023-05-01', description: t('aprilPayment'), amount: '₺38.750', status: 'paid' },
            { key: '3', date: '2023-04-01', description: t('marchPayment'), amount: '₺41.200', status: 'paid' },
          ]}
        />
      </Card>
    </TabContent>
  );

  return (
    <ChannelDetailContainer>
      <ChannelHeader ref={headerRef}>
        <H1>{channel?.name} {t('channelDetails')}</H1>
        <Badge status={channel?.active ? 'success' : 'error'} text={t(`status.${channel?.status}`)} />
      </ChannelHeader>
      <ChannelMain>
        <ChannelGrid ref={gridRef}>
          <Card title={<Bold>{t('totalProducts')}</Bold>} extra={<BoxPlotOutlined />}>
            <ChannelCardContent>{channel.products}</ChannelCardContent>
          </Card>
          <Card title={<Bold>{t('totalOrders')}</Bold>} extra={<ShoppingCartOutlined />}>
            <ChannelCardContent>{channel.orders}</ChannelCardContent>
          </Card>
          <Card title={<Bold>{t('totalRevenue')}</Bold>} extra={<DollarOutlined />}>
            <ChannelCardContent>₺{channel.revenue.toLocaleString()}</ChannelCardContent>
          </Card>
          <Card title={<Bold>{t('conversionRate')}</Bold>} extra={<LineChartOutlined />}>
            <ChannelCardContent>%{channel.conversionRate}</ChannelCardContent>
          </Card>
        </ChannelGrid>
        <div ref={tabsRef}>
          <Tabs defaultActiveKey="products">
            <TabPane tab={t('products')} key="products">
              {renderProductsTab()}
            </TabPane>
            <TabPane tab={t('orders')} key="orders">
              {renderOrdersTab()}
            </TabPane>
            <TabPane tab={t('performance')} key="performance">
              {renderPerformanceTab()}
            </TabPane>
            <TabPane tab={t('settings')} key="settings">
              {renderSettingsTab()}
            </TabPane>
            <TabPane tab={t('finance')} key="finance">
              {renderFinanceTab()}
            </TabPane>
          </Tabs>
        </div>
      </ChannelMain>
    </ChannelDetailContainer>
  );
};

export default ChannelDetail;
