import React from 'react';
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { IProduct, IChannel } from '../products.d';
import { ExpandedContent } from '../products.styled';
import {
  ExpandedHeader,
  ChannelGrid,
  ChannelCardHeader,
  ChannelName,
  ChannelDetails,
  DetailRow,
  EmptyChannelMessage,
  ChannelCard,
  EmptyMessage,
  ChannelButton,
  AddButton
} from './expandedRow.styled';

interface ExpandedRowProps {
  record: IProduct;
  channels: ReadonlyArray<{ readonly id: string; readonly name: string; }>;
  onEditChannel?: (channelId: string, productId: number) => void;
  onAddToChannel?: (channelId: string, productId: number) => void;
}

export const ExpandedRow: React.FC<ExpandedRowProps> = ({
  record,
  channels,
  onEditChannel,
  onAddToChannel
}) => {
  const { t } = useTranslation('products');

  return (
    <ExpandedContent>
      <ExpandedHeader>
        {t('table.expandedRow.channelDetails')}
      </ExpandedHeader>
      <ChannelGrid>
        {channels.map(channel => {
          const channelData = record.channels.find(c => c.id === channel.id);
          return (
            <ChannelCard key={channel.id}>
              <ChannelCardHeader>
                <ChannelName>{channel.name}</ChannelName>
                <Badge 
                  status={channelData?.active ? 'success' : 'error'}
                  text={channelData?.active ? 
                    t('channel.status.active') : 
                    t('channel.status.inactive')}
                />
              </ChannelCardHeader>
              {channelData ? (
                <>
                  <ChannelDetails>
                    <DetailRow>
                      {t('channel.labels.price')} ₺{channelData.price.toFixed(2)}
                    </DetailRow>
                    <DetailRow>
                      {t('channel.labels.stock')} {channelData.stock}
                    </DetailRow>
                    <DetailRow>
                      {t('channel.labels.priceRule')} {channelData.priceRule}
                    </DetailRow>
                    <DetailRow>
                      {t('channel.labels.stockRule')} {channelData.stockRule}
                    </DetailRow>
                  </ChannelDetails>
                  <ChannelButton 
                    $buttonStyle="primary"
                    onClick={() => onEditChannel?.(channel.id, record.id)}
                  >
                    {t('table.expandedRow.edit')}
                  </ChannelButton>
                </>
              ) : (
                <EmptyChannelMessage>
                  <EmptyMessage>
                    {t('table.expandedRow.notListedMessage')}
                  </EmptyMessage>
                  <AddButton 
                    $buttonStyle="primary"
                    onClick={() => onAddToChannel?.(channel.id, record.id)}
                  >
                    {t('table.expandedRow.addToChannel')}
                  </AddButton>
                </EmptyChannelMessage>
              )}
            </ChannelCard>
          );
        })}
      </ChannelGrid>
    </ExpandedContent>
  );
}; 