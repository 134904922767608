import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchChannelsStart, fetchChannelsSuccess, fetchChannelsFailure } from './channels.reducer';
import { fetchChannelsApi } from './channels.service';

function* fetchChannelsSaga(): Generator<any, void, unknown> {
  try {
    const channels: any = yield call(fetchChannelsApi);
    yield put(fetchChannelsSuccess(channels));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchChannelsFailure(error.message));
    } else {
      yield put(fetchChannelsFailure('An unknown error occurred'));
    }
  }
}

export function* watchChannelsSaga() {
  yield takeLatest(fetchChannelsStart.type, fetchChannelsSaga);
}
