import axiosInstance from '../../api/axios-config';
import { CompanyData } from './accountSettings.types';

export const accountSettingsService = {
  fetchUserData: async (userId: string) => {
    try {
      const response = await axiosInstance.get(`/api/v1/users/${userId}`);
      return response.data.user;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  },

  updateUserData: async (userId: string, userData: any) => {
    try {
      const response = await axiosInstance.put(`/api/v1/users/${userId}`, userData);
      return response.data.user;
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error;
    }
  },

  createCompany: async (companyData: any): Promise<CompanyData> => {
    try {
      const response = await axiosInstance.post('/api/v1/tenants', companyData);
      return response.data.tenant;
    } catch (error) {
      console.error('Error creating company:', error);
      throw error;
    }
  },

  fetchCompanyData: async (): Promise<CompanyData> => {
    try {
      const response = await axiosInstance.get('/api/v1/company');
      return response.data.company;
    } catch (error) {
      console.error('Error fetching company data:', error);
      throw error;
    }
  },

  getTenant: async (tenantId: string): Promise<CompanyData> => {
    try {
      const response = await axiosInstance.get(`/api/v1/tenants/${tenantId}`);
      return response.data.tenant;
    } catch (error) {
      console.error('Error fetching tenant data:', error);
      throw error;
    }
  },
};
