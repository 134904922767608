import { takeLatest, put, call } from 'redux-saga/effects';
import { changeLanguage } from '../actions/languageActions';
import { setLanguage } from '../reducers/languageReducer';
import i18n from '../../i18n';

function* changeLanguageSaga(action: ReturnType<typeof changeLanguage>) {
  try {
    yield call([i18n, 'changeLanguage'], action.payload);
    yield put(setLanguage(action.payload));
  } catch (error) {
    console.error('Failed to change language:', error);
  }
}

export function* watchLanguageChange() {
  yield takeLatest(changeLanguage.type, changeLanguageSaga);
}
