import { IWebSocketState } from "./websocket";
import { WebSocketActions } from "./websocket.actions";
import { WS_ACTION_TYPES } from "./websocket.enum";

export const initialState: IWebSocketState = {};

export const WebSocketReducer = (
  state: IWebSocketState = initialState,
  action: WebSocketActions
): IWebSocketState => {
  switch (action.type) {
    case WS_ACTION_TYPES.INIT_WS:
      return {
        ...state,
        [action.data.socketURI]: {
          ...action.data,
          isWebSocketConnecting: true,
          isWebSocketConnected: false,
          isWebSocketDisconnected: true,
        },
      };
    case WS_ACTION_TYPES.UPDATE_WS:
      return {
        ...state,
        [action.data.socketURI]: {
          ...action.data,
        },
      };
    default:
      return state;
  }
};
