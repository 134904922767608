import MenuFoldOutlined from '@ant-design/icons/lib/icons/MenuFoldOutlined';
import MenuUnfoldOutlined from '@ant-design/icons/lib/icons/MenuUnfoldOutlined';
import { Layout, Drawer } from 'antd';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CaretDownOutlined } from '@ant-design/icons';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  z-index: 1001;
  background-color: ${({ theme }) => theme.colors.background} !important;
  border-right: 1px solid ${({ theme }) => theme.colors.divider};

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border-right: none !important;
    padding: 0 !important;
  }

  .ant-menu-item {
    margin: 4px 16px !important;
    padding: 0 16px !important;
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.textSecondary} !important;
    width: calc(100% - 32px) !important;

    .anticon {
      min-width: 24px !important;
      margin-right: ${props => props.collapsed ? '0' : '16px'} !important;
    }

    &.ant-menu-item-collapsed {
      padding: 0 !important;
      justify-content: center;
      
      .anticon {
        margin: 0 !important;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.colors.text} !important;
      background-color: ${({ theme }) => theme.colors.hoverBackground} !important;
    }
  }

  .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    color: #ffffff !important;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryLight} !important;
      color: #ffffff !important;
    }

    .anticon {
      color: #ffffff !important;
    }
  }

  .ant-menu-item .anticon {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  .ant-menu-item:hover .anticon {
    color: ${({ theme }) => theme.colors.text};
  }
`;

interface LogoProps {
  $collapsed: boolean;
}

export const Logo = styled.div<LogoProps>`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.$collapsed ? 'center' : 'flex-start'};
  padding: 0 ${props => props.$collapsed ? '0' : '24px'};
  background: transparent;
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};

  span.logo-symbol {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.$collapsed ? '100%' : '24px'};
    min-width: 24px;
    margin-right: ${props => props.$collapsed ? '0' : '16px'};
  }
`;

export const CollapseButton = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  
  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.hoverBackground};
  }
`;

export const MenuItemStyled = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 6px;
  transition: all 0.3s;
  width: 100%;
  
  ${({ active, theme }) => active ? `
    background-color: ${theme.colors.primary};
    color: #ffffff;

    &:hover {
      background-color: ${theme.colors.primaryLight};
    }

    .anticon {
      color: #ffffff;
    }
  ` : `
    color: ${theme.colors.textSecondary};

    &:hover {
      color: ${theme.colors.text};
      background-color: ${theme.colors.hoverBackground};
    }

    .anticon {
      color: ${theme.colors.textSecondary};
    }

    &:hover .anticon {
      color: ${theme.colors.text};
    }
  `}
`;

export const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  background: ${({ theme }) => theme.colors.background};

  color: ${({ theme }) => theme.colors.text};
  font-size: 24px;
  cursor: pointer;
`;

export const StyledMenuFoldOutlined = styled(MenuFoldOutlined)`
  background: ${({ theme }) => theme.colors.background};

  color: ${({ theme }) => theme.colors.text};
  font-size: 24px;
  cursor: pointer;
`;

export const SidebarContent = styled(motion.div)<{ $collapsed: boolean }>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.2s;
  width: ${props => props.$collapsed ? '80px' : '240px'};
`;

export const MobileMenuButton = styled.button`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 1000;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    font-size: 24px;
  }
`;

export const Overlay = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
`;

export const BottomSection = styled.div<{ collapsed: boolean }>`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s;
`;

export const SectionTitle = styled.div<{ collapsed: boolean }>`
  padding: ${props => props.collapsed ? '16px 0' : '16px 24px 8px'};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSecondary};
  text-align: ${props => props.collapsed ? 'center' : 'left'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ThemeToggle = styled.div<{ 
  collapsed: boolean; 
  isDark: boolean;
}>`
  display: flex;
  justify-content: ${props => props.collapsed ? 'center' : 'flex-start'};
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.textSecondary};
  border-radius: 6px;
  margin: 0 16px;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.hoverBackground};
  }

  span {
    font-size: ${props => props.collapsed ? '20px' : '16px'};
  }
`;

export const LanguageToggle = styled.div<{ collapsed: boolean }>`
  display: flex;
  justify-content: ${props => props.collapsed ? 'center' : 'space-around'};
  align-items: center;
  padding: 16px;
  margin: 8px 16px;
  flex-wrap: wrap;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const LanguageButton = styled.button<{ isActive: boolean; collapsed: boolean }>`
  background: none;
  border: none;
  font-size: 14px;
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  color: ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.textSecondary};
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s;
  width: ${props => props.collapsed ? '100%' : 'auto'};
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.hoverBackground};
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const DrawerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 16px; // Üst kısımda biraz boşluk bırak
`;

export const DrawerHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const DrawerLogo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
`;

export const SubMenuItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin: 4px 0;
  border-radius: 4px;
  transition: all 0.3s;
  
  ${({ active, theme }) => active && `
    background-color: ${theme.colors.activeBackground};
    color: #ffffff;
  `}
  
  &:hover {
    background-color: ${({ theme, active }) => active ? theme.colors.activeBackground : theme.colors.hoverBackgroundLight};
  }
`;

export const ChannelSubmenu = styled(motion.div)`
  overflow: hidden;
  margin-top: -4px;
  padding-bottom: 4px;
`;

export const MenuItemContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  right: 16px;
  top: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  pointer-events: none;

  svg {
    position: absolute;
    width: 10px;
    height: 10px;
    fill: currentColor;
    color: ${({ theme }) => theme.colors.textSecondary};
    transform-origin: center;
    transition: transform 0.25s cubic-bezier(0.4, 0.45, 0, 1.32);
    transform: ${({ $isOpen }) => 
      $isOpen ? 'rotate(180deg)' : 'rotate(0)'};
    will-change: transform;
  }

  .ant-menu-item:hover & svg {
    color: ${({ theme }) => theme.colors.text};
  }
`;
