import { createReducer } from '@reduxjs/toolkit';
import { IProductsState } from './products.d';
import * as actions from './products.action';

const initialState: IProductsState = {
  products: [],
  loading: false,
  error: null,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0
  },
  filters: {
    search: '',
    category: undefined,
    status: undefined
  }
};

const productsReducer = createReducer(initialState, (builder) => {
  builder
    // Fetch Products
    .addCase(actions.fetchProductsRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(actions.fetchProductsSuccess, (state, action) => {
      state.loading = false;
      state.products = action.payload.data;
      state.pagination = {
        current: action.payload.page,
        pageSize: action.payload.limit,
        total: action.payload.total
      };
      state.error = null;
    })
    .addCase(actions.fetchProductsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    
    // Update Product Status
    .addCase(actions.updateProductStatusRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(actions.updateProductStatusSuccess, (state, action) => {
      state.loading = false;
      state.products = state.products.map(product => 
        product.id === action.payload.id 
          ? { ...product, status: action.payload.status }
          : product
      );
      state.error = null;
    })
    .addCase(actions.updateProductStatusFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // Delete Product
    .addCase(actions.deleteProductRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(actions.deleteProductSuccess, (state, action) => {
      state.loading = false;
      state.products = state.products.filter(product => product.id !== action.payload);
      state.pagination = {
        ...state.pagination,
        total: state.pagination.total - 1
      };
      state.error = null;
    })
    .addCase(actions.deleteProductFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // Bulk Update Status
    .addCase(actions.bulkUpdateStatusRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(actions.bulkUpdateStatusSuccess, (state, action) => {
      state.loading = false;
      state.products = state.products.map(product => 
        action.payload.ids.includes(product.id)
          ? { ...product, status: action.payload.status }
          : product
      );
      state.error = null;
    })
    .addCase(actions.bulkUpdateStatusFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // Bulk Delete
    .addCase(actions.bulkDeleteRequest, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(actions.bulkDeleteSuccess, (state, action) => {
      state.loading = false;
      state.products = state.products.filter(product => !action.payload.includes(product.id));
      state.pagination = {
        ...state.pagination,
        total: state.pagination.total - action.payload.length
      };
      state.error = null;
    })
    .addCase(actions.bulkDeleteFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default productsReducer; 