import { call, put, takeLatest, select } from 'redux-saga/effects';
import { ProductActionTypes } from './products.action';
import * as actions from './products.action';
import { ProductsService } from './products.service';
import { IProductsResponse } from './products.d';
import { message } from 'antd';
import i18n from '../../i18n';
import { IGlobalState } from '../../redux/reducers';

const t = (key: string) => i18n.t(key, { ns: 'products' });

// Selector to get tenantId from Redux store
const selectTenantId = (state: IGlobalState): string | undefined => state.LOGIN.user?.tenant?.ID;
const selectUserId = (state: IGlobalState): string | undefined => state.LOGIN.user?.id;

function* fetchProductsSaga(
	action: ReturnType<typeof actions.fetchProductsRequest>
): Generator<any, void, any> {
	try {
		const tenantId: string | undefined = yield select(selectTenantId);
		if (!tenantId) {
			throw new Error(t('errors.noTenant'));
		}

		const response = yield call(ProductsService.getProducts, tenantId, action.payload);
		yield put(actions.fetchProductsSuccess(response.data));
	} catch (error: any) {
		yield put(
			actions.fetchProductsFailure(error.response?.data?.message || t('errors.fetchError'))
		);
		message.error(t('errors.fetchError'));
	}
}

function* updateProductStatusSaga(
	action: ReturnType<typeof actions.updateProductStatusRequest>
): Generator<any, void, any> {
	try {
		const tenantId: string | undefined = yield select(selectTenantId);
		const userId: string | undefined = yield select(selectUserId);
		if (!tenantId || !userId) {
			throw new Error(t('errors.noTenant'));
		}

		const { id, status } = action.payload;
		yield call(
			{
				context: ProductsService,
				fn: ProductsService.updateProductStatus,
			},
			tenantId,
			id.toString(),
			status === 'active',
			userId
		);
		yield put(actions.updateProductStatusSuccess({ id, status }));
		message.success(t('messages.statusUpdateSuccess'));
	} catch (error: any) {
		yield put(
			actions.updateProductStatusFailure(error.response?.data?.message || t('messages.error'))
		);
		message.error(t('messages.statusUpdateError'));
	}
}

function* bulkUpdateStatusSaga(
	action: ReturnType<typeof actions.bulkUpdateStatusRequest>
): Generator<any, void, any> {
	try {
		const tenantId: string | undefined = yield select(selectTenantId);
		const userId: string | undefined = yield select(selectUserId);
		if (!tenantId || !userId) {
			throw new Error(t('errors.noTenant'));
		}

		const { ids, status } = action.payload;
		yield call(
			{
				context: ProductsService,
				fn: ProductsService.bulkUpdateStatus,
			},
			tenantId,
			ids.map((id) => id.toString()),
			status === 'active',
			userId
		);
		yield put(actions.bulkUpdateStatusSuccess({ ids, status }));
		message.success(t('messages.bulkStatusUpdateSuccess'));
	} catch (error: any) {
		yield put(
			actions.bulkUpdateStatusFailure(error.response?.data?.message || t('messages.error'))
		);
		message.error(t('messages.bulkStatusUpdateError'));
	}
}

function* bulkDeleteSaga(
	action: ReturnType<typeof actions.bulkDeleteRequest>
): Generator<any, void, any> {
	try {
		const tenantId: string | undefined = yield select(selectTenantId);
		if (!tenantId) {
			throw new Error(t('errors.noTenant'));
		}

		const { ids } = action.payload;
		yield call(
			{
				context: ProductsService,
				fn: ProductsService.bulkDelete,
			},
			tenantId,
			ids.map((id) => id.toString())
		);
		yield put(actions.bulkDeleteSuccess(ids));
		message.success(t('messages.bulkDeleteSuccess'));
	} catch (error: any) {
		yield put(actions.bulkDeleteFailure(error.response?.data?.message || t('messages.error')));
		message.error(t('messages.bulkDeleteError'));
	}
}

export function* productsWatcher() {
	yield takeLatest(ProductActionTypes.FETCH_PRODUCTS_REQUEST, fetchProductsSaga);
	yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_STATUS_REQUEST, updateProductStatusSaga);
	yield takeLatest(ProductActionTypes.BULK_UPDATE_STATUS_REQUEST, bulkUpdateStatusSaga);
	yield takeLatest(ProductActionTypes.BULK_DELETE_REQUEST, bulkDeleteSaga);
}
