import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from 'styled-components';
import { Eye, EyeOff, ArrowRight, AlertCircle, Shield, Zap, Globe } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { IGlobalState } from '../../../redux/reducers';
import { registerUserActionCreator } from '../login.action';
import { ILoginState } from '../login.reducer';
import { registerSchema } from '../login.validations';
import { useFormik } from 'formik';
import { store } from '../../../redux/store';
import { TextInputLazy } from '../../../components/Input/TextInput/text-input.lazy';
import { ModernBackground } from '../UserLogin/ModernBackground';
import SocialLoginButtons from '../UserLogin/SocialLoginButtons';
import { Text, Checkbox } from '../../../components/Design/design.styled';
import {
  RegisterContainer,
  RegisterFormWrapper,
  RegisterForm,
  StyledLink,
  CheckboxWrapper,
  LinkContainer,
  Divider,
  FormContainer,
  WelcomeText,
  RegisterButton
} from './register.styled';
import { ButtonState } from '../../../components/LoadingButton';

export const UserRegister: React.FC = () => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const login: ILoginState = useSelector<IGlobalState, ILoginState>(state => state.LOGIN);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loginButtonState, setLoginButtonState] = useState<ButtonState>('default');
  const { t } = useTranslation('login');
  const theme = useTheme();

  useEffect(() => {
    if (!login.isFetching) {
      setIsSubmitting(false);
      
      // Başarılı kayıt durumu
      if (login.user && !login.error) {
        setLoginButtonState('success');
      }
      // Hata durumu
      else if (login.error) {
        setLoginButtonState('error');
      }
      // Başlangıç durumu
      else {
        setLoginButtonState('default');
      }
    }
  }, [login.isFetching, login.error, login.user]);

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      agreeTerms: false,
    },
    validationSchema: registerSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      const allFieldsFilled = Object.values(values).every(value => value !== '' && value !== false);
      if (!allFieldsFilled) {
        alert(t('fillAllFields'));
        return;
      }

      setIsSubmitting(true);
      setHasSubmitted(true);
      dispatch(registerUserActionCreator(
        values.name,
        values.surname,
        values.email,
        values.password
      ));
    },
  });

  const getButtonColor = () => {
    if (isSubmitting) return theme.colors.primary;
    if (login.error) return theme.colors.error;
    if (login.user) return theme.colors.success;
    
    const touchedFields = Object.keys(formik.touched).filter(key => formik.touched[key as keyof typeof formik.touched]);
    const hasErrors = Object.keys(formik.errors).length > 0;

    if (touchedFields.length === 0) return theme.colors.primary;
    if (hasErrors || !formik.values.agreeTerms) return theme.colors.warning;
    return theme.colors.success;
  };

  const getErrorMessage = (fieldName: keyof typeof formik.values): string | undefined => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return t(formik.errors[fieldName] as string);
    }
    return undefined;
  };

  const getButtonContent = (): string => {
    if (hasSubmitted && login.error) {
      return t('checkAndTryAgain');
    }

    if (isSubmitting) {
      return t('creatingAccount');
    }

    const touchedFields = Object.keys(formik.touched).length;
    const hasErrors = Object.keys(formik.errors).length > 0;

    if (touchedFields === 0) return t('fillForm');
    if (hasErrors) return t('checkErrors');
    if (!formik.values.agreeTerms) return t('acceptTerms');

    return t('register');
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <RegisterContainer>
      <ModernBackground />
      <RegisterFormWrapper>
        <RegisterForm
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <WelcomeText
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.h1 variants={item}>
              {t('welcomeTitle')}
            </motion.h1>
            
            <motion.p 
              className="description"
              variants={item}
            >
              {t('welcomeDescription')}
            </motion.p>

            <motion.div 
              className="features"
              variants={container}
            >
              <motion.div className="feature" variants={item}>
                <Shield size={18} />
                <span>{t('secureAccess')}</span>
              </motion.div>
              <motion.div className="feature" variants={item}>
                <Zap size={18} />
                <span>{t('fastPerformance')}</span>
              </motion.div>
              <motion.div className="feature" variants={item}>
                <Globe size={18} />
                <span>{t('globalSupport')}</span>
              </motion.div>
            </motion.div>
          </WelcomeText>

          <FormContainer onSubmit={formik.handleSubmit} noValidate>
            <div className="input-wrapper">
              <TextInputLazy
                label={t('name')}
                id="name"
                name="name"
                type="text"
                autoComplete="given-name"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={getErrorMessage('name')}
                icon="user"
                aria-label={t('name')}
              />
            </div>

            <div className="input-wrapper">
              <TextInputLazy
                label={t('surname')}
                id="surname"
                name="surname"
                type="text"
                autoComplete="family-name"
                required
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={getErrorMessage('surname')}
                icon="user"
                aria-label={t('surname')}
              />
            </div>

            <div className="input-wrapper">
              <TextInputLazy
                label={t('email')}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={getErrorMessage('email')}
                icon="mail"
                aria-label={t('email')}
              />
            </div>

            <div className="input-wrapper">
              <TextInputLazy
                label={t('password')}
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={getErrorMessage('password')}
                icon="lock"
                aria-label={t('password')}
              />
            </div>

            <div className="input-wrapper">
              <TextInputLazy
                label={t('confirmPassword')}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                required
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={getErrorMessage('confirmPassword')}
                icon="lock"
                aria-label={t('confirmPassword')}
              />
            </div>

            <CheckboxWrapper>
              <Checkbox
                id="agreeTerms"
                name="agreeTerms"
                checked={formik.values.agreeTerms}
                onChange={formik.handleChange}
                aria-label={t('agreeTerms')}
                $variant="secondary"
              >
                {t('agreeTerms')}
              </Checkbox>
            </CheckboxWrapper>

            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <RegisterButton
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                disabled={isSubmitting}
                $buttonColor={getButtonColor()}
                aria-label={getButtonContent()}
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    key={isSubmitting ? 'submitting' : 'default'}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    {isSubmitting ? (
                      getButtonContent()
                    ) : (
                      <>
                        <span>{t('register')}</span>
                        <ArrowRight size={20} />
                      </>
                    )}
                  </motion.div>
                </AnimatePresence>
              </RegisterButton>
            </motion.div>
          </FormContainer>

          <Divider>
            <span>{t('orContinueWith')}</span>
          </Divider>

          <SocialLoginButtons />

          <LinkContainer>
            <Text style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {t('haveAccount')}{' '}
              <StyledLink to="/giris">
                {t('login')}
              </StyledLink>
            </Text>
          </LinkContainer>
        </RegisterForm>
      </RegisterFormWrapper>
    </RegisterContainer>
  );
};

export default UserRegister;
