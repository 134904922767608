import { combineReducers } from '@reduxjs/toolkit';
import { INotficationState } from '../../components/Notification/notification';
import { notficationReducer } from '../../components/Notification/notification.reducer';
import LoginReducer, { ILoginState } from '../../pages/Login/login.reducer';
import { IWebSocketState } from '../../websocket/websocket';
import { WebSocketReducer } from '../../websocket/websocket.reducer';
import languageReducer, { ILanguageState } from './languageReducer';
import dashboardReducer from '../../pages/Dashboard/dashboard.reducer';
import { accountSettingsReducer, IAccountSettingsState } from '../../pages/AccountSettings/accountSettings.reducer';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import channelsReducer from '../../pages/Channels/channels.reducer';
import productsReducer from '../../pages/Products/products.reducer';
import productDetailReducer from '../../pages/Products/Detail/productDetail.reducer';
import { IProductsState } from '../../pages/Products/products.d';
import { IProductDetailState } from '../../pages/Products/Detail/productDetail';
import themeReducer from './theme.reducer';
import { ThemeState } from './theme.reducer';
import exampleReducer, { IExampleState } from '../../pages/ExampleModule/example.reducer';

// Define Global State Interface
export interface IGlobalState {
  websocket: IWebSocketState;
  LOGIN: ILoginState;
  notifications: INotficationState[];
  language: ILanguageState;
  DASHBOARD: ReturnType<typeof dashboardReducer>;
  ACCOUNT_SETTINGS: IAccountSettingsState;
  _persist?: PersistPartial;
  channels: ReturnType<typeof channelsReducer>;
  products: IProductsState;
  productDetail: IProductDetailState;
  theme: ThemeState;
  EXAMPLE: IExampleState;
}

// Combine Reducers
export const createRootReducer = () =>
  combineReducers({
    websocket: WebSocketReducer,
    LOGIN: LoginReducer,
    notifications: notficationReducer,
    language: languageReducer,
    DASHBOARD: dashboardReducer,
    ACCOUNT_SETTINGS: accountSettingsReducer,
    channels: channelsReducer,
    products: productsReducer,
    productDetail: productDetailReducer,
    theme: themeReducer,
    EXAMPLE: exampleReducer,
  });
