import styled from 'styled-components';
import { Card } from 'antd';

interface MetricTrendProps {
  $isPositive: boolean;
}

interface InsightCardProps {
  $type: 'positive' | 'warning' | 'negative';
}

export const DashboardContainer = styled.div`
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const WelcomeSection = styled.div`
  margin-bottom: 32px;
`;

export const WelcomeContent = styled.div`
  margin-bottom: 24px;
`;

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  margin-top: 24px;
`;

export const StatCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
`;

export const StatValue = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2C3E50;
`;

export const StatLabel = styled.div`
  font-size: 14px;
  color: #5D6D7E;
  margin-top: 8px;
`;

export const GraphSection = styled(Card)`
  height: 100%;
  .ant-card-head {
    border-bottom: none;
  }
`;

export const MetricsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
`;

export const MetricCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
`;

export const MetricInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const MetricIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #F5F8FA;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  font-size: 20px;
  color: #3498DB;
`;

export const MetricDetails = styled.div`
  flex: 1;
`;

export const MetricName = styled.div`
  font-weight: 600;
  color: #2C3E50;
`;

export const MetricSubtext = styled.div`
  font-size: 12px;
  color: #5D6D7E;
  margin-top: 4px;
`;

export const MetricStats = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MetricTrend = styled.div<MetricTrendProps>`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.$isPositive ? '#2ECC71' : '#E74C3C'};
`;

export const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const InsightCard = styled.div<InsightCardProps>`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  background: ${props => {
    switch (props.$type) {
      case 'positive': return '#F1F9F5';
      case 'warning': return '#FEF9E7';
      case 'negative': return '#FDEDEC';
      default: return '#F5F8FA';
    }
  }};
`;

export const InsightIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const InsightContent = styled.div`
  flex: 1;
  color: #2C3E50;
`;

export const TopProductsList = styled.div`
  margin: 24px 0;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
`;

export const TopProductItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grey100}10;
  }
`;

export const ProductRank = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  margin-right: 16px;
  min-width: 32px;
`;

export const ProductInfo = styled.div`
  flex: 1;
  margin-right: 24px;

  .ant-typography {
    &:first-child {
      color: ${({ theme }) => theme.colors.text};
      font-weight: 500;
      margin-bottom: 4px;
    }

    &:last-child {
      color: ${({ theme }) => theme.colors.textSecondary};
      font-size: 13px;
    }
  }
`;

export const ProductStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 120px;
  
  .ant-typography {
    &:first-child {
      color: ${({ theme }) => theme.colors.text};
      font-weight: 600;
      font-size: 15px;
    }
  }

  .metric-trend {
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 4px;
    margin-top: 4px;
    
    &.positive {
      color: ${({ theme }) => theme.colors.success};
      background: ${({ theme }) => theme.colors.success}10;
    }
  }
`;

export const PerformanceMetrics = styled.div`
  margin-top: 32px;
`;

export const MetricSegment = styled.div`
  height: 100%;
`;

export const GoalProgress = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
`;

export const TimeframeSelector = styled.div`
  .ant-segmented {
    background: #F5F8FA;
  }
`;

export const PremiumCard = styled.div`
  background: linear-gradient(120deg, rgba(255, 229, 241, 0.8) 0%, rgba(232, 245, 255, 0.8) 50%, rgba(239, 249, 246, 0.8) 100%);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 24px;
  padding: 32px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 
    0 8px 32px rgba(100, 100, 100, 0.1),
    inset 0 0 32px rgba(255, 255, 255, 0.4);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;

  .content-wrapper {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    color: #2C3E50 !important;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 12px;
    background: linear-gradient(135deg, #2C3E50 0%, #3498DB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .ant-typography {
    color: #5D6D7E !important;
    font-size: 16px;
    line-height: 1.6;
    opacity: 0.9;
    margin-bottom: 24px;
  }

  .ant-btn {
    background: linear-gradient(135deg, #FF9ECD 0%, #7CB9E8 100%);
    border: none;
    color: white;
    font-weight: 600;
    padding: 12px 32px;
    height: auto;
    border-radius: 12px;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 14px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    align-self: flex-start;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
      background: linear-gradient(135deg, #FFB6C1 0%, #87CEEB 100%);
      color: white;
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .emoji-container {
    font-size: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-left: 48px;
    flex-shrink: 0;
    box-shadow: 
      0 8px 32px rgba(100, 100, 100, 0.1),
      inset 0 0 32px rgba(255, 255, 255, 0.6);
    animation: float 6s ease-in-out infinite;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
      border-radius: 50%;
      z-index: -1;
      animation: pulse 3s ease-in-out infinite alternate;
    }
  }

  @keyframes float {
    0%, 100% {
      transform: translateY(0) rotate(0);
    }
    25% {
      transform: translateY(-12px) rotate(-2deg);
    }
    75% {
      transform: translateY(-6px) rotate(2deg);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 24px;

    .content-wrapper {
      align-items: center;
      margin-bottom: 24px;
    }

    .emoji-container {
      margin-left: 0;
      margin-top: 24px;
    }

    .ant-btn {
      align-self: center;
    }
  }
`; 