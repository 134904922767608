import styled from 'styled-components';
import { Text, Button } from '../../components/Design';

export const ChannelContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

export const ChannelHeader = styled.div`
  margin-bottom: 20px;
`;

export const ChannelDescription = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  margin-top: 4px;
  margin-bottom: 12px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 1.5;
`;

export const ChannelMain = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

export const ChannelCardContent = styled.div`
  padding: 16px;
`;

export const ChannelCardFooter = styled.div`
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  display: flex;
  justify-content: center;
`;

export const ChannelItem = styled.div`
  // Reserved for future implementation
`;

export const ChannelList = styled.div`
  // Reserved for future implementation
`;

export const ChannelMetrics = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
`;

export const MetricItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 8px;
  margin-bottom: 4px;

  .anticon {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${Text} {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
  }
`;

export const AnimatedCard = styled.div`
  visibility: hidden;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 200px;
`;
