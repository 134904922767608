import React, { memo, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { NumberinputLazy } from '../Input/NumberInput/numberinput.lazy';
import SelectInputLazy from '../Input/Select/select.lazy';
import { TextInputLazy } from '../Input/TextInput/text-input.lazy';
import { IDesignProps } from './design.d';
import { Bold, Button, Caption, Color, Colors, DesignContainer, DesignInnerContainer, DesignSection, H1, H2, H3, H4, PasswordInput, TestButton, Text } from './design.styled';
import { EditOutlined, ArrowRightOutlined, AlertOutlined } from '@ant-design/icons';

import AnimatedText from '../AnimatedText';
import AnimatedBackground from '../AnimatedBackground';
import { Checkbox } from 'antd';

import enTranslations from './translations/en.json';
import trTranslations from './translations/tr.json';
import { Theme } from '../../lib/theme';
import { GenericInputLazy } from '../GenericInput/genericInput.lazy';
import { ButtonState, LoadingButton } from '../LoadingButton';

const DesignContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 300px;
`;

const ColorPalette = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
`;

const ColorSwatch = styled.div<{ bgColor: string }>`
  width: 100%;
  height: 100px;
  background-color: ${props => props.bgColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.5rem;
  color: ${props => props.theme.colors.text};
  font-size: 0.8rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const ResponsiveButtonWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    max-width: none;
  }
`;

export const Design: React.FC<IDesignProps> = memo(() => {
	const { t, i18n } = useTranslation('design');
	const theme = useTheme() as Theme;
	const [checkboxValue, setCheckboxValue] = useState(false);
	const [numberInputValue, setNumberInputValue] = useState<number>(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [loginButtonState, setLoginButtonState] = useState<ButtonState>('default');
	const [registerButtonState, setRegisterButtonState] = useState<'default' | 'loading' | 'error' | 'success'>('default');
	const [genericInputValue, setGenericInputValue] = useState('');
	const [genericInputError, setGenericInputError] = useState('');
	const [textInputValue, setTextInputValue] = useState('');
	const [emailInputValue, setEmailInputValue] = useState('');
	const [passwordInputValue, setPasswordInputValue] = useState('');
	const [textareaValue, setTextareaValue] = useState('');
	const [genericNumberInputValue, setGenericNumberInputValue] = useState('');

	useEffect(() => {
		i18n.addResourceBundle('en', 'design', enTranslations, true, true);
		i18n.addResourceBundle('tr', 'design', trTranslations, true, true);
	}, [i18n]);

	const handleLoadingClick = () => {
		setIsLoading(true);
		setTimeout(() => setIsLoading(false), 2000);
	};

	const handleEditClick = () => {
		setIsEditing(!isEditing);
	};

	const getLoginButtonContent = (state: ButtonState) => {
		switch (state) {
			case 'loading':
				return t('login.loggingIn');
			case 'error':
				return (
					<span className="flex items-center justify-center text-red-500">
						<AlertOutlined className="mr-2 h-5 w-5" />
						{t('login.errorMessage')}
					</span>
				);
			case 'success':
				return t('login.success');
			default:
				return (
					<>
						{t('login.submit')}
						<ArrowRightOutlined className="ml-2 h-5 w-5" />
					</>
				);
		}
	};

	const getRegisterButtonContent = () => {
		switch (registerButtonState) {
			case 'loading':
				return t('register.creating');
			case 'error':
				return (
					<span className="flex items-center justify-center text-red-500">
						<AlertOutlined className="mr-2 h-5 w-5" />
						{t('register.errorMessage')}
					</span>
				);
			case 'success':
				return t('register.success');
			default:
				return (
					<>
						{t('register.submit')}
						<ArrowRightOutlined className="ml-2 h-5 w-5" />
					</>
				);
		}
	};

	const handleLoginClick = () => {
		setLoginButtonState('loading');
		setTimeout(() => {
			const isSuccess = Math.random() > 0.5;
			setLoginButtonState(isSuccess ? 'success' : 'error');
			setTimeout(() => setLoginButtonState('default'), 2000);
		}, 2000);
	};

	const handleGenericInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGenericInputValue(e.target.value);
	};

	const handleTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setTextInputValue(e.target.value);
	};

	const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmailInputValue(e.target.value);
	};

	const handlePasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPasswordInputValue(e.target.value);
	};

	const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setTextareaValue(e.target.value);
	};

	const handleNumberInputChange = (value: number | null) => {
		setNumberInputValue(value ?? 0);
	};

	const handleGenericNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGenericNumberInputValue(e.target.value);
	};

	return (
		<DesignContainer>
			<AnimatedBackground />
			<DesignContent>
				<AnimatedText text={t('designSystem')} />
				
				<Section>
					<H1>{t('animatedBackground')}</H1>
					<DesignSection>
						<H3>{t('usageAndPurpose')}</H3>
						<div>
							<Text>
								{t('animatedBackgroundDescription')}
							</Text>
							<ul>
								<li>{t('usageList.landingPages')}</li>
								<li>{t('usageList.sectionHeaders')}</li>
								<li>{t('usageList.loadingScreens')}</li>
								<li>{t('usageList.dashboardBackgrounds')}</li>
							</ul>
							<Text>
								{t('animationDescription')}
							</Text>
						</div>
					</DesignSection>
				</Section>

				<Section>
					<H1>{t('colors')}</H1>
					<DesignSection>
						<H3>{t('colorPalette')}</H3>
						<ColorPalette>
							{Object.entries(theme.colors).map(([colorName, colorValue]) => (
								<ColorSwatch key={colorName} bgColor={colorValue as string}>
									<span>{colorName}</span>
									<span>{String(colorValue)}</span>
								</ColorSwatch>
							))}
						</ColorPalette>
					</DesignSection>
				</Section>

				<Section>
					<H1>{t('typography')}</H1>
					<DesignSection>
						<H3>{t('hierarchyStyles')}</H3>
						<div>
							<H1>{t('h1Heading')}</H1>
							<H2>{t('h2Heading')}</H2>
							<H3>{t('h3Heading')}</H3>
							<H4>{t('h4Heading')}</H4>
							<Text>{t('paragraphText')}</Text>
							<Caption>{t('captionText')}</Caption>
							<Bold>{t('boldText')}</Bold>
						</div>
					</DesignSection>
				</Section>

				<Section>
					<H1>{t('buttons')}</H1>
					<DesignSection>
						<H3>{t('buttonTypes')}</H3>
						<ButtonWrapper>
							<Button $buttonstyle='primary' $size='medium'>{t('primaryButton')}</Button>
							<Button $buttonstyle='secondary' $size='medium'>{t('secondaryButton')}</Button>
							<Button $buttonstyle='outline' $size='medium'>{t('outlineButton')}</Button>
						</ButtonWrapper>
					</DesignSection>
					<DesignSection>
						<H3>{t('buttonSizes')}</H3>
						<ButtonWrapper>
							<Button $buttonstyle='primary' $size='small'>{t('smallButton')}</Button>
							<Button $buttonstyle='primary' $size='medium'>{t('mediumButton')}</Button>
							<Button $buttonstyle='primary' $size='large'>{t('largeButton')}</Button>
						</ButtonWrapper>
					</DesignSection>
					<DesignSection>
						<H3>{t('buttonStates')}</H3>
						<ButtonWrapper>
							<Button $buttonstyle='primary' $size='medium'>{t('normalButton')}</Button>
							<Button $buttonstyle='primary' $size='medium' disabled>{t('disabledButton')}</Button>
							<Button $buttonstyle='primary' $size='medium' loading={isLoading} onClick={handleLoadingClick}>
								{isLoading ? t('loadingButton') : t('clickToLoad')}
							</Button>
							<Button
								$buttonstyle={isEditing ? 'secondary' : 'primary'}
								$size="medium"
								onClick={handleEditClick}
							>
								<EditOutlined /> {isEditing ? t('save') : t('edit')}
							</Button>
						</ButtonWrapper>
					</DesignSection>
					<DesignSection>
						<H3>{t('fullWidthButton')}</H3>
						<Button $buttonstyle='primary' $size='medium' $fullWidth>{t('fullWidthButton')}</Button>
					</DesignSection>
					<DesignSection>
						<H3>{t('editButton')}</H3>
						<Button
							$buttonstyle={isEditing ? 'secondary' : 'primary'}
							$size="medium"
							onClick={handleEditClick}
						>
							<EditOutlined /> {isEditing ? t('save') : t('edit')}
						</Button>
					</DesignSection>
					<DesignSection>
						<H3>{t('loginButton')}</H3>
						<ResponsiveButtonWrapper>
							<LoadingButton
								onClick={handleLoginClick}
								buttonState={loginButtonState}
								getButtonContent={getLoginButtonContent}
							/>
						</ResponsiveButtonWrapper>
					</DesignSection>
					<DesignSection>
						<H3>{t('registerButton')}</H3>
						<ResponsiveButtonWrapper>
							<Button
								$buttonstyle='primary'
								$size='large'
								$fullWidth
								$loading={registerButtonState === 'loading'}
								onClick={() => setRegisterButtonState(prev => prev === 'default' ? 'loading' : 'default')}
							>
								{getRegisterButtonContent()}
							</Button>
						</ResponsiveButtonWrapper>
					</DesignSection>
				</Section>

				<Section>
					<H1>{t('inputs')}</H1>
					<DesignSection>
						<H3>{t('inputTypes')}</H3>
						<div>
							<InputWrapper>
								<GenericInputLazy
									placeholder={t('genericInputPlaceholder')}
									label={t('genericInputLabel')}
									value={genericInputValue}
									onChange={handleGenericInputChange}
									error={genericInputError}
								/>
								<Button onClick={() => setGenericInputError(genericInputError ? '' : 'Example error')}>
									Toggle Error
								</Button>
							</InputWrapper>
							<InputWrapper>
								<GenericInputLazy
									placeholder={t('textareaPlaceholder')}
									label={t('textareaLabel')}
									type="textarea"
								/>
							</InputWrapper>
							<InputWrapper>
								<NumberinputLazy 
									value={numberInputValue} 
									label={t('numberInputLabel')} 
									onChange={handleNumberInputChange}
								/>
							</InputWrapper>
							<InputWrapper>
								<PasswordInput placeholder={t('passwordInputPlaceholder')} />
							</InputWrapper>
							<InputWrapper>
								<SelectInputLazy 
									options={[
										{ value: 'option1', label: t('selectOption1') },
										{ value: 'option2', label: t('selectOption2') },
									]} 
									placeholder={t('selectPlaceholder')}
								/>
							</InputWrapper>
							<InputWrapper>
								<Checkbox
									checked={checkboxValue}
									onChange={(e) => setCheckboxValue(e.target.checked)}
								>
									{t('checkboxLabel')}
								</Checkbox>
							</InputWrapper>
							<InputWrapper>
								<GenericInputLazy
									type="text"
									placeholder={t('textInputPlaceholder')}
									label={t('textInputLabel')}
									value={textInputValue}
									onChange={handleTextInputChange}
								/>
							</InputWrapper>
							<InputWrapper>
								<GenericInputLazy
									type="email"
									placeholder={t('emailInputPlaceholder')}
									label={t('emailInputLabel')}
									value={emailInputValue}
									onChange={handleEmailInputChange}
								/>
							</InputWrapper>
							<InputWrapper>
								<GenericInputLazy
									type="password"
									placeholder={t('passwordInputPlaceholder')}
									label={t('passwordInputLabel')}
									value={passwordInputValue}
									onChange={handlePasswordInputChange}
								/>
							</InputWrapper>
							<InputWrapper>
								<GenericInputLazy
									type="textarea"
									placeholder={t('textareaPlaceholder')}
									label={t('textareaLabel')}
									value={textareaValue}
									onChange={handleTextareaChange}
								/>
							</InputWrapper>
							<InputWrapper>
								<GenericInputLazy
									type="number"
									placeholder={t('numberInputPlaceholder')}
									label={t('numberInputLabel')}
									value={genericNumberInputValue}
									onChange={handleGenericNumberInputChange}
								/>
							</InputWrapper>
							<InputWrapper>
								<GenericInputLazy
									type="text"
									placeholder={t('errorInputPlaceholder')}
									label={t('errorInputLabel')}
									error={t('errorInputMessage')}
								/>
							</InputWrapper>
						</div>
					</DesignSection>
				</Section>
			</DesignContent>
		</DesignContainer>
	);
});

// Bileşenleri export et
export { Button, H1, H2, H3, H4, Text, Bold, Caption };
