import * as Yup from 'yup';

export const productDetailSchema = Yup.object().shape({
	name: Yup.string()
		.required('validation.required')
		.min(3, 'validation.minLength')
		.max(100, 'validation.maxLength'),
	description: Yup.string()
		.required('validation.required')
		.min(10, 'validation.minLength')
		.max(1000, 'validation.maxLength'),
	category: Yup.string().required('validation.required'),
	price: Yup.number().required('validation.required').min(0, 'validation.minPrice'),
	stock: Yup.number()
		.required('validation.required')
		.min(0, 'validation.minStock')
		.integer('validation.integer'),
	status: Yup.string()
		.oneOf(['active', 'inactive'], 'validation.invalidStatus')
		.required('validation.required'),
	channels: Yup.array().of(
		Yup.object().shape({
			price: Yup.number().min(0, 'validation.minPrice').required('validation.required'),
			stock: Yup.number()
				.min(0, 'validation.minStock')
				.integer('validation.integer')
				.required('validation.required'),
			priceRule: Yup.string().required('validation.required'),
			stockRule: Yup.string().required('validation.required'),
		})
	),
	features: Yup.array().of(
		Yup.string().required('validation.required').min(3, 'validation.minLength')
	),
	metadata: Yup.object().shape({
		images: Yup.array()
			.of(Yup.string())
			.min(1, 'validation.minImages')
			.max(10, 'validation.maxImages')
			.required('validation.required'),
	}),
	hasVariants: Yup.boolean(),
	variants: Yup.mixed().when('hasVariants', {
		is: true,
		then: () =>
			Yup.array().of(
				Yup.object().shape({
					sku: Yup.string().required('validation.required'),
					price: Yup.number().min(0, 'validation.minPrice').required('validation.required'),
					stock: Yup.number()
						.min(0, 'validation.minStock')
						.integer('validation.integer')
						.required('validation.required'),
					isActive: Yup.boolean().required('validation.required'),
					options: Yup.array()
						.of(
							Yup.object().shape({
								name: Yup.string().required('validation.required'),
								value: Yup.string().required('validation.required'),
							})
						)
						.required('validation.required'),
					images: Yup.array().of(Yup.string()),
				})
			),
		otherwise: () => Yup.array(),
	}),
	variantOptions: Yup.mixed().when('hasVariants', {
		is: true,
		then: () =>
			Yup.object()
				.shape({})
				.test({
					name: 'variant-options-validation',
					test: (value, context) => {
						if (!value || Object.keys(value).length === 0) {
							return context.createError({
								message: 'validation.variantOptionsRequired',
							});
						}

						// Seçenek isimlerinin benzersiz olduğunu kontrol et
						const optionNames = Object.keys(value);
						const uniqueNames = new Set(optionNames);
						if (uniqueNames.size !== optionNames.length) {
							return context.createError({
								message: 'variants.validation.duplicateOptionName',
							});
						}

						// Her seçeneğin değerlerinin boş olmadığını ve benzersiz olduğunu kontrol et
						for (const [optionName, values] of Object.entries(value)) {
							if (!Array.isArray(values) || values.length === 0) {
								return context.createError({
									message: 'variants.validation.emptyOptionValues',
								});
							}

							const uniqueValues = new Set(values);
							if (uniqueValues.size !== values.length) {
								return context.createError({
									message: 'variants.validation.duplicateOptionValue',
								});
							}
						}

						return true;
					},
				}),
		otherwise: () => Yup.object(),
	}),
});
