import { AccountSettingsActionTypes } from './accountSettings.actions';
import { UserData, CompanyData } from './accountSettings.types';

export interface IAccountSettingsState {
  user: UserData | null;
  company: CompanyData | null;
  loading: boolean;
  error: string | null;
}

const initialState: IAccountSettingsState = {
  user: null,
  company: null,
  loading: false,
  error: null,
};

type AccountSettingsAction = 
  | { type: AccountSettingsActionTypes.FETCH_USER_DATA }
  | { type: AccountSettingsActionTypes.FETCH_USER_DATA_SUCCESS; payload: UserData }
  | { type: AccountSettingsActionTypes.FETCH_USER_DATA_FAILURE; payload: string }
  | { type: AccountSettingsActionTypes.UPDATE_USER_DATA }
  | { type: AccountSettingsActionTypes.UPDATE_USER_DATA_SUCCESS; payload: UserData }
  | { type: AccountSettingsActionTypes.UPDATE_USER_DATA_FAILURE; payload: string }
  | { type: AccountSettingsActionTypes.FETCH_COMPANY_DATA }
  | { type: AccountSettingsActionTypes.FETCH_COMPANY_DATA_SUCCESS; payload: CompanyData }
  | { type: AccountSettingsActionTypes.FETCH_COMPANY_DATA_FAILURE; payload: string }
  | { type: AccountSettingsActionTypes.CREATE_COMPANY }
  | { type: AccountSettingsActionTypes.CREATE_COMPANY_SUCCESS; payload: CompanyData }
  | { type: AccountSettingsActionTypes.CREATE_COMPANY_FAILURE; payload: string }
  | { type: AccountSettingsActionTypes.GET_TENANT }
  | { type: AccountSettingsActionTypes.GET_TENANT_SUCCESS; payload: CompanyData }
  | { type: AccountSettingsActionTypes.GET_TENANT_FAILURE; payload: string };

export const accountSettingsReducer = (
  state = initialState,
  action: AccountSettingsAction
): IAccountSettingsState => {
  switch (action.type) {
    case AccountSettingsActionTypes.FETCH_USER_DATA:
    case AccountSettingsActionTypes.UPDATE_USER_DATA:
    case AccountSettingsActionTypes.FETCH_COMPANY_DATA:
    case AccountSettingsActionTypes.CREATE_COMPANY:
    case AccountSettingsActionTypes.GET_TENANT:
      return { ...state, loading: true, error: null };
    case AccountSettingsActionTypes.FETCH_USER_DATA_SUCCESS:
    case AccountSettingsActionTypes.UPDATE_USER_DATA_SUCCESS:
      return { ...state, loading: false, user: action.payload, error: null };
    case AccountSettingsActionTypes.FETCH_COMPANY_DATA_SUCCESS:
    case AccountSettingsActionTypes.CREATE_COMPANY_SUCCESS:
    case AccountSettingsActionTypes.GET_TENANT_SUCCESS:
      return { ...state, loading: false, company: action.payload, error: null };
    case AccountSettingsActionTypes.FETCH_USER_DATA_FAILURE:
    case AccountSettingsActionTypes.UPDATE_USER_DATA_FAILURE:
    case AccountSettingsActionTypes.FETCH_COMPANY_DATA_FAILURE:
    case AccountSettingsActionTypes.CREATE_COMPANY_FAILURE:
    case AccountSettingsActionTypes.GET_TENANT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
