export function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return null;
}

// Function to set a cookie
export function setCookie(name: string, value: string, options: { secure?: boolean; httpOnly?: boolean; sameSite?: 'Strict' | 'Lax' | 'None' } = {}): void {
  let cookieString = `${name}=${value}; path=/`;

  if (options.secure) {
    cookieString += '; Secure';
  }
  if (options.httpOnly) {
    // Note: HttpOnly is not accessible via JavaScript, this is just for demonstration
    cookieString += '; HttpOnly';
  }
  if (options.sameSite) {
    cookieString += `; SameSite=${options.sameSite}`;
  }

  document.cookie = cookieString;
}

// Function to delete a cookie
export function deleteCookie(name: string): void {
  document.cookie = `${name}=; Max-Age=0; path=/`;
}