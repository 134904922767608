import { configureStore, ConfigureStoreOptions, combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer, IGlobalState } from './reducers';
import rootSaga from './sagas';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';

// Initialize Saga Middleware
const sagaMiddleware = createSagaMiddleware();

// Create Root Reducer
const rootReducer = createRootReducer();

// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['LOGIN', 'language'], // Only persist these reducers
};

// Define a new type that includes PersistPartial
export type PersistedGlobalState = IGlobalState & PersistPartial;

// Create a type-safe persisted reducer
const persistedReducer = persistReducer<IGlobalState>(persistConfig, rootReducer);

// Define Store Configuration
const storeOptions: ConfigureStoreOptions<PersistedGlobalState> = {
  reducer: persistedReducer as Reducer<PersistedGlobalState, AnyAction>,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
};

// Configure and Export Store
export function configureAppStore() {
  const store = configureStore(storeOptions);
  
  // Run Root Saga
  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
}

// Export the store type
export type AppStore = ReturnType<typeof configureAppStore>['store'];
export type AppState = ReturnType<AppStore['getState']>;
