import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Segmented, Tooltip, Progress, Typography } from 'antd';
import { 
  ShoppingOutlined, 
  DollarOutlined, 
  UserOutlined, 
  RiseOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  TagOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Line } from '@ant-design/charts';
import { IGlobalState } from '../../redux/reducers';
import { H1, H2 } from '../../components/Design';
import {
  DashboardContainer,
  WelcomeSection,
  WelcomeContent,
  StatsContainer,
  StatCard,
  StatValue,
  StatLabel,
  GraphSection,
  MetricsList,
  MetricCard,
  MetricInfo,
  MetricIcon,
  MetricDetails,
  MetricName,
  MetricSubtext,
  MetricStats,
  MetricTrend,
  PremiumCard,
  ChartContainer,
  ChartHeader,
  InsightCard,
  InsightIcon,
  InsightContent,
  TopProductsList,
  TopProductItem,
  ProductRank,
  ProductInfo,
  ProductStats,
  PerformanceMetrics,
  MetricSegment,
  GoalProgress,
  TimeframeSelector
} from './dashboard.styled';
import { useTheme } from 'styled-components';

const { Text: AntText } = Typography;

interface Insight {
  id: number;
  type: 'positive' | 'warning' | 'negative';
  message: string;
  icon: React.ReactNode;
}

const Dashboard: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const user = useSelector((state: IGlobalState) => state.LOGIN.user);
  const [timeframe, setTimeframe] = useState<string>('week');
  const theme = useTheme();

  // Revenue chart data
  const revenueData = [
    { date: '2024-01-01', revenue: 15000, orders: 120 },
    { date: '2024-01-02', revenue: 18000, orders: 145 },
    { date: '2024-01-03', revenue: 22000, orders: 180 },
    { date: '2024-01-04', revenue: 17000, orders: 130 },
    { date: '2024-01-05', revenue: 25000, orders: 200 },
    { date: '2024-01-06', revenue: 28000, orders: 220 },
    { date: '2024-01-07', revenue: 24000, orders: 190 },
  ];

  // Top products data
  const topProducts = [
    { rank: 1, name: 'Wireless Earbuds Pro', sales: 234, revenue: 23400, trend: '+12%' },
    { rank: 2, name: 'Smart Watch Series 5', sales: 189, revenue: 37800, trend: '+8%' },
    { rank: 3, name: 'Premium Laptop Stand', sales: 156, revenue: 15600, trend: '+5%' },
  ];

  // Performance metrics
  const performanceMetrics = [
    { id: 1, name: t('totalOrders'), value: '1,234', trend: '+12.5%', period: t('thisMonth'), icon: <ShoppingOutlined /> },
    { id: 2, name: t('totalRevenue'), value: '₺152,345', trend: '+8.2%', period: t('thisMonth'), icon: <DollarOutlined /> },
    { id: 3, name: t('activeCustomers'), value: '892', trend: '+5.8%', period: t('thisMonth'), icon: <UserOutlined /> },
    { id: 4, name: t('conversionRate'), value: '3.2%', trend: '+1.5%', period: t('thisMonth'), icon: <RiseOutlined /> },
    { id: 5, name: t('avgOrderValue'), value: '₺123', trend: '+3.2%', period: t('thisMonth'), icon: <ShoppingCartOutlined /> },
    { id: 6, name: t('customerSatisfaction'), value: '4.8', trend: '+0.3', period: t('thisMonth'), icon: <StarOutlined /> },
  ];

  // Business insights
  const insights: Insight[] = [
    { id: 1, type: 'positive', message: t('insights.topSeller'), icon: <TagOutlined /> },
    { id: 2, type: 'warning', message: t('insights.inventory'), icon: <ClockCircleOutlined /> },
  ];

  interface ISalesDistribution {
    category: string;
    value: number;
    percentage: number;
  }

  const salesDistribution: ISalesDistribution[] = [
    { category: t('categories.electronics'), value: 45000, percentage: 35 },
    { category: t('categories.fashion'), value: 35000, percentage: 28 },
    { category: t('categories.home'), value: 25000, percentage: 20 },
    { category: t('categories.beauty'), value: 15000, percentage: 12 },
    { category: t('categories.sports'), value: 8000, percentage: 5 },
  ];

  const revenueConfig = {
    data: revenueData,
    xField: 'date',
    yField: 'revenue',
    smooth: true,
    point: {
      size: 4,
      shape: 'circle',
      style: {
        fill: theme.colors.white,
        stroke: theme.colors.primary,
        lineWidth: 1.5,
      },
    },
    line: {
      style: {
        stroke: theme.colors.primary,
        lineWidth: 1.5,
      }
    },
    area: {
      style: {
        fill: `l(270) 0:${theme.colors.primary}10 1:${theme.colors.primary}01`,
      }
    },
    tooltip: {
      showMarkers: false,
      formatter: (datum: any) => {
        return { name: t('revenue'), value: `₺${datum.revenue.toLocaleString()}` };
      },
    },
    interactions: [{ type: 'marker-active' }],
  };

  return (
    <DashboardContainer>
      <WelcomeSection>
        <WelcomeContent>
          <H1>{t('welcome', { name: user?.name || 'User' })}</H1>
          <AntText>{t('welcomeBack')}</AntText>
        </WelcomeContent>
        <StatsContainer>
          <StatCard>
            <StatValue>₺25,430</StatValue>
            <StatLabel>{t('todayRevenue')}</StatLabel>
            <Tooltip title={t('tooltips.todayRevenue')}>
              <InfoCircleOutlined style={{ marginLeft: 8 }} />
            </Tooltip>
          </StatCard>
          <StatCard>
            <StatValue>156</StatValue>
            <StatLabel>{t('todayOrders')}</StatLabel>
            <Tooltip title={t('tooltips.todayOrders')}>
              <InfoCircleOutlined style={{ marginLeft: 8 }} />
            </Tooltip>
          </StatCard>
        </StatsContainer>
      </WelcomeSection>

      <PerformanceMetrics>
        <ChartHeader>
          <H2>{t('performanceOverview')}</H2>
          <TimeframeSelector>
            <Segmented
              options={['week', 'month', 'quarter', 'year']}
              value={timeframe}
              onChange={(value) => setTimeframe(value.toString())}
            />
          </TimeframeSelector>
        </ChartHeader>

        <MetricsList>
          {performanceMetrics.map(metric => (
            <MetricCard key={metric.id}>
              <MetricInfo>
                <MetricIcon>
                  {metric.icon}
                </MetricIcon>
                <MetricDetails>
                  <MetricName>{metric.name}</MetricName>
                  <MetricSubtext>{metric.period}</MetricSubtext>
                </MetricDetails>
              </MetricInfo>
              <MetricStats>
                <StatValue>{metric.value}</StatValue>
                <MetricTrend $isPositive={metric.trend.startsWith('+')}>
                  {metric.trend}
                </MetricTrend>
              </MetricStats>
            </MetricCard>
          ))}
        </MetricsList>

        <ChartContainer>
          <MetricSegment>
            <GraphSection title={t('revenueStatistics')}>
              <Line {...revenueConfig} />
            </GraphSection>
          </MetricSegment>
          <MetricSegment>
            <GraphSection title={t('salesDistribution')}>
              <div style={{ padding: '20px' }}>
                {salesDistribution.map(item => (
                  <div key={item.category} style={{ marginBottom: '16px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                      <AntText>{item.category}</AntText>
                      <AntText>₺{item.value.toLocaleString()} ({item.percentage}%)</AntText>
                    </div>
                    <Progress 
                      percent={item.percentage} 
                      showInfo={false}
                      strokeColor={{
                        '0%': theme.colors.primary,
                        '100%': theme.colors.grey600
                      }}
                      trailColor={theme.colors.grey100}
                    />
                  </div>
                ))}
              </div>
            </GraphSection>
          </MetricSegment>
        </ChartContainer>

        <GoalProgress>
          <H2>{t('monthlyGoals')}</H2>
          <div style={{ marginTop: 16 }}>
            <AntText>{t('revenueGoal')}</AntText>
            <Progress 
              percent={75} 
              status="active" 
              strokeColor={{
                '0%': theme.colors.primary,
                '100%': theme.colors.grey600
              }}
              trailColor={theme.colors.grey100}
            />
            <AntText>{t('ordersGoal')}</AntText>
            <Progress 
              percent={85} 
              status="active"
              strokeColor={{
                '0%': theme.colors.primary,
                '100%': theme.colors.grey600
              }}
              trailColor={theme.colors.grey100}
            />
            <AntText>{t('customersGoal')}</AntText>
            <Progress 
              percent={60} 
              status="active"
              strokeColor={{
                '0%': theme.colors.primary,
                '100%': theme.colors.grey600
              }}
              trailColor={theme.colors.grey100}
            />
          </div>
        </GoalProgress>

        <TopProductsList>
          <H2>{t('topProducts')}</H2>
          {topProducts.map(product => (
            <TopProductItem key={product.rank}>
              <ProductRank>
                #{product.rank}
              </ProductRank>
              <ProductInfo>
                <AntText>{product.name}</AntText>
                <AntText type="secondary">{product.sales} {t('sales')}</AntText>
              </ProductInfo>
              <ProductStats>
                <AntText>₺{product.revenue.toLocaleString()}</AntText>
                <div className={`metric-trend ${product.trend.startsWith('+') ? 'positive' : 'negative'}`}>
                  {product.trend}
                </div>
              </ProductStats>
            </TopProductItem>
          ))}
        </TopProductsList>

        {insights.map(insight => (
          <InsightCard key={insight.id} $type={insight.type}>
            <InsightIcon>{insight.icon}</InsightIcon>
            <InsightContent>{insight.message}</InsightContent>
          </InsightCard>
        ))}

        <PremiumCard>
          <div className="content-wrapper">
            <H1>{t('upgradeAccount')}</H1>
            <AntText>{t('unlockFeatures')}</AntText>
            <Button type="primary">
              {t('upgrade')}
            </Button>
          </div>
          <div className="emoji-container">✨</div>
        </PremiumCard>
      </PerformanceMetrics>
    </DashboardContainer>
  );
};

export default Dashboard;
