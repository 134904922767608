import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../../pages/Dashboard/components/Footer';
import { LayoutContainer, MainContent, ContentWrapper, ScrollableContent } from './layout.styled';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/reducers';

const Layout: React.FC<PropsWithChildren> = () => {
  const user = useSelector((state: IGlobalState) => state.LOGIN.user);

  return (
    <LayoutContainer>
      <Sidebar />
      <MainContent>
        <Header />
        <ContentWrapper>
          <ScrollableContent>
            <Outlet />
          </ScrollableContent>
        </ContentWrapper>
        <Footer />
      </MainContent>
    </LayoutContainer>
  );
};

export default React.memo(Layout);
