"use client"

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { motion } from 'framer-motion'; // motion import'unu ekledik
import { IGlobalState } from '../../../redux/reducers';
import { loginActionCreator } from '../login.action';
import { ILoginState } from '../login.reducer';
import { loginSchema } from '../login.validations';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextInputLazy } from '../../../components/Input/TextInput/text-input.lazy';
import { ModernBackground } from './ModernBackground';
import { SocialLoginButtons } from './SocialLoginButtons';
import { Text, H1, Checkbox } from '../../../components/Design/design.styled';
import {
  LoginContainer,
  LoginFormWrapper,
  LoginForm,
  StyledLink,
  CheckboxContainer,
  LinkContainer,
  LogoContainer,
  Divider,
  FormContainer,
  WelcomeText,
} from './login.styled';
import { Shield, Zap, Globe } from 'lucide-react';
import { ButtonState, LoadingButton } from '../../../components/LoadingButton';

interface LoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const UserLogin: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching, error, user } = useSelector<IGlobalState, ILoginState>(state => state?.LOGIN);
  const { t } = useTranslation('login');
  const [loginButtonState, setLoginButtonState] = useState<ButtonState>('default');
  const theme = useTheme();

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoginButtonState('loading');
      dispatch(loginActionCreator(values.email, values.password, values.rememberMe));
    },
  });

  useEffect(() => {
    if (!isFetching) {
      if (error) {
        setLoginButtonState('error');
      } else if (user) {
        setLoginButtonState('success');
        navigate('/dashboard');
      } else {
        setLoginButtonState('default');
      }
    }
  }, [isFetching, error, user, navigate]);

  const getButtonContent = useCallback((state: ButtonState): string => {
    switch (state) {
      case 'loading':
        return t('loggingIn');
      case 'success':
        return t('success');
      case 'error':
        return t('error');
      default:
        return t('submit');
    }
  }, [t]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <LoginContainer>
      <ModernBackground />
      <LoginFormWrapper>
        <LoginForm
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <WelcomeText
            variants={container}
            initial="hidden"
            animate="show"
          >
            <motion.h1 variants={item}>
              Watoz Yönetim Paneli
            </motion.h1>
            
            <motion.p 
              className="description"
              variants={item}
            >
              İşletmenizi daha verimli yönetmek için ihtiyacınız olan tüm araçlar bir arada. 
              Gerçek zamanlı analizler, detaylı raporlar ve kullanıcı dostu arayüz ile 
              işlerinizi kolaylaştırın.
            </motion.p>

            <motion.div 
              className="features"
              variants={container}
            >
              <motion.div className="feature" variants={item}>
                <Shield size={18} />
                <span>Güvenli Erişim</span>
              </motion.div>
              <motion.div className="feature" variants={item}>
                <Zap size={18} />
                <span>Hızlı Performans</span>
              </motion.div>
              <motion.div className="feature" variants={item}>
                <Globe size={18} />
                <span>7/24 Destek</span>
              </motion.div>
            </motion.div>
          </WelcomeText>

          <FormContainer onSubmit={formik.handleSubmit} noValidate>
            <div className="input-wrapper">
              <TextInputLazy
                label={t('email')}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!(formik.touched.email && formik.errors.email)}
                icon="mail"
                aria-label={t('email')}
              />
            </div>

            <div className="input-wrapper">
              <TextInputLazy
                label={t('password')}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!(formik.touched.password && formik.errors.password)}
                icon="lock"
                aria-label={t('password')}
              />
            </div>

            <div className="checkbox-wrapper">
              <CheckboxContainer>
                <Checkbox
                  id="rememberMe"
                  name="rememberMe"
                  checked={formik.values.rememberMe}
                  onChange={formik.handleChange}
                  aria-label={t('rememberMe')}
                  $variant="secondary"
                >
                  {t('rememberMe')}
                </Checkbox>
              </CheckboxContainer>
            </div>

            <div className="button-wrapper">
              <LoadingButton
                onClick={formik.handleSubmit}
                buttonState={loginButtonState}
                getButtonContent={getButtonContent}
                disabled={loginButtonState === 'loading'}
                aria-label={getButtonContent(loginButtonState)}
              />
            </div>
          </FormContainer>

          <Divider>
            <span>{t('login:orContinueWith')}</span>
          </Divider>

          <SocialLoginButtons />

          <LinkContainer>
            <Text 
              size="small" 
              style={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >
              {t('noAccount')}{' '}
              <StyledLink to="/kayit-ol">
                {t('register')}
              </StyledLink>
            </Text>
          </LinkContainer>

          <LinkContainer style={{ marginTop: '8px' }}>
            <StyledLink to="/sifre-sifirlama">
              <Text 
                size="small"
                style={{ color: 'inherit' }}
              >
                {t('forgotPassword')}
              </Text>
            </StyledLink>
          </LinkContainer>
        </LoginForm>
      </LoginFormWrapper>
    </LoginContainer>
  );
};

export const MemoizedUserLogin = React.memo(UserLogin);
