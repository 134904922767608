import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const ButtonsContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    gap: 0.75rem;
  }
`;

const SocialButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.login.buttons.social.border};
  background: ${({ theme }) => theme.login.buttons.social.background};
  color: ${({ theme }) => theme.login.buttons.social.color};
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  backdrop-filter: blur(10px);

  &:hover {
    background: ${({ theme }) => theme.login.buttons.social.hoverBackground};
    transform: translateY(-1px);
    border-color: ${({ theme }) => theme.login.buttons.social.hoverBorder};
  }

  &:active {
    transform: translateY(0);
  }

  svg {
    width: 20px;
    height: 20px;
    transition: all 0.2s ease;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding: 0.6rem;
    font-size: 0.85rem;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const GoogleIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z" />
  </svg>
);

const AppleIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M17.05 20.28c-.98.95-2.05.8-3.08.35-1.09-.46-2.09-.48-3.24 0-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8 1.18-.24 2.31-.93 3.57-.84 1.51.12 2.65.72 3.4 1.8-3.12 1.87-2.38 5.98.48 7.13-.57 1.5-1.31 2.99-2.53 4.08zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25.29 2.58-2.34 4.5-3.74 4.25z"/>
  </svg>
);

const buttonVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  hover: { scale: 1.02 }
};

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

export const SocialLoginButtons: React.FC = () => {
  const { t } = useTranslation('login');
  
  const handleSocialLogin = (provider: string) => {
    console.log(`${provider} login initiated`);
  };

  return (
    <ButtonsContainer
      variants={containerVariants}
      initial="initial"
      animate="animate"
    >
      <SocialButton
        onClick={() => handleSocialLogin('Google')}
        variants={buttonVariants}
        whileHover="hover"
        whileTap={{ scale: 0.98 }}
        aria-label="Google ile giriş yap"
      >
        <GoogleIcon />
        Google
      </SocialButton>
      
      <SocialButton
        onClick={() => handleSocialLogin('Apple')}
        variants={buttonVariants}
        whileHover="hover"
        whileTap={{ scale: 0.98 }}
        aria-label="Apple ile giriş yap"
      >
        <AppleIcon />
        Apple
      </SocialButton>
    </ButtonsContainer>
  );
};

export default SocialLoginButtons;
