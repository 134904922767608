import { Action } from 'redux';
import { UserData } from './accountSettings.types';
import { CompanyData } from './accountSettings.types';

export enum AccountSettingsActionTypes {
  FETCH_USER_DATA = '[Account Settings] Fetch User Data',
  FETCH_USER_DATA_SUCCESS = '[Account Settings] Fetch User Data Success',
  FETCH_USER_DATA_FAILURE = '[Account Settings] Fetch User Data Failure',
  UPDATE_USER_DATA = '[Account Settings] Update User Data',
  UPDATE_USER_DATA_SUCCESS = '[Account Settings] Update User Data Success',
  UPDATE_USER_DATA_FAILURE = '[Account Settings] Update User Data Failure',
  FETCH_COMPANY_DATA = '[Account Settings] Fetch Company Data',
  FETCH_COMPANY_DATA_SUCCESS = '[Account Settings] Fetch Company Data Success',
  FETCH_COMPANY_DATA_FAILURE = '[Account Settings] Fetch Company Data Failure',
  CREATE_COMPANY = '[Account Settings] Create Company',
  CREATE_COMPANY_SUCCESS = '[Account Settings] Create Company Success',
  CREATE_COMPANY_FAILURE = '[Account Settings] Create Company Failure',
  GET_TENANT = '[Account Settings] Get Tenant',
  GET_TENANT_SUCCESS = '[Account Settings] Get Tenant Success',
  GET_TENANT_FAILURE = '[Account Settings] Get Tenant Failure',
}

export interface FetchUserDataAction extends Action {
  type: AccountSettingsActionTypes.FETCH_USER_DATA;
}

export interface UpdateUserDataAction extends Action {
  type: AccountSettingsActionTypes.UPDATE_USER_DATA;
  payload: UserData;
}

export const fetchUserData = (): FetchUserDataAction => ({
  type: AccountSettingsActionTypes.FETCH_USER_DATA,
});

export const updateUserData = (userData: UserData): UpdateUserDataAction => ({
  type: AccountSettingsActionTypes.UPDATE_USER_DATA,
  payload: userData,
});

export const updateUserDataSuccess = (userData: UserData) => ({
  type: AccountSettingsActionTypes.UPDATE_USER_DATA_SUCCESS,
  payload: userData,
});

export const updateUserDataFailure = (error: string) => ({
  type: AccountSettingsActionTypes.UPDATE_USER_DATA_FAILURE,
  payload: error,
});

export const fetchCompanyData = () => ({
  type: AccountSettingsActionTypes.FETCH_COMPANY_DATA,
});

export const fetchCompanyDataSuccess = (companyData: CompanyData) => ({
  type: AccountSettingsActionTypes.FETCH_COMPANY_DATA_SUCCESS,
  payload: companyData,
});

export const fetchCompanyDataFailure = (error: string) => ({
  type: AccountSettingsActionTypes.FETCH_COMPANY_DATA_FAILURE,
  payload: error,
});

export const createCompany = (companyData: any) => ({
  type: AccountSettingsActionTypes.CREATE_COMPANY,
  payload: companyData,
});

export const createCompanySuccess = (company: any) => ({
  type: AccountSettingsActionTypes.CREATE_COMPANY_SUCCESS,
  payload: company,
});

export const createCompanyFailure = (error: string) => ({
  type: AccountSettingsActionTypes.CREATE_COMPANY_FAILURE,
  payload: error,
});

export const getTenant = () => ({
  type: AccountSettingsActionTypes.GET_TENANT,
});

export const getTenantSuccess = (tenant: CompanyData) => ({
  type: AccountSettingsActionTypes.GET_TENANT_SUCCESS,
  payload: tenant,
});

export const getTenantFailure = (error: string) => ({
  type: AccountSettingsActionTypes.GET_TENANT_FAILURE,
  payload: error,
});
