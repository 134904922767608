import { Badge } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.headerBackground};
  height: 100px;
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const SummaryItem = styled.div`
  text-align: center;

  span {
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.colors.primary};
  }

  strong {
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin-bottom: 16px;
`;

export const NotificationIcon = styled(Badge)`
  .ant-badge-count {
    background-color: ${({ theme }) => theme.colors.error};
  }
`;

export const MobileMenuButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 16px;
`;

export const DesktopView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }

  .left-section, .right-section {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`;

export const MobileView = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;
