import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FormContainer as LoginFormContainer } from '../UserLogin/login.styled';

export const ResetContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0.5rem;
  }
`;

export const ResetFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    max-width: 100%;
  }
`;

export const ResetForm = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  padding: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding: 1.5rem;
    border-radius: 16px;
  }
`;

export const FormContainer = styled(LoginFormContainer)`
  // Reset Password'a özel stil override'ları buraya eklenebilir
`;

export const Title = styled(motion.h1)`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Description = styled(motion.p)`
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1rem;
  line-height: 1.6;

  strong {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
  }
`;

export const ActionButton = styled(motion.button)<{ $variant?: 'primary' | 'secondary' }>`
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, $variant }) => 
    $variant === 'secondary' 
      ? 'rgba(255, 255, 255, 0.1)'
      : `linear-gradient(135deg, ${theme.colors.primary} 0%, ${theme.colors.primaryDark} 100%)`};

  &:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: ${({ theme, $variant }) => 
      $variant === 'secondary'
        ? 'rgba(255, 255, 255, 0.15)'
        : `linear-gradient(135deg, ${theme.colors.primaryDark} 0%, ${theme.colors.primary} 100%)`};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const LinkContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.fonts.weights.semiBold};
  text-decoration: none;
  transition: all 0.2s ease;
  margin-left: 0.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;
