import React from 'react';
import { Outlet } from 'react-router-dom';
import AnimatedBackground from '../../components/AnimatedBackground';
import ScrollingFooter from '../../components/ScrollingFooter';
import styled from 'styled-components';
import LanguageSwitcher from '../../components/LanguageSwitcher';

const LoginContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  background-color: #1a202c;
  overflow: hidden;
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

const Content = styled.div``;

const Login: React.FC = () => {
  return (
    <LoginContainer>
      <Header>
        <LanguageSwitcher />
      </Header>
      <Content>
        <Outlet />
      </Content>
      {/* <ScrollingFooter /> */}
    </LoginContainer>
  );
};

export default Login;
