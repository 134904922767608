import { ThemeConfig } from 'antd/es/config-provider';

const loginThemeColors = {
	primary: '#4F46E5',
	primaryDark: '#3730A3',
	primaryLight: '#6366F1',
	secondary: '#00BF91',
	accent: '#004C3C',
	hover: '#2CC2A5',
	error: '#EF4444',
	success: '#22C55E',
	warning: '#FFC107',
	background: 'rgba(255, 255, 255, 0.05)',
	text: '#FFFFFF',
	textSecondary: 'rgba(255, 255, 255, 0.7)',
	border: 'rgba(255, 255, 255, 0.1)',
	divider: 'rgba(255, 255, 255, 0.1)',
	buttonGradient: {
		start: '#4F46E5',
		end: '#3730A3',
	},
	socialButton: {
		background: 'rgba(255, 255, 255, 0.03)',
		border: 'rgba(255, 255, 255, 0.1)',
		hoverBackground: 'rgba(255, 255, 255, 0.07)',
		hoverBorder: 'rgba(255, 255, 255, 0.2)',
		text: 'rgba(255, 255, 255, 0.9)',
	},
};

export interface ThemeColors {
	black: string;
	grey900: string;
	grey800: string;
	grey700: string;
	primary: string;
	primaryDark: string;
	primaryLight: string;
	secondary: string;
	grey600: string;
	success: string;
	successDark: string;
	error: string;
	errorDark: string;
	grey500: string;
	grey400: string;
	grey300: string;
	grey200: string;
	grey100: string;
	white: string;
	text: string;
	textSecondary: string;
	background: string;
	sidebarBackground: string;
	warning: string;
	accent: string;
	hover: string;
	activeBackground: string;
	hoverBackground: string;
	hoverBackgroundLight: string;
	divider: string;
	buttonPrimary: string;
	buttonSecondary: string;
	buttonSuccess: string;
	buttonError: string;
	buttonWarning: string;
	disabled: string;
}

interface ThemeFontSizes {
	small: string;
	medium: string;
	large: string;
	extraLarge: string;
	xxLarge: string;
}

interface ThemeResponsiveFontSizes {
	small: string;
	medium: string;
	large: string;
	extraLarge: string;
	xxLarge: string;
}

interface ThemeButton {
	colors: {
		primary: string;
		secondary: string;
		success: string;
		error: string;
		warning: string;
	};
}

interface ThemeFonts {
	primary: string;
	secondary: string;
	weights: {
		thin: number;
		extraLight: number;
		light: number;
		regular: number;
		medium: number;
		semiBold: number;
		bold: number;
	};
}

interface ThemeSpacings {
	none: string;
	small: string;
	medium: string;
	large: string;
	extraLarge: string;
}

interface ThemeBreakpoints {
	mobileS: string;
	mobileM: string;
	mobileL: string;
	tablet: string;
	laptop: string;
	laptopL: string;
	desktop: string;
}

interface ThemeLayout {
	maxWidth: string;
	containerPadding: {
		mobile: string;
		tablet: string;
		desktop: string;
	};
}

interface ThemeShadows {
	small: string;
	medium: string;
	large: string;
}

interface ThemeLogin {
	colors: typeof loginThemeColors;
	buttons: {
		primary: {
			background: string;
			color: string;
			hoverBackground: string;
			disabledOpacity: number;
			gradient: string;
			hoverGradient: string;
		};
		secondary: {
			background: string;
			color: string;
			hoverBackground: string;
			disabledOpacity: number;
		};
		social: {
			background: string;
			color: string;
			border: string;
			hoverBackground: string;
			hoverBorder: string;
		};
	};
	forms: {
		background: string;
		borderRadius: string;
		padding: string;
		border: string;
		boxShadow: string;
		backdropFilter: string;
	};
}

// Temel renk ve değerleri tanımlayalım
const baseColors = {
	light: {
		primary: '#262626',
		white: '#FFFFFF',
		grey100: '#F5F5F5',
		text: '#262626',
		textSecondary: '#525252',
		divider: '#d4d4d4',
		hover: '#2CC2A5',
		background: '#f5f5f5',
	},
	dark: {
		primary: '#f5f5f5',
		background: '#171717',
		text: '#f5f5f5',
		textSecondary: '#d4d4d4',
		divider: '#525252',
		hover: '#95FFF1',
	},
};

// Ant Design token'ları için temel değerler
const baseAntTokens = {
	light: {
		token: {
			colorPrimary: baseColors.light.primary,
			colorBgContainer: baseColors.light.white,
			colorBgElevated: baseColors.light.white,
			colorBgLayout: baseColors.light.grey100,
			colorText: baseColors.light.text,
			colorTextSecondary: baseColors.light.textSecondary,
			colorBorder: baseColors.light.divider,
			borderRadius: 6,
			controlHeight: 32,
			controlHeightSM: 24,
			controlHeightLG: 40,
			fontSize: 14,
			fontSizeSM: 12,
			fontSizeLG: 16,
		},
		components: {
			Form: {
				labelFontSize: 14,
				verticalLabelPadding: 2,
				verticalLabelMargin: 2,
				itemMarginBottom: 12,
			},
			Input: {
				colorBgContainer: baseColors.light.white,
				colorBorder: baseColors.light.divider,
				colorText: baseColors.light.text,
				colorTextPlaceholder: baseColors.light.textSecondary,
				activeBorderColor: baseColors.light.primary,
				hoverBorderColor: baseColors.light.hover,
				controlHeight: 32,
				controlHeightSM: 24,
				controlHeightLG: 40,
				borderRadius: 6,
				paddingInline: 8,
				paddingInlineSM: 6,
				paddingInlineLG: 12,
				fontSize: 14,
			},
			Select: {
				algorithm: true,
				controlHeight: 32,
				controlHeightSM: 24,
				controlHeightLG: 40,
				colorBgContainer: baseColors.light.white,
				colorBorder: baseColors.light.divider,
				colorText: baseColors.light.text,
				colorTextPlaceholder: baseColors.light.textSecondary,
				colorPrimary: baseColors.light.primary,
				colorPrimaryHover: baseColors.light.hover,
				colorBgElevated: baseColors.light.white,
				optionSelectedBg: `${baseColors.light.primary}15`,
				optionActiveBg: `${baseColors.light.primary}0D`,
				paddingXXS: 2,
				paddingXS: 6,
				padding: 8,
				paddingLG: 12,
				fontSize: 14,
				borderRadius: 6,
			},
		},
	},
	dark: {
		token: {
			colorPrimary: baseColors.dark.primary,
			colorBgContainer: baseColors.dark.background,
			colorBgElevated: baseColors.dark.background,
			colorBgLayout: baseColors.dark.background,
			colorText: baseColors.dark.text,
			colorTextSecondary: baseColors.dark.textSecondary,
			colorBorder: baseColors.dark.divider,
			borderRadius: 6,
			controlHeight: 32,
			controlHeightSM: 24,
			controlHeightLG: 40,
			fontSize: 14,
			fontSizeSM: 12,
			fontSizeLG: 16,
		},
		components: {
			Form: {
				labelFontSize: 14,
				verticalLabelPadding: 2,
				verticalLabelMargin: 2,
				itemMarginBottom: 12,
			},
			Input: {
				colorBgContainer: baseColors.dark.background,
				colorBorder: baseColors.dark.divider,
				colorText: baseColors.dark.text,
				colorTextPlaceholder: baseColors.dark.textSecondary,
				activeBorderColor: baseColors.dark.primary,
				hoverBorderColor: baseColors.dark.hover,
				controlHeight: 32,
				controlHeightSM: 24,
				controlHeightLG: 40,
				borderRadius: 6,
				paddingInline: 8,
				paddingInlineSM: 6,
				paddingInlineLG: 12,
				fontSize: 14,
			},
			Select: {
				algorithm: true,
				controlHeight: 32,
				controlHeightSM: 24,
				controlHeightLG: 40,
				colorBgContainer: baseColors.dark.background,
				colorBorder: baseColors.dark.divider,
				colorText: baseColors.dark.text,
				colorTextPlaceholder: baseColors.dark.textSecondary,
				colorPrimary: baseColors.dark.primary,
				colorPrimaryHover: baseColors.dark.hover,
				colorBgElevated: baseColors.dark.background,
				optionSelectedBg: `${baseColors.dark.primary}15`,
				optionActiveBg: `${baseColors.dark.primary}0D`,
				paddingXXS: 2,
				paddingXS: 6,
				padding: 8,
				paddingLG: 12,
				fontSize: 14,
				borderRadius: 6,
			},
		},
	},
};

// Tema tanımlamaları
export const lightTheme: Theme = {
	colors: {
		black: '#000000',
		grey900: '#212121',
		grey800: '#424242',
		grey700: '#616161',
		primary: '#262626', // Ana marka rengi
		primaryDark: '#171717', // Aktif durumlar için
		primaryLight: '#404040', // Hover durumları için
		secondary: '#737373', // İkincil aksiyonlar
		grey600: '#757575',
		success: '#10B981', // Başarı durumu
		successDark: '#16A34A',
		error: '#EF4444', // Hata durumu
		errorDark: '#DC2626',
		grey500: '#9E9E9E',
		grey400: '#BDBDBD',
		grey300: '#E0E0E0',
		grey200: '#EEEEEE',
		grey100: '#F5F5F5',
		white: '#FFFFFF',
		text: '#262626', // Ana metin
		textSecondary: '#525252', // İkincil metin
		background: '#f5f5f5', // Ana arka plan
		sidebarBackground: '#262626',
		warning: '#F59E0B', // Uyarı durumu
		accent: '#6366F1', // Vurgu rengi
		hover: '#2CC2A5', // Hover durumu için renk
		activeBackground: '#262626', // Aktif menü öğesi için arka plan
		hoverBackground: '#f5f5f5', // Hover durumu için arka plan
		hoverBackgroundLight: '#fafafa', // Açık hover arka planı
		divider: '#d4d4d4', // Ayraçlar için
		buttonPrimary: '#262626',
		buttonSecondary: '#737373',
		buttonSuccess: '#28A745',
		buttonError: '#DC3545',
		buttonWarning: '#FFC107',
		disabled: '#f5f5f5',
	},
	fontSizes: {
		small: '0.75rem', // 12px
		medium: '0.875rem', // 14px
		large: '1rem', // 16px
		extraLarge: '1.25rem', // 20px
		xxLarge: '1.5rem', // 24px
	},
	responsiveFontSizes: {
		small: 'calc(0.75rem + 0.1vw)',
		medium: 'calc(0.875rem + 0.1vw)',
		large: 'calc(1rem + 0.1vw)',
		extraLarge: 'calc(1.25rem + 0.15vw)',
		xxLarge: 'calc(1.5rem + 0.2vw)',
	},
	button: {
		colors: {
			primary: '#4F46E5', // Updated to match the new design
			secondary: '#6C757D',
			success: '#28A745',
			error: '#DC3545',
			warning: '#FFC107',
		},
	},
	fonts: {
		primary: '"IBM Plex Sans", sans-serif', // Primary font family
		secondary: '"IBM Plex Sans", sans-serif', // Secondary font family
		weights: {
			thin: 100,
			extraLight: 200,
			light: 300,
			regular: 400,
			medium: 500,
			semiBold: 600,
			bold: 700,
		},
	},
	spacings: {
		none: '0px',
		small: '4px',
		medium: '8px',
		large: '16px',
		extraLarge: '24px',
	},
	breakpoints: {
		mobileS: '320px',
		mobileM: '375px',
		mobileL: '425px',
		tablet: '768px',
		laptop: '1024px',
		laptopL: '1440px',
		desktop: '2560px',
	},
	// Add layout property
	layout: {
		maxWidth: '1200px',
		containerPadding: {
			mobile: '16px',
			tablet: '24px',
			desktop: '32px',
		},
	},
	shadows: {
		small: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
		medium: '0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)',
		large: '0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.10)',
	},
	login: {
		colors: loginThemeColors,
		buttons: {
			primary: {
				background: loginThemeColors.primary,
				color: loginThemeColors.text,
				hoverBackground: loginThemeColors.hover,
				disabledOpacity: 0.7,
				gradient: `linear-gradient(135deg, ${loginThemeColors.buttonGradient.start} 0%, ${loginThemeColors.buttonGradient.end} 100%)`,
				hoverGradient: `linear-gradient(135deg, ${loginThemeColors.buttonGradient.end} 0%, ${loginThemeColors.buttonGradient.start} 100%)`,
			},
			secondary: {
				background: 'rgba(255, 255, 255, 0.1)',
				color: loginThemeColors.text,
				hoverBackground: 'rgba(255, 255, 255, 0.15)',
				disabledOpacity: 0.7,
			},
			social: {
				background: loginThemeColors.socialButton.background,
				color: loginThemeColors.socialButton.text,
				border: loginThemeColors.socialButton.border,
				hoverBackground: loginThemeColors.socialButton.hoverBackground,
				hoverBorder: loginThemeColors.socialButton.hoverBorder,
			},
		},
		forms: {
			background: loginThemeColors.background,
			borderRadius: '24px',
			padding: '2.5rem',
			border: `1px solid ${loginThemeColors.border}`,
			boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
			backdropFilter: 'blur(20px)',
		},
	},
	antd: baseAntTokens.light,
};

export const darkTheme: Theme = {
	...lightTheme,
	colors: {
		black: '#000000',
		grey900: '#212121',
		grey800: '#424242',
		grey700: '#616161',
		primary: '#f5f5f5',
		primaryDark: '#ffffff',
		primaryLight: '#e5e5e5',
		secondary: '#a3a3a3',
		grey600: '#757575',
		success: '#34D399',
		successDark: '#064E3B',
		error: '#F87171',
		errorDark: '#7F1D1D',
		grey500: '#9E9E9E',
		grey400: '#BDBDBD',
		grey300: '#E0E0E0',
		grey200: '#EEEEEE',
		grey100: '#F5F5F5',
		white: '#FFFFFF',
		text: '#f5f5f5',
		textSecondary: '#d4d4d4',
		background: '#171717',
		sidebarBackground: '#171717',
		warning: '#FBBF24',
		accent: '#818CF8',
		hover: '#95FFF1',
		activeBackground: '#333333',
		hoverBackground: '#1E1E1E',
		divider: '#525252',
		buttonPrimary: '#f5f5f5',
		buttonSecondary: '#a3a3a3',
		buttonSuccess: '#4AE54A',
		buttonError: '#FF6B6B',
		buttonWarning: '#FFD93D',
		hoverBackgroundLight: '#2d2d2d', // Koyu tema için uygun bir hover rengi
		disabled: '#2d2d2d',
	},
	shadows: {
		small: '0 1px 3px rgba(255, 255, 255, 0.12), 0 1px 2px rgba(255, 255, 255, 0.24)',
		medium: '0 3px 6px rgba(255, 255, 255, 0.15), 0 2px 4px rgba(255, 255, 255, 0.12)',
		large: '0 10px 20px rgba(255, 255, 255, 0.15), 0 3px 6px rgba(255, 255, 255, 0.10)',
	},
	login: {
		...lightTheme.login,
		colors: {
			...loginThemeColors,
			primary: '#6FFFE4',
			primaryDark: '#4ECDC4',
			primaryLight: '#95FFF1',
			secondary: '#6FFFE4',
			accent: '#95FFF1',
			hover: '#95FFF1',
			buttonGradient: {
				start: '#6FFFE4',
				end: '#4ECDC4',
			},
		},
	},
	antd: baseAntTokens.dark,
};

export interface Theme {
	colors: ThemeColors;
	fontSizes: ThemeFontSizes;
	responsiveFontSizes: ThemeResponsiveFontSizes;
	button: ThemeButton;
	fonts: ThemeFonts;
	spacings: ThemeSpacings;
	breakpoints: ThemeBreakpoints;
	layout: ThemeLayout;
	shadows: ThemeShadows;
	login: ThemeLogin;
	antd: typeof baseAntTokens.light | typeof baseAntTokens.dark;
}

export const mapThemeToAntD = (theme: Theme): ThemeConfig => {
	return theme.antd;
};
