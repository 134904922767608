import styled from 'styled-components';

export const ChannelDetailContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

export const ChannelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ChannelMain = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

export const ChannelCardContent = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

export const TabContent = styled.div`
  margin-top: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
