import { INotficationState } from './notification';
import { NotificationActions, NotificationActionTypes } from './notification.actions';

export const initialState: INotficationState[] = [];

type NotficationAction = NotificationActions;

export const notficationReducer = (state: INotficationState[] = initialState, action: NotficationAction): INotficationState[] => {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATION:
      return [...state, action.data];
    case NotificationActionTypes.REMOVE_NOTIFICATION:
      return state.slice(1);
    default:
      return state;
  }
};
