// Updated action types enumeration to include registration and reset password
export enum LoginActionTypes {
  LOGIN = '[Login] Set Login',
  SET_USER = '[Login] Set User',
  LOGIN_ERROR = '[Login] Login Error',
  REGISTER_USER = '[Login] Register User', // Added for registration
  REGISTRATION_ERROR = '[Login] Registration Error', // Added for registration errors
  RESET_PASSWORD = '[Login] Reset Password', // Added for password reset
  RESET_PASSWORD_ERROR = '[Login] Reset Password Error', // Added for password reset errors
  FORGOT_PASSWORD = '[Login] Forgot Password',
  FORGOT_PASSWORD_ERROR = '[Login] Forgot Password Error',
  RESET_LOGIN_STATE = 'RESET_LOGIN_STATE',
}

// Interfaces for all actions including new ones for registration and reset
interface ILoginAction {
  type: typeof LoginActionTypes.LOGIN;
  payload: ILoginPayload; // Updated to use the new payload type
}

interface IRegisterUserAction {
  type: typeof LoginActionTypes.REGISTER_USER;
  payload: {
    name: string;
    surname: string;
    email: string;
    password: string;
  };
}

interface ISetUserAction {
  type: typeof LoginActionTypes.SET_USER;
  payload: {
    id: string;
    email: string;
    name: string;
    surname: string;
    token: string;
  };
}

interface ILoginErrorAction {
  type: typeof LoginActionTypes.LOGIN_ERROR;
  payload: { error: string };
}

export interface IRegistrationErrorAction {
  type: LoginActionTypes.REGISTRATION_ERROR;
  payload: { error: string };
}

export interface IResetPasswordAction {
  type: LoginActionTypes.RESET_PASSWORD;
  payload: { password: string; token: string };
}

export interface IResetPasswordErrorAction {
  type: LoginActionTypes.RESET_PASSWORD_ERROR;
  payload: { error: string };
}

export interface IForgotPasswordAction {
  type: LoginActionTypes.FORGOT_PASSWORD;
  payload: { email: string };
}

export interface IForgotPasswordErrorAction {
  type: LoginActionTypes.FORGOT_PASSWORD_ERROR;
  payload: { error: string };
}

// Define a type for the login payload
interface ILoginPayload {
  email: string;
  password: string;
  rememberMe: boolean; // Include rememberMe
}

// Update the login action creator to include rememberMe
export const loginActionCreator = (email: string, password: string, rememberMe: boolean): ILoginAction => ({
  type: LoginActionTypes.LOGIN,
  payload: { email, password, rememberMe },
});

export const registerUserActionCreator = (name: string, surname: string, email: string, password: string): IRegisterUserAction => ({
  type: LoginActionTypes.REGISTER_USER,
  payload: {
    name,
    surname,
    email,
    password,
  },
});

export const setUserActionCreator = (id: string, email: string, name: string, surname: string, token: string): ISetUserAction => ({
  type: LoginActionTypes.SET_USER,
  payload: {
    id,
    email,
    name,
    surname,
    token,
  },
});

export const loginErrorActionCreator = (error: string): ILoginErrorAction => ({
  type: LoginActionTypes.LOGIN_ERROR,
  payload: { error },
});

export const registrationErrorActionCreator = (error: string): IRegistrationErrorAction => ({
  type: LoginActionTypes.REGISTRATION_ERROR,
  payload: { error },
});

export const resetPasswordActionCreator = (password: string, token: string): IResetPasswordAction => ({
  type: LoginActionTypes.RESET_PASSWORD,
  payload: { password, token },
});

export const resetPasswordErrorActionCreator = (error: string): IResetPasswordErrorAction => ({
  type: LoginActionTypes.RESET_PASSWORD_ERROR,
  payload: { error },
});

export const forgotPasswordActionCreator = (email: string): IForgotPasswordAction => ({
  type: LoginActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const forgotPasswordErrorActionCreator = (error: string): IForgotPasswordErrorAction => ({
  type: LoginActionTypes.FORGOT_PASSWORD_ERROR,
  payload: { error },
});

export const resetLoginStateActionCreator = () => ({
  type: LoginActionTypes.RESET_LOGIN_STATE as const,
});

// Union type for all login-related actions
export type LoginActions =
  | ILoginAction
  | ISetUserAction
  | ILoginErrorAction
  | IRegisterUserAction
  | IRegistrationErrorAction
  | IResetPasswordAction
  | IResetPasswordErrorAction
  | IForgotPasswordAction
  | IForgotPasswordErrorAction;
