import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import trTranslations from './locales/tr.json';
import accountSettingsEN from './pages/AccountSettings/translations/en.json';
import accountSettingsTR from './pages/AccountSettings/translations/tr.json';
import designEN from './components/Design/translations/en.json';
import designTR from './components/Design/translations/tr.json';
import loginEN from './pages/Login/translations/en.json';
import loginTR from './pages/Login/translations/tr.json';
import headerEN from './components/Header/translations/en.json';
import headerTR from './components/Header/translations/tr.json';
import dashboardEN from './pages/Dashboard/translations/en.json';
import dashboardTR from './pages/Dashboard/translations/tr.json';
import channelsEN from './pages/Channels/translations/en.json';
import channelsTR from './pages/Channels/translations/tr.json';
import productsEN from './pages/Products/translations/en.json';
import productsTR from './pages/Products/translations/tr.json';
import productDetailEN from './pages/Products/Detail/translations/en.json';
import productDetailTR from './pages/Products/Detail/translations/tr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
        accountSettings: accountSettingsEN.accountSettings,
        design: designEN,
        login: loginEN.login,
        header: headerEN,
        dashboard: dashboardEN.dashboard,
        channels: channelsEN.channels,
        products: productsEN,
        productDetail: productDetailEN.productDetail
      },
      tr: {
        translation: trTranslations,
        accountSettings: accountSettingsTR.accountSettings,
        design: designTR,
        login: loginTR.login,
        header: headerTR,
        dashboard: dashboardTR.dashboard,
        channels: channelsTR.channels,
        products: productsTR,
        productDetail: productDetailTR.productDetail
      },
    },
    fallbackLng: 'tr',
    lng: 'tr',
    interpolation: {
      escapeValue: false,
    },
    ns: ['translation', 'products', 'productDetail'],
    defaultNS: 'translation'
  }).then(() => {
    document.dispatchEvent(new Event('i18nInitialized'));
  });

export default i18n;
