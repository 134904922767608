import { createAction } from '@reduxjs/toolkit';
import { IFetchProductsRequest, IProductsResponse } from './products.d';

export enum ProductActionTypes {
	FETCH_PRODUCTS_REQUEST = '[Products] Fetch Products Request',
	FETCH_PRODUCTS_SUCCESS = '[Products] Fetch Products Success',
	FETCH_PRODUCTS_FAILURE = '[Products] Fetch Products Failure',
	UPDATE_PRODUCT_STATUS_REQUEST = '[Products] Update Product Status Request',
	UPDATE_PRODUCT_STATUS_SUCCESS = '[Products] Update Product Status Success',
	UPDATE_PRODUCT_STATUS_FAILURE = '[Products] Update Product Status Failure',
	DELETE_PRODUCT_REQUEST = '[Products] Delete Product Request',
	DELETE_PRODUCT_SUCCESS = '[Products] Delete Product Success',
	DELETE_PRODUCT_FAILURE = '[Products] Delete Product Failure',
	BULK_UPDATE_STATUS_REQUEST = '[Products] Bulk Update Status Request',
	BULK_UPDATE_STATUS_SUCCESS = '[Products] Bulk Update Status Success',
	BULK_UPDATE_STATUS_FAILURE = '[Products] Bulk Update Status Failure',
	BULK_DELETE_REQUEST = '[Products] Bulk Delete Request',
	BULK_DELETE_SUCCESS = '[Products] Bulk Delete Success',
	BULK_DELETE_FAILURE = '[Products] Bulk Delete Failure',
}

export const fetchProductsRequest = createAction<IFetchProductsRequest>(
	ProductActionTypes.FETCH_PRODUCTS_REQUEST
);

export const fetchProductsSuccess = createAction<IProductsResponse>(
	ProductActionTypes.FETCH_PRODUCTS_SUCCESS
);

export const fetchProductsFailure = createAction<string>(ProductActionTypes.FETCH_PRODUCTS_FAILURE);

export const updateProductStatusRequest = createAction<{
	id: number;
	status: 'active' | 'inactive';
}>(ProductActionTypes.UPDATE_PRODUCT_STATUS_REQUEST);

export const updateProductStatusSuccess = createAction<{
	id: number;
	status: 'active' | 'inactive';
}>(ProductActionTypes.UPDATE_PRODUCT_STATUS_SUCCESS);

export const updateProductStatusFailure = createAction<string>(
	ProductActionTypes.UPDATE_PRODUCT_STATUS_FAILURE
);

export const deleteProductRequest = createAction<number>(ProductActionTypes.DELETE_PRODUCT_REQUEST);

export const deleteProductSuccess = createAction<number>(ProductActionTypes.DELETE_PRODUCT_SUCCESS);

export const deleteProductFailure = createAction<string>(ProductActionTypes.DELETE_PRODUCT_FAILURE);

export const bulkUpdateStatusRequest = createAction<{
	ids: number[];
	status: 'active' | 'inactive';
}>(ProductActionTypes.BULK_UPDATE_STATUS_REQUEST);

export const bulkUpdateStatusSuccess = createAction<{
	ids: number[];
	status: 'active' | 'inactive';
}>(ProductActionTypes.BULK_UPDATE_STATUS_SUCCESS);

export const bulkUpdateStatusFailure = createAction<string>(
	ProductActionTypes.BULK_UPDATE_STATUS_FAILURE
);

export const bulkDeleteRequest = createAction<{
	ids: number[];
}>(ProductActionTypes.BULK_DELETE_REQUEST);

export const bulkDeleteSuccess = createAction<number[]>(ProductActionTypes.BULK_DELETE_SUCCESS);

export const bulkDeleteFailure = createAction<string>(ProductActionTypes.BULK_DELETE_FAILURE);
