import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Switch, Alert, Spin, Select } from 'antd';
import { StyledCard, SectionTitle } from '../MyProfile/myProfile.styled';
import { H4, Button } from '../../../../components/Design/design.styled';
import { GenericInputLazy } from '../../../../components/GenericInput/genericInput.lazy';
import { getTenant, updateUserData } from '../../accountSettings.actions';
import { IGlobalState } from '../../../../redux/reducers';
import { IAccountSettingsState } from '../../accountSettings.reducer';
import { Select as StyledSelect } from '../../../../components/Design/design.styled'; // Use the styled Select component

const MyCompany: React.FC = () => {
  const { t } = useTranslation('accountSettings');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const accountSettings = useSelector<IGlobalState, IAccountSettingsState>(state => state.ACCOUNT_SETTINGS);
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getTenant());
  }, [dispatch]);

  useEffect(() => {
    if (accountSettings.company) {
      form.setFieldsValue({
        name: accountSettings.company.name,
        domain: accountSettings.company.domain,
        description: accountSettings.company.description,
        kepAddress: accountSettings.company.kepAddress,
        taxOffice: accountSettings.company.taxOffice,
        companyType: accountSettings.company.companyType,
        companyTradeName: accountSettings.company.companyTradeName,
        mersisNumber: accountSettings.company.mersisNumber,
        commercialRegistryNumber: accountSettings.company.commercialRegistryNumber,
        invoiceType: accountSettings.company.invoiceType,
        taxOrIdentityNumber: accountSettings.company.taxOrIdentityNumber,
        isActive: accountSettings.company.isActive,
      });
    }
  }, [accountSettings.company, form]);

  useEffect(() => {
    if (accountSettings.error) {
      setFormError(accountSettings.error);
    } else {
      setFormError(null);
    }
  }, [accountSettings.error]);

  const handleSubmit = (values: any) => {
    dispatch(updateUserData(values));
  };

  if (accountSettings.loading) {
    return <Spin size="large" />;
  }

  return (
    <StyledCard>
      <SectionTitle>
        <H4>{t('myCompany')}</H4>
      </SectionTitle>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {formError && (
          <Form.Item>
            <Alert
              message={t('error')}
              description={formError}
              type="error"
              closable
              onClose={() => setFormError(null)}
            />
          </Form.Item>
        )}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="name" label={t('companyName')} rules={[{ required: true, message: t('requiredField') }]}>
              <GenericInputLazy />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="companyTradeName" label={t('companyTradeName')} rules={[{ required: true, message: t('requiredField') }]}>
              <GenericInputLazy />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={t('companyDescription')} rules={[{ required: true, message: t('requiredField') }]}>
              <GenericInputLazy type="textarea" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="kepAddress" label={t('kepAddress')} rules={[{ required: true, type: 'email', message: t('invalidEmail') }]}>
              <GenericInputLazy />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="taxOffice" label={t('taxOffice')} rules={[{ required: true, message: t('requiredField') }]}>
              <StyledSelect>
                <StyledSelect.Option value="office1">{t('office1')}</StyledSelect.Option>
                <StyledSelect.Option value="office2">{t('office2')}</StyledSelect.Option>
              </StyledSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="companyType" label={t('companyType')} rules={[{ required: true, message: t('requiredField') }]}>
              <StyledSelect>
                <StyledSelect.Option value="individual">{t('individual')}</StyledSelect.Option>
                <StyledSelect.Option value="corporate">{t('corporate')}</StyledSelect.Option>
              </StyledSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="mersisNumber" label={t('mersisNumber')} rules={[
              { required: true, message: t('requiredField') },
              { validator: (_, value) => 
                  value && /^\d{16}$/.test(value) 
                    ? Promise.resolve() 
                    : Promise.reject(new Error(t('invalidMersisNumber')))
              }
            ]}>
              <GenericInputLazy type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="commercialRegistryNumber" label={t('commercialRegistryNumber')} rules={[
              { required: true, message: t('requiredField') },
              { validator: (_, value) => 
                  value && /^\d{16}$/.test(value) 
                    ? Promise.resolve() 
                    : Promise.reject(new Error(t('invalidCommercialRegistryNumber')))
              }
            ]}>
              <GenericInputLazy type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="invoiceType" label={t('invoiceType')} rules={[{ required: true, message: t('requiredField') }]}>
              <StyledSelect>
                <StyledSelect.Option value="eInvoice">{t('eInvoice')}</StyledSelect.Option>
                <StyledSelect.Option value="eArchive">{t('eArchive')}</StyledSelect.Option>
              </StyledSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="taxOrIdentityNumber" 
              label={t('taxOrIdentityNumber')} 
              rules={[
                { required: true, message: t('requiredField') },
                { validator: (_, value) => {
                    const companyType = form.getFieldValue('companyType');
                    if (companyType === 'individual' && /^\d{11}$/.test(value)) {
                      return Promise.resolve();
                    } else if (companyType !== 'individual' && /^\d{10}$/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('invalidTaxOrIdentityNumber')));
                  }
                }
              ]}
            >
              <GenericInputLazy type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="isActive" label={t('isActive')} valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button 
            $buttonstyle="primary" 
            htmlType="submit" 
            $loading={accountSettings.loading}
            disabled={!!formError}
          >
            {accountSettings.company ? t('updateCompany') : t('createCompany')}
          </Button>
        </Form.Item>
      </Form>
    </StyledCard>
  );
};

export default MyCompany;
