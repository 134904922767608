import styled from 'styled-components';

export const ProductsContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.large};
  background-color: ${({ theme }) => theme.colors.background};
  min-width: 0;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.large};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.medium};

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  align-items: center;
  flex-wrap: wrap;

  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-btn:not(.ant-btn-primary) {
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.divider};

    &:hover {
      border-color: ${({ theme }) => theme.colors.hover};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    
    .ant-input-affix-wrapper,
    .ant-select,
    .ant-btn {
      flex: 1;
      min-width: 150px;
    }
  }
`;

export const TableWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.small};
  overflow-x: auto;
  
  .ant-table-wrapper {
    min-width: 0;
  }

  .ant-table {
    background: ${({ theme }) => theme.colors.white};

    .ant-table-thead > tr > th {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.text};
      border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .ant-table-tbody > tr > td {
      background: ${({ theme }) => theme.colors.white};
      border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .ant-table-tbody > tr:hover > td {
      background: ${({ theme }) => theme.colors.hoverBackground} !important;
    }

    .ant-table-expanded-row > td {
      background: ${({ theme }) => theme.colors.background};
    }
  }

  .ant-pagination {
    background: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.spacings.medium};
  }
`;

export const ExpandedContent = styled.div`
  padding: ${({ theme }) => theme.spacings.medium};
  background: ${({ theme }) => theme.colors.background};

  .channel-card {
    background: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.spacings.medium};
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${({ theme }) => theme.shadows.small};
    border: 1px solid ${({ theme }) => theme.colors.divider};

    &:hover {
      border-color: ${({ theme }) => theme.colors.hover};
    }
  }
`; 