import styled from 'styled-components';
import { Card, List } from 'antd';

export const ProductDetailContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.large};
  background-color: ${({ theme }) => theme.colors.background};
  min-width: 0;

  .ant-card {
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.divider};
    box-shadow: ${({ theme }) => theme.shadows.small};
    margin-bottom: ${({ theme }) => theme.spacings.medium};
  }

  .ant-form-item-label > label {
    color: ${({ theme }) => theme.colors.text};
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selector {
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.divider};

    &:hover {
      border-color: ${({ theme }) => theme.colors.hover};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacings.medium};
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.large};

  h1 {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const FormSection = styled.div`
  .ant-form-item {
    margin-bottom: ${({ theme }) => theme.spacings.medium};
  }
`;

export const ChannelsGrid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.medium};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

export const ChannelCard = styled.div`
  padding: ${({ theme }) => theme.spacings.medium};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.divider};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.small};

  &:hover {
    border-color: ${({ theme }) => theme.colors.hover};
  }
`;

export const ActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacings.large};
  padding: ${({ theme }) => theme.spacings.medium};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.small};
`;

export const TooltipContent = styled.div`
  max-width: 300px;

  h4 {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: ${({ theme }) => theme.spacings.small};
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: ${({ theme }) => theme.spacings.small};
  }

  ul {
    margin: 0;
    padding-left: ${({ theme }) => theme.spacings.medium};

    li {
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: ${({ theme }) => theme.spacings.small};
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const VariantOptionsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

export const VariantOptionRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  padding-bottom: ${({ theme }) => theme.spacings.medium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const VariantListWrapper = styled.div`
  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      vertical-align: middle;
    }
  }
`;

export const FilterDropdown = styled.div`
  padding: ${({ theme }) => theme.spacings.small};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const VariantSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};

  .ant-table-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  @media (max-width: 768px) {
    .ant-table-content {
      overflow-x: auto;
    }
  }
`;

export const VariantOptionsContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.medium};

  .option-row {
    margin-bottom: ${({ theme }) => theme.spacings.small};

    &:last-child {
      margin-bottom: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;