import React, { useState, useMemo } from 'react';
import { Upload, Modal, Button, Space, Tag, Input, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import type { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import { IVariantImage } from '../productDetail.d';

interface Props {
  images: IVariantImage[];
  onChange: (images: IVariantImage[]) => void;
  disabled?: boolean;
  maxCount?: number;
  onImageUpload: (file: File) => Promise<string>;
}

export const VariantImageManager: React.FC<Props> = ({
  images,
  onChange,
  disabled = false,
  maxCount = 5,
  onImageUpload
}) => {
  const { t } = useTranslation('productDetail');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [editingImage, setEditingImage] = useState<IVariantImage | null>(null);
  const [newTag, setNewTag] = useState('');
  const [uploading, setUploading] = useState(false);

  const fileList: UploadFile[] = useMemo(() => 
    images.map(img => ({
      uid: img.id,
      name: img.url.split('/').pop() || '',
      status: 'done' as UploadFileStatus,
      url: img.url,
      thumbUrl: img.url,
      type: 'image/jpeg',
      size: 0,
    })), [images]
  );

  const handlePreview = (file: UploadFile) => {
    setPreviewImage(file.url || '');
    setPreviewVisible(true);
  };

  const handleChange = async ({ fileList }: { fileList: UploadFile[] }) => {
    try {
      const newFiles = fileList.filter(file => file.originFileObj);
      
      if (newFiles.length === 0) return;

      setUploading(true);

      // Tüm dosyaları paralel olarak işle
      const uploadPromises = newFiles.map(async (file) => {
        const base64 = await getBase64(file.originFileObj as File);
        return {
          id: `variant-${Date.now()}-${Math.random()}`,
          url: base64,
          isDefault: images.length === 0 && newFiles.indexOf(file) === 0,
          order: images.length + newFiles.indexOf(file),
          tags: []
        };
      });

      // Tüm dönüşümleri bekle
      const newImages = await Promise.all(uploadPromises);
      
      // Mevcut görsellere yeni görselleri ekle
      onChange([...images, ...newImages]);
    } catch (error) {
      message.error(t('variants.images.uploadError'));
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = (file: UploadFile) => {
    const newImages = images.filter(img => img.url !== file.url);
    if (newImages.length > 0 && images.find(img => img.url === file.url)?.isDefault) {
      newImages[0].isDefault = true;
    }
    onChange(newImages);
    return true;
  };

  const handleSetDefault = (image: IVariantImage) => {
    const newImages = images.map(img => ({
      ...img,
      isDefault: img.url === image.url
    }));
    onChange(newImages);
  };

  const handleAddTag = (image: IVariantImage) => {
    if (!newTag.trim()) return;
    
    const newImages = images.map(img => {
      if (img.url === image.url) {
        return {
          ...img,
          tags: [...(img.tags || []), newTag.trim()]
        };
      }
      return img;
    });
    
    onChange(newImages);
    setNewTag('');
    setEditingImage(null);
  };

  const handleRemoveTag = (image: IVariantImage, tagToRemove: string) => {
    const newImages = images.map(img => {
      if (img.url === image.url) {
        return {
          ...img,
          tags: (img.tags || []).filter(tag => tag !== tagToRemove)
        };
      }
      return img;
    });
    
    onChange(newImages);
  };

  return (
    <div className="variant-image-manager">
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        disabled={disabled || uploading}
        multiple={true}
        maxCount={maxCount}
        accept="image/*"
        onRemove={handleRemove}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: !disabled,
          showDownloadIcon: false,
        }}
        beforeUpload={(file, fileList) => {
          const totalFiles = images.length + fileList.length;
          if (totalFiles > maxCount) {
            message.error(t('variants.images.maxCountError', { count: maxCount }));
            return Upload.LIST_IGNORE;
          }

          const isImage = file.type.startsWith('image/');
          if (!isImage) {
            message.error(t('variants.images.invalidType'));
            return Upload.LIST_IGNORE;
          }

          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            message.error(t('variants.images.fileTooLarge'));
            return Upload.LIST_IGNORE;
          }

          return true;
        }}
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            if (file instanceof File) {
              getBase64(file).then((base64) => {
                onSuccess?.(base64);
              });
            }
          }, 0);
        }}
      >
        {fileList.length >= maxCount ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>
              {uploading ? t('variants.images.uploading') : t('variants.images.upload')}
            </div>
          </div>
        )}
      </Upload>

      <Modal
        open={previewVisible}
        title={t('variants.images.preview')}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img 
          alt="preview" 
          style={{ width: '100%' }} 
          src={previewImage}
        />
      </Modal>

      <div style={{ marginTop: 16 }}>
        {images.map((image) => (
          <div key={image.url} style={{ marginBottom: 8 }}>
            <Space>
              {image.isDefault && (
                <Tag color="blue">{t('variants.images.default')}</Tag>
              )}
              {!image.isDefault && (
                <Button
                  type="link"
                  size="small"
                  onClick={() => handleSetDefault(image)}
                >
                  {t('variants.images.setAsDefault')}
                </Button>
              )}
              <Button
                type="link"
                size="small"
                icon={<EditOutlined />}
                onClick={() => setEditingImage(image)}
              >
                {t('variants.images.editTags')}
              </Button>
            </Space>
            {image.tags?.map(tag => (
              <Tag
                key={tag}
                closable
                onClose={() => handleRemoveTag(image, tag)}
              >
                {tag}
              </Tag>
            ))}
            {editingImage?.url === image.url && (
              <Space style={{ marginTop: 8 }}>
                <Input
                  size="small"
                  value={newTag}
                  onChange={e => setNewTag(e.target.value)}
                  onPressEnter={() => handleAddTag(image)}
                  placeholder={t('variants.images.tagPlaceholder')}
                />
                <Button
                  size="small"
                  type="primary"
                  onClick={() => handleAddTag(image)}
                >
                  {t('variants.images.addTag')}
                </Button>
              </Space>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string);
      } else {
        reject(new Error('Failed to read file'));
      }
    };
    reader.onerror = (error) => reject(error);
  });
};

const styles = {
  variantImageManager: {
    '.ant-upload-list-picture-card': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px'
    },
    '.ant-upload-list-picture-card-container': {
      width: '104px',
      height: '104px',
      margin: '0'
    },
    '.ant-upload.ant-upload-select-picture-card': {
      width: '104px',
      height: '104px',
      margin: '0'
    }
  }
}; 