import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate, useParams, useBeforeUnload } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, InputNumber, Switch, Button, Space, Card, Divider, message, Row, Col, List, Upload, Spin, Tooltip, Modal, Table, Tag, Dropdown, Result, Empty } from 'antd';
import { FormikErrors, FormikTouched } from 'formik';
import { 
  ArrowLeftOutlined, 
  PlusOutlined, 
  MinusCircleOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  SettingOutlined,
  SearchOutlined,
  FilterOutlined,
  DeleteOutlined,
  ExportOutlined,
  ImportOutlined,
  CopyOutlined,
  MoreOutlined
} from '@ant-design/icons';
import { useFormik } from 'formik';
import { IGlobalState } from '../../../redux/reducers';
import { fetchProductDetailRequest, saveProductRequest, uploadImageRequest, deleteImageRequest } from './productDetail.action';
import { productDetailSchema } from './productDetail.validations';
import { 
  IProductForm, 
  IProductDetailState, 
  IChannel,
  IMetadata,
  IAttributeValue,
  IVariant,
  IVariantOption,
  IVariantImage,
  initialProductForm
} from './productDetail.d';
import * as S from './productDetail.styled';
import { UploadFile as AntUploadFile, UploadProps } from 'antd/es/upload/interface';
import type { ColumnsType } from 'antd/es/table';
import * as XLSX from 'xlsx';
import { ProductImageManager } from './components/ProductImageManager';
import { ProductDetailService } from './productDetail.service';
import { VariantImageManager } from './components/VariantImageManager';
import { VariantHelpCard } from './components/VariantHelpCard';

const { Option } = Select;

type CustomUploadFile = AntUploadFile & {
  originFileObj?: File;
};

type FormikMetadataErrors = FormikErrors<IMetadata>;
type FormikMetadataTouched = FormikTouched<IMetadata>;
type FormikChannelErrors = FormikErrors<IChannel>;
type FormikChannelTouched = FormikTouched<IChannel>;

// Formik error tipleri için yardımcı tipler
type FormikVariantErrors = {
  [K in keyof IVariant]?: string;
};

type FormikVariantsErrors = {
  variants?: (FormikVariantErrors | string)[];
};

// SKU oluşturma yardımcı fonksiyonu
const generateSKU = (baseProductName: string, options: IVariantOption[]): string => {
  const optionParts = options
    .map(opt => `${opt.name.substring(0, 2).toUpperCase()}-${opt.value.substring(0, 2).toUpperCase()}`)
    .join('-');
  
  return `${baseProductName.substring(0, 3).toUpperCase()}-${optionParts}`;
};

// Mock varyant oluşturma fonksiyonu
const createMockVariant = (options: { [key: string]: string[] }, formikValues: IProductForm): IVariant[] => {
  const mockVariants: IVariant[] = [];
  const optionNames = Object.keys(options);

  // Her seçenek kombinasyonu için bir varyant oluştur
  const generateCombinations = (currentOptions: IVariantOption[] = [], currentIndex: number = 0) => {
    if (currentIndex === optionNames.length) {
      // Kombinasyon tamamlandı, varyant oluştur
      mockVariants.push({
        id: `${Date.now()}-${Math.random()}`,
        sku: generateSKU(formikValues.name, currentOptions),
        price: formikValues.price,
        stock: Math.floor(formikValues.stock / 2),
        isActive: true,
        options: [...currentOptions],
        images: []
      });
      return;
    }

    const currentOptionName = optionNames[currentIndex];
    const currentOptionValues = options[currentOptionName];

    currentOptionValues.forEach(value => {
      const newOption: IVariantOption = {
        id: `${Date.now()}-${Math.random()}`,
        name: currentOptionName,
        value: value
      };
      generateCombinations([...currentOptions, newOption], currentIndex + 1);
    });
  };

  generateCombinations();
  return mockVariants;
};

// Varyant listesi için filtre ve sıralama tipleri
type SortOrder = 'ascend' | 'descend' | null;

interface VariantFilters {
  sku?: string;
  price?: [number | null, number | null];
  stock?: [number | null, number | null];
  isActive?: boolean;
  options?: { [key: string]: string[] };
}

// Sıralama fonksiyonunu güncelle
const sortVariants = (a: IVariant, b: IVariant, key: keyof IVariant, order: SortOrder) => {
  const aValue = a[key];
  const bValue = b[key];

  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return order === 'ascend' 
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  }

  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return order === 'ascend' 
      ? aValue - bValue
      : bValue - aValue;
  }

  if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
    return order === 'ascend'
      ? (aValue === bValue ? 0 : aValue ? 1 : -1)
      : (aValue === bValue ? 0 : aValue ? -1 : 1);
  }

  return 0;
};

type SetSelectedKeysFunction = (selectedKeys: (string | number | null)[]) => void;

// Fiyat filtresi için handler'lar
const handleMinPriceChange = (
  value: number | null,
  setSelectedKeys: SetSelectedKeysFunction,
  selectedKeys: (number | null)[]
) => {
  setSelectedKeys([value, selectedKeys[1]]);
};

const handleMaxPriceChange = (
  value: number | null,
  setSelectedKeys: SetSelectedKeysFunction,
  selectedKeys: (number | null)[]
) => {
  setSelectedKeys([selectedKeys[0], value]);
};

// Stok filtresi için handler'lar
const handleMinStockChange = (
  value: number | null,
  setSelectedKeys: SetSelectedKeysFunction,
  selectedKeys: (number | null)[]
) => {
  setSelectedKeys([value, selectedKeys[1]]);
};

const handleMaxStockChange = (
  value: number | null,
  setSelectedKeys: SetSelectedKeysFunction,
  selectedKeys: (number | null)[]
) => {
  setSelectedKeys([selectedKeys[0], value]);
};

// Varyant kopyalama için yardımcı fonksiyon
const duplicateVariant = (variant: IVariant): IVariant => {
  return {
    ...variant,
    sku: `${variant.sku}-COPY`,
    images: [] // Görselleri kopyalamıyoruz
  };
};

// Excel export için yardımcı fonksiyon
const exportToExcel = (variants: IVariant[]) => {
  const data = variants.map(variant => ({
    SKU: variant.sku,
    Price: variant.price,
    Stock: variant.stock,
    Status: variant.isActive ? 'Active' : 'Inactive',
    Options: variant.options.map(opt => `${opt.name}: ${opt.value}`).join(', ')
  }));

  // Excel dosyası oluşturma ve indirme işlemi
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Variants');
  XLSX.writeFile(workbook, 'variants.xlsx');
};

// Excel import için yardımcı fonksiyon
const importFromExcel = async (file: File, setFieldValue: (field: string, value: any) => void) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target?.result as ArrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    // Excel verilerini varyantlara dönüştr
    const importedVariants = jsonData.map((row: any) => {
      const options = row.Options.split(', ').map((opt: string) => {
        const [name, value] = opt.split(': ');
        return { id: '', name, value };
      });

      return {
        sku: row.SKU,
        price: Number(row.Price),
        stock: Number(row.Stock),
        isActive: row.Status === 'Active',
        options,
        images: []
      };
    });

    setFieldValue('variants', importedVariants);
  };
  reader.readAsArrayBuffer(file);
};

interface Channel {
  id: string;
  name: string;
}

export const ProductDetailComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation('productDetail');
  
  const [isEditing, setIsEditing] = useState(!id || id === 'new');
  const isCreateMode = !id || id === 'new';
  const [isFormDirty, setIsFormDirty] = useState(false);

  const categories = [
    { id: 'electronics', name: t('categories.electronics') },
    { id: 'home', name: t('categories.home') },
    { id: 'fashion', name: t('categories.fashion') },
    { id: 'beauty', name: t('categories.beauty') },
    { id: 'sports', name: t('categories.sports') }
  ];

  // Tüm state'leri tek bir selector'da toplayalım
  const { 
    product, 
    loadingStates, 
    error, 
    metadata, 
    attributeValues 
  } = useSelector<IGlobalState, IProductDetailState>(
    (state) => state.productDetail
  );

  // Loading state'lerini destructure edelim
  const { 
    main: loading,
    saving,
    uploadingImages,
    deletingImage 
  } = loadingStates;

  const formik = useFormik({
    initialValues: isCreateMode ? initialProductForm : (product || initialProductForm),
    validationSchema: productDetailSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        dispatch(saveProductRequest({ 
          ...values, 
          id: id && id !== 'new' ? Number(id) : undefined 
        }));

        // Sadece isEditing state'ini güncelleyelim
        setIsEditing(false);
      } catch (error) {
        console.error('Form submit error:', error);
      }
    }
  });

  useEffect(() => {
    // Sadece düzenleme modunda ve id varsa detayları getir
    if (id && id !== 'new') {
      dispatch(fetchProductDetailRequest(Number(id)));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (formik.dirty && !isFormDirty) {
      setIsFormDirty(true);
    }
  }, [formik.dirty, isFormDirty]);

  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (isFormDirty) {
          event.preventDefault();
          return t('prompts.unsavedChanges.navigation');
        }
      },
      [isFormDirty, t]
    )
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = t('prompts.unsavedChanges.navigation');
        return t('prompts.unsavedChanges.navigation');
      }
    };

    const handlePopState = (event: PopStateEvent) => {
      if (isFormDirty) {
        event.preventDefault();
        Modal.confirm({
          title: t('prompts.unsavedChanges.title'),
          content: t('prompts.unsavedChanges.content'),
          okText: t('prompts.unsavedChanges.ok'),
          cancelText: t('prompts.unsavedChanges.cancel'),
          onOk: () => {
            setIsFormDirty(false);
            window.removeEventListener('popstate', handlePopState);
            window.history.back();
          }
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isFormDirty, t]);

  const handleNavigate = useCallback((to: string) => {
    if (isFormDirty) {
      Modal.confirm({
        title: t('prompts.unsavedChanges.title'),
        content: t('prompts.unsavedChanges.content'),
        okText: t('prompts.unsavedChanges.ok'),
        cancelText: t('prompts.unsavedChanges.cancel'),
        onOk: () => {
          setIsFormDirty(false);
          navigate(to);
        }
      });
    } else {
      navigate(to);
    }
  }, [isFormDirty, navigate, t]);

  const handleBack = useCallback(() => {
    if (isEditing && !isCreateMode) {
      if (isFormDirty) {
        Modal.confirm({
          title: t('prompts.unsavedChanges.title'),
          content: t('prompts.unsavedChanges.content'),
          okText: t('prompts.unsavedChanges.ok'),
          cancelText: t('prompts.unsavedChanges.cancel'),
          onOk: () => {
            setIsFormDirty(false);
            setIsEditing(false);
            formik.resetForm();
          }
        });
      } else {
        setIsEditing(false);
        formik.resetForm();
      }
    } else {
      handleNavigate('/products');
    }
  }, [isEditing, isCreateMode, formik, isFormDirty, t, handleNavigate]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleSave = async () => {
    try {
      await formik.submitForm();
      setIsFormDirty(false);
    } catch (error) {
      message.error(t('messages.error'));
    }
  };

  const handleCancel = useCallback(() => {
    if (isCreateMode) {
      handleNavigate('/products');
    } else {
      setIsEditing(false);
      formik.resetForm();
    }
  }, [isCreateMode, handleNavigate, formik]);

  const handleAddChannel = useCallback(() => {
    const newChannel: IChannel = {
      id: '',
      active: true,
      price: 0,
      stock: 0,
      priceRule: '',
      stockRule: '',
    };
    formik.setFieldValue('channels', [...formik.values.channels, newChannel]);
  }, [formik]);

  const handleImageUpload = useCallback(({ file, onSuccess, onError }: any) => {
    const isValidFileType = ['image/jpeg', 'image/png', 'image/webp'].includes(file.type);
    if (!isValidFileType) {
      message.error(t('validation.invalidImageType'));
      onError(new Error('Invalid file type'));
      return;
    }

    const isValidFileSize = file.size / 1024 / 1024 < 5;
    if (!isValidFileSize) {
      message.error(t('validation.imageTooLarge'));
      onError(new Error('File too large'));
      return;
    }

    dispatch(uploadImageRequest(file));
    onSuccess();
  }, [dispatch, t]);

  const handleImageRemove = useCallback((file: CustomUploadFile) => {
    if (file.url) {
      dispatch(deleteImageRequest(file.url));
      return true;
    }
    return false;
  }, [dispatch]);

  const getChannelError = (index: number, field: keyof IChannel) => {
    const channelErrors = formik.errors.channels?.[index] as FormikChannelErrors;
    const channelTouched = formik.touched.channels?.[index] as FormikChannelTouched;
    
    if (channelErrors?.[field] && channelTouched?.[field]) {
      return {
        hasError: true,
        errorMessage: channelErrors[field] as string
      };
    }

    return {
      hasError: false,
      errorMessage: ''
    };
  };

  const uploadProps: UploadProps = {
    listType: "picture-card",
    multiple: true,
    customRequest: handleImageUpload,
    onRemove: handleImageRemove,
    fileList: formik.values.metadata?.images.map(img => ({
      uid: img,
      name: img,
      status: 'done',
      url: img,
    } as CustomUploadFile)),
    disabled: !isEditing || uploadingImages,
    accept: "image/jpeg,image/png,image/webp",
    showUploadList: {
      showRemoveIcon: !deletingImage,
    }
  };

  const renderTooltipContent = (tooltipKey: 'priceRule' | 'stockRule') => {
    const tooltipData = {
      priceRule: {
        examples: [
          "basePrice * 1.2 (Baz fiyatın %20 fazlası)",
          "basePrice + 50 (Baz fiyat + 50 TL)",
          "basePrice * 0.9 (Baz fiyatın %10 eksiği)"
        ]
      },
      stockRule: {
        examples: [
          "baseStock * 0.8 (Ana stokun %80'i)",
          "baseStock - 10 (Ana stok - 10 adet)",
          "Math.min(baseStock, 100) (Ana stok veya 100, hangisi küçükse)"
        ]
      }
    };

    return (
      <S.TooltipContent>
        <h4>{t(`channels.tooltips.${tooltipKey}.title`)}</h4>
        <p>{t(`channels.tooltips.${tooltipKey}.description`)}</p>
        <ul>
          {tooltipData[tooltipKey].examples.map((example: string, index: number) => (
            <li key={index}>{example}</li>
          ))}
        </ul>
      </S.TooltipContent>
    );
  };

  // Varyant filtreleri için state
  const [variantFilters, setVariantFilters] = useState<VariantFilters>({});
  const [sortedInfo, setSortedInfo] = useState<{
    columnKey?: string;
    order?: SortOrder;
  }>({});

  // Filtrelenmiş varyantları hesapla
  const filteredVariants = useMemo(() => {
    let result = [...formik.values.variants];

    // SKU filtresi
    if (variantFilters.sku) {
      result = result.filter(variant => 
        variant.sku.toLowerCase().includes(variantFilters.sku!.toLowerCase())
      );
    }

    // Fiyat filtresi
    if (variantFilters.price) {
      const [minPrice, maxPrice] = variantFilters.price;
      result = result.filter(variant => 
        (!minPrice || variant.price >= minPrice) && 
        (!maxPrice || variant.price <= maxPrice)
      );
    }

    // Stok filtresi
    if (variantFilters.stock) {
      const [minStock, maxStock] = variantFilters.stock;
      result = result.filter(variant => 
        (!minStock || variant.stock >= minStock) && 
        (!maxStock || variant.stock <= maxStock)
      );
    }

    // Durum filtresi
    if (variantFilters.isActive !== undefined) {
      result = result.filter(variant => 
        variant.isActive === variantFilters.isActive
      );
    }

    // Seçenek filtresi
    if (variantFilters.options) {
      Object.entries(variantFilters.options).forEach(([optionName, values]) => {
        if (values.length > 0) {
          result = result.filter(variant => 
            variant.options.some(opt => 
              opt.name === optionName && values.includes(opt.value)
            )
          );
        }
      });
    }

    // Sıralama
    if (sortedInfo.columnKey && sortedInfo.order) {
      const key = sortedInfo.columnKey as keyof IVariant;
      result.sort((a, b) => sortVariants(a, b, key, sortedInfo.order!));
    }

    return result;
  }, [formik.values.variants, variantFilters, sortedInfo]);

  // Varyant kolonlarını güncelle
  const variantColumns = React.useMemo<ColumnsType<IVariant>>(() => [
    {
      title: t('variants.list.sku'),
      dataIndex: 'sku',
      key: 'sku',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'sku' ? sortedInfo.order : undefined,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t('variants.filters.skuPlaceholder')}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              confirm();
              setVariantFilters(prev => ({
                ...prev,
                sku: selectedKeys[0] as string
              }));
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                setVariantFilters(prev => ({
                  ...prev,
                  sku: selectedKeys[0] as string
                }));
              }}
              size="small"
              style={{ width: 90 }}
            >
              {t('variants.filters.search')}
            </Button>
            <Button
              onClick={() => {
                clearFilters?.();
                setVariantFilters(prev => ({
                  ...prev,
                  sku: undefined
                }));
              }}
              size="small"
              style={{ width: 90 }}
            >
              {t('variants.filters.reset')}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (text: string, record: IVariant, index: number) => {
        const error = (formik.errors as FormikVariantsErrors)?.variants?.[index];
        const hasError = typeof error === 'object' ? error?.sku : undefined;
        
        return (
          <Form.Item
            style={{ margin: 0 }}
            validateStatus={hasError ? 'error' : ''}
            help={hasError && t(hasError)}
          >
            <Input
              value={text}
              onChange={(e) => formik.setFieldValue(`variants.${index}.sku`, e.target.value)}
              disabled={!isEditing}
              placeholder="SKU-001"
            />
          </Form.Item>
        );
      }
    },
    {
      title: t('variants.list.price'),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'price' ? sortedInfo.order : undefined,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Space direction="vertical">
            <InputNumber
              placeholder={t('variants.filters.minPrice')}
              value={selectedKeys[0] as number}
              onChange={(value) => handleMinPriceChange(
                value,
                setSelectedKeys as SetSelectedKeysFunction,
                selectedKeys as (number | null)[]
              )}
              style={{ width: 188 }}
            />
            <InputNumber
              placeholder={t('variants.filters.maxPrice')}
              value={selectedKeys[1] as number}
              onChange={(value) => handleMaxPriceChange(
                value,
                setSelectedKeys as SetSelectedKeysFunction,
                selectedKeys as (number | null)[]
              )}
              style={{ width: 188 }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                  setVariantFilters(prev => ({
                    ...prev,
                    price: selectedKeys as [number | null, number | null]
                  }));
                }}
                size="small"
              >
                {t('variants.filters.apply')}
              </Button>
              <Button
                onClick={() => {
                  clearFilters?.();
                  setVariantFilters(prev => ({
                    ...prev,
                    price: undefined
                  }));
                }}
                size="small"
              >
                {t('variants.filters.reset')}
              </Button>
            </Space>
          </Space>
        </div>
      ),
      filterIcon: filtered => (
        <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (text: number, record: IVariant, index: number) => {
        const error = (formik.errors as FormikVariantsErrors)?.variants?.[index];
        const hasError = typeof error === 'object' ? error?.price : undefined;
        
        return (
          <Form.Item
            style={{ margin: 0 }}
            validateStatus={hasError ? 'error' : ''}
            help={hasError && t(hasError)}
          >
            <InputNumber
              value={text}
              onChange={(value) => formik.setFieldValue(`variants.${index}.price`, value)}
              disabled={!isEditing}
              min={0}
              precision={2}
              style={{ width: '100%' }}
            />
          </Form.Item>
        );
      }
    },
    {
      title: t('variants.list.stock'),
      dataIndex: 'stock',
      key: 'stock',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'stock' ? sortedInfo.order : undefined,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Space direction="vertical">
            <InputNumber
              placeholder={t('variants.filters.minStock')}
              value={selectedKeys[0] as number}
              onChange={(value) => handleMinStockChange(
                value,
                setSelectedKeys as SetSelectedKeysFunction,
                selectedKeys as (number | null)[]
              )}
              style={{ width: 188 }}
            />
            <InputNumber
              placeholder={t('variants.filters.maxStock')}
              value={selectedKeys[1] as number}
              onChange={(value) => handleMaxStockChange(
                value,
                setSelectedKeys as SetSelectedKeysFunction,
                selectedKeys as (number | null)[]
              )}
              style={{ width: 188 }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                  setVariantFilters(prev => ({
                    ...prev,
                    stock: selectedKeys as [number | null, number | null]
                  }));
                }}
                size="small"
              >
                {t('variants.filters.apply')}
              </Button>
              <Button
                onClick={() => {
                  clearFilters?.();
                  setVariantFilters(prev => ({
                    ...prev,
                    stock: undefined
                  }));
                }}
                size="small"
              >
                {t('variants.filters.reset')}
              </Button>
            </Space>
          </Space>
        </div>
      ),
      filterIcon: filtered => (
        <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (text: number, record: IVariant, index: number) => {
        const error = (formik.errors as FormikVariantsErrors)?.variants?.[index];
        const hasError = typeof error === 'object' ? error?.stock : undefined;
        
        return (
          <Form.Item
            style={{ margin: 0 }}
            validateStatus={hasError ? 'error' : ''}
            help={hasError && t(hasError)}
          >
            <InputNumber
              value={text}
              onChange={(value) => formik.setFieldValue(`variants.${index}.stock`, value)}
              disabled={!isEditing}
              min={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
        );
      }
    },
    {
      title: t('variants.list.status'),
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'isActive' ? sortedInfo.order : undefined,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select
            mode="multiple"
            value={selectedKeys as string[]}
            onChange={(value) => setSelectedKeys(value)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            <Option value="true">{t('variants.filters.active')}</Option>
            <Option value="false">{t('variants.filters.inactive')}</Option>
          </Select>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                setVariantFilters(prev => ({
                  ...prev,
                  isActive: selectedKeys.includes('true')
                }));
              }}
              size="small"
            >
              {t('variants.filters.apply')}
            </Button>
            <Button
              onClick={() => {
                clearFilters?.();
                setVariantFilters(prev => ({
                  ...prev,
                  isActive: undefined
                }));
              }}
              size="small"
            >
              {t('variants.filters.reset')}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => (
        <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (checked: boolean, record: IVariant, index: number) => (
        <Switch
          checked={checked}
          onChange={(value) => formik.setFieldValue(`variants.${index}.isActive`, value)}
          disabled={!isEditing}
        />
      )
    },
    {
      title: t('variants.list.images'),
      dataIndex: 'images',
      key: 'images',
      render: (images: IVariantImage[], record: IVariant, index: number) => (
        <VariantImageManager
          images={images}
          onChange={(newImages) => {
            const updatedVariants = [...formik.values.variants];
            updatedVariants[index] = {
              ...updatedVariants[index],
              images: newImages
            };
            formik.setFieldValue('variants', updatedVariants);
          }}
          disabled={!isEditing}
          maxCount={5}
          onImageUpload={async (file) => {
            try {
              const url = await ProductDetailService.uploadImage(file);
              return url;
            } catch (error) {
              message.error(t('variants.images.uploadError'));
              throw error;
            }
          }}
        />
      )
    },
    {
      title: t('variants.list.actions'),
      key: 'actions',
      render: (_, record: IVariant, index: number) => (
        <Space>
          <Button
            type="text"
            icon={<CopyOutlined />}
            onClick={() => {
              const newVariant = duplicateVariant(record);
              const variants = [...formik.values.variants];
              variants.splice(index + 1, 0, newVariant);
              formik.setFieldValue('variants', variants);
            }}
            disabled={!isEditing}
          />
        </Space>
      )
    }
  ], [t, sortedInfo, formik, isEditing, handleImageUpload]);

  // Varyant seçeneklerini göstermek için kolon
  const optionsColumn = React.useMemo<ColumnsType<IVariant>[0]>(() => ({
    title: t('variants.options.title'),
    key: 'options',
    render: (_, record: IVariant) => (
      <Space direction="vertical">
        {record.options.map((option, idx) => (
          <Tag key={idx}>{`${option.name}: ${option.value}`}</Tag>
        ))}
      </Space>
    )
  }), [t]);

  // Varyant fiyatlarını toplu güncelleme
  const handleBulkPriceUpdate = useCallback((operation: 'increase' | 'decrease', value: number) => {
    Modal.confirm({
      title: t(`variants.actions.${operation}PriceTitle`),
      content: (
        <div>
          <p>{t('variants.actions.priceUpdateDescription')}</p>
          <InputNumber
            placeholder={t('variants.actions.percentagePlaceholder')}
            min={0}
            max={100}
            onChange={(value) => {
              if (value) {
                const updatedVariants = formik.values.variants.map(variant => ({
                  ...variant,
                  price: operation === 'increase' 
                    ? variant.price * (1 + value / 100)
                    : variant.price * (1 - value / 100)
                }));
                formik.setFieldValue('variants', updatedVariants);
              }
            }}
            style={{ width: '100%' }}
          />
        </div>
      ),
      okText: t('variants.actions.apply'),
      cancelText: t('variants.actions.cancel')
    });
  }, [formik, t]);

  // Varyant stoklarını toplu güncelleme
  const handleBulkStockUpdate = useCallback((operation: 'increase' | 'decrease', value: number) => {
    Modal.confirm({
      title: t(`variants.actions.${operation}StockTitle`),
      content: (
        <div>
          <p>{t('variants.actions.stockUpdateDescription')}</p>
          <InputNumber
            placeholder={t('variants.actions.quantityPlaceholder')}
            min={1}
            onChange={(value) => {
              if (value) {
                const updatedVariants = formik.values.variants.map(variant => ({
                  ...variant,
                  stock: operation === 'increase'
                    ? variant.stock + value
                    : Math.max(0, variant.stock - value)
                }));
                formik.setFieldValue('variants', updatedVariants);
              }
            }}
            style={{ width: '100%' }}
          />
        </div>
      ),
      okText: t('variants.actions.apply'),
      cancelText: t('variants.actions.cancel')
    });
  }, [formik, t]);

  // Excel export için yardımcı fonksiyon
  const handleExportToExcel = useCallback(() => {
    try {
      const data = formik.values.variants.map(variant => ({
        SKU: variant.sku,
        Price: variant.price,
        Stock: variant.stock,
        Status: variant.isActive ? 'Active' : 'Inactive',
        Options: variant.options.map(opt => `${opt.name}: ${opt.value}`).join(', ')
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Variants');
      XLSX.writeFile(workbook, 'variants.xlsx');

      message.success(t('variants.actions.exportSuccess'));
    } catch (error) {
      message.error(t('variants.actions.exportError'));
    }
  }, [formik.values.variants, t]);

  // Excel import için yardımcı fonksiyon
  const handleImportFromExcel = useCallback((file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const importedVariants = jsonData.map((row: any) => {
          const options = row.Options.split(', ').map((opt: string) => {
            const [name, value] = opt.split(': ');
            return { id: '', name, value };
          });

          return {
            sku: row.SKU,
            price: Number(row.Price),
            stock: Number(row.Stock),
            isActive: row.Status === 'Active',
            options,
            images: []
          };
        });

        formik.setFieldValue('variants', importedVariants);
        message.success(t('variants.actions.importSuccess'));
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      message.error(t('variants.actions.importError'));
    }
  }, [formik, t]);

  // Varyant listesi için seçili satırları tutacak state
  const [selectedVariantRows, setSelectedVariantRows] = useState<React.Key[]>([]);

  // Toplu silme işlemi için handler
  const handleBulkDelete = useCallback(() => {
    Modal.confirm({
      title: t('variants.actions.deleteTitle'),
      content: t('variants.actions.deleteConfirm'),
      okText: t('variants.actions.deleteOk'),
      cancelText: t('variants.actions.deleteCancel'),
      onOk: () => {
        const updatedVariants = formik.values.variants.filter((_, index) => 
          !selectedVariantRows.includes(index.toString())
        );
        formik.setFieldValue('variants', updatedVariants);
        setSelectedVariantRows([]);
      }
    });
  }, [formik, selectedVariantRows, t]);

  // Varyant listesi kartının extra bölümünü güncelle
  const variantListExtra = (
    <Space>
      <Button
        type="dashed"
        onClick={() => {
          const newVariants = createMockVariant(formik.values.variantOptions, formik.values);
          
          // Mevcut kombinasyonları kontrol et
          const existingCombinations = new Set(
            formik.values.variants.map(variant => 
              JSON.stringify(
                variant.options
                  .map(opt => ({ name: opt.name, value: opt.value }))
                  .sort((a, b) => a.name.localeCompare(b.name))
              )
            )
          );

          // Sadece yeni kombinasyonları ekle
          const uniqueNewVariants = newVariants.filter(variant => {
            const combinationKey = JSON.stringify(
              variant.options
                .map(opt => ({ name: opt.name, value: opt.value }))
                .sort((a, b) => a.name.localeCompare(b.name))
            );
            return !existingCombinations.has(combinationKey);
          });

          if (uniqueNewVariants.length === 0) {
            message.info(t('variants.messages.noNewVariants'));
            return;
          }

          formik.setFieldValue('variants', [...formik.values.variants, ...uniqueNewVariants]);
        }}
        icon={<PlusOutlined />}
        disabled={Object.keys(formik.values.variantOptions).length === 0}
      >
        {t('variants.list.add')}
      </Button>
      <Dropdown
        menu={{
          items: [
            {
              key: 'price-increase',
              label: t('variants.actions.increasePrice'),
              onClick: () => handleBulkPriceUpdate('increase', 0)
            },
            {
              key: 'price-decrease',
              label: t('variants.actions.decreasePrice'),
              onClick: () => handleBulkPriceUpdate('decrease', 0)
            },
            {
              key: 'stock-increase',
              label: t('variants.actions.increaseStock'),
              onClick: () => handleBulkStockUpdate('increase', 0)
            },
            {
              key: 'stock-decrease',
              label: t('variants.actions.decreaseStock'),
              onClick: () => handleBulkStockUpdate('decrease', 0)
            },
            {
              key: 'export',
              label: t('variants.actions.export'),
              icon: <ExportOutlined />,
              onClick: handleExportToExcel
            },
            {
              key: 'import',
              label: t('variants.actions.import'),
              icon: <ImportOutlined />,
              onClick: () => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = '.xlsx,.xls';
                input.onchange = (e) => {
                  const file = (e.target as HTMLInputElement).files?.[0];
                  if (file) {
                    handleImportFromExcel(file);
                  }
                };
                input.click();
              }
            }
          ]
        }}
      >
        <Button icon={<MoreOutlined />}>{t('variants.actions.more')}</Button>
      </Dropdown>
      {selectedVariantRows.length > 0 && (
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={handleBulkDelete}
        >
          {t('variants.actions.deleteSelected')}
        </Button>
      )}
    </Space>
  );

  // Channels state'i ekleyelim
  const [availableChannels, setAvailableChannels] = useState<Channel[]>([]);
  const [loadingChannels, setLoadingChannels] = useState(false);

  // Channels'ı fetch edelim
  const fetchChannels = useCallback(async () => {
    try {
      setLoadingChannels(true);
      const response = await ProductDetailService.getChannels();
      setAvailableChannels(response.data.channels);
    } catch (error) {
      message.error(t('messages.channelsFetchError'));
    } finally {
      setLoadingChannels(false);
    }
  }, [t]);

  useEffect(() => {
    // Only fetch channels when editing an existing product
    if (isEditing && id && id !== 'new') {
      fetchChannels();
    }
  }, [fetchChannels, isEditing, id]);

  // Error durumunda kullanıcıya bilgi verelim
  useEffect(() => {
    if (error) {
      message.error(error);
      setIsEditing(true); // Hata durumunda editing modunda kalalım
    }
  }, [error]);

  // Loading state kontrolü - yeni ürün oluşturma modunda loading gösterme
  if (loading && !isCreateMode) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" tip={t('messages.loading')} />
      </div>
    );
  }

  // Error state kontrolü
  if (error && !isCreateMode) {
    return (
      <Result
        status="error"
        title={t('messages.error')}
        subTitle={error}
        extra={[
          <Button type="primary" key="back" onClick={() => navigate('/products')}>
            {t('navigation.back')}
          </Button>
        ]}
      />
    );
  }

  // Ürün bulunamadı kontrolü - yeni ürün oluşturma modunda gösterme
  if (!product && !isCreateMode) {
    return (
      <Result
        status="404"
        title={t('messages.productNotFound')}
        subTitle={t('messages.productNotFoundDesc')}
        extra={[
          <Button type="primary" key="back" onClick={() => navigate('/products')}>
            {t('navigation.back')}
          </Button>
        ]}
      />
    );
  }

  return (
    <S.ProductDetailContainer>
      <S.HeaderSection>
        <Space>
          <Button icon={<ArrowLeftOutlined />} onClick={handleBack}>
            {t('navigation.back')}
          </Button>
          <h1>
            {isCreateMode
              ? t('header.create')
              : isEditing
              ? t('header.edit')
              : t('header.view')}
          </h1>
        </Space>
        {!isCreateMode && !isEditing && (
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={handleEdit}
          >
            {t('actions.edit')}
          </Button>
        )}
      </S.HeaderSection>

      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Card>
          <S.FormSection>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label={t('form.images')}
                  validateStatus={
                    (formik.errors.metadata as FormikMetadataErrors)?.images && 
                    (formik.touched.metadata as FormikMetadataTouched)?.images 
                      ? 'error' 
                      : ''
                  }
                  help={
                    (formik.touched.metadata as FormikMetadataTouched)?.images && 
                    t((formik.errors.metadata as FormikMetadataErrors)?.images as string)
                  }
                >
                  <ProductImageManager
                    images={formik.values.metadata.images}
                    onChange={(newImages) => {
                      formik.setFieldValue('metadata.images', newImages);
                    }}
                    disabled={!isEditing}
                    maxCount={10}
                    onImageUpload={async (file) => {
                      try {
                        const url = await ProductDetailService.uploadImage(file);
                        return url;
                      } catch (error) {
                        message.error(t('product.images.uploadError'));
                        throw error;
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('form.name')}
                  validateStatus={formik.errors.name && formik.touched.name ? 'error' : ''}
                  help={formik.touched.name && t(formik.errors.name as string)}
                >
                  <Input
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!isEditing}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('form.category')}
                  validateStatus={formik.errors.category && formik.touched.category ? 'error' : ''}
                  help={formik.touched.category && t(formik.errors.category as string)}
                >
                  <Select
                    value={formik.values.category}
                    onChange={(value) => formik.setFieldValue('category', value)}
                    onBlur={() => formik.setFieldTouched('category', true)}
                    disabled={!isEditing}
                  >
                    {categories.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('form.description')}
                  validateStatus={formik.errors.description && formik.touched.description ? 'error' : ''}
                  help={formik.touched.description && t(formik.errors.description as string)}
                >
                  <Input.TextArea
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!isEditing}
                    rows={4}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('form.price')}
                  validateStatus={formik.errors.price && formik.touched.price ? 'error' : ''}
                  help={formik.touched.price && t(formik.errors.price as string)}
                >
                  <InputNumber
                    value={formik.values.price}
                    onChange={(value) => formik.setFieldValue('price', value)}
                    onBlur={() => formik.setFieldTouched('price', true)}
                    disabled={!isEditing}
                    min={0}
                    precision={2}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('form.stock')}
                  validateStatus={formik.errors.stock && formik.touched.stock ? 'error' : ''}
                  help={formik.touched.stock && t(formik.errors.stock as string)}
                >
                  <InputNumber
                    value={formik.values.stock}
                    onChange={(value) => formik.setFieldValue('stock', value)}
                    onBlur={() => formik.setFieldTouched('stock', true)}
                    disabled={!isEditing}
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('form.status')}
                  validateStatus={formik.errors.status && formik.touched.status ? 'error' : ''}
                  help={formik.touched.status && t(formik.errors.status as string)}
                >
                  <Select
                    value={formik.values.status}
                    onChange={(value) => formik.setFieldValue('status', value)}
                    disabled={!isEditing}
                  >
                    <Option value="active">{t('status.active')}</Option>
                    <Option value="inactive">{t('status.inactive')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </S.FormSection>
        </Card>

        {/* Channels Section */}
        <Card 
          title={t('channels.title')} 
          style={{ marginTop: 16 }}
          extra={isEditing && (
            <Button
              type="dashed"
              onClick={handleAddChannel}
              icon={<PlusOutlined />}
            >
              {t('channels.add')}
            </Button>
          )}
        >
          {loadingChannels ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Spin />
            </div>
          ) : availableChannels.length > 0 ? (
            <S.ChannelsGrid>
              {formik.values.channels.map((channel, index) => (
                <S.ChannelCard key={index}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item 
                        label={t('channels.marketplace')}
                        validateStatus={getChannelError(index, 'id').hasError ? 'error' : ''}
                        help={getChannelError(index, 'id').errorMessage ? t(getChannelError(index, 'id').errorMessage) : undefined}
                      >
                        <Select
                          value={channel.id}
                          onChange={(value) => formik.setFieldValue(`channels.${index}.id`, value)}
                          disabled={!isEditing}
                          style={{ width: '100%' }}
                        >
                          {availableChannels.map((c: Channel) => (
                            <Option key={c.id} value={c.id}>{c.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item 
                        label={t('channels.labels.price')}
                        validateStatus={getChannelError(index, 'price').hasError ? 'error' : ''}
                        help={getChannelError(index, 'price').errorMessage ? t(getChannelError(index, 'price').errorMessage) : undefined}
                      >
                        <InputNumber
                          value={channel.price}
                          onChange={(value) => formik.setFieldValue(`channels.${index}.price`, value)}
                          disabled={!isEditing}
                          min={0}
                          precision={2}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item 
                        label={t('channels.labels.stock')}
                        validateStatus={getChannelError(index, 'stock').hasError ? 'error' : ''}
                        help={getChannelError(index, 'stock').errorMessage ? t(getChannelError(index, 'stock').errorMessage) : undefined}
                      >
                        <InputNumber
                          value={channel.stock}
                          onChange={(value) => formik.setFieldValue(`channels.${index}.stock`, value)}
                          disabled={!isEditing}
                          min={0}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item 
                        label={
                          <Space>
                            {t('channels.labels.priceRule')}
                            <Tooltip 
                              title={renderTooltipContent('priceRule')} 
                              placement="topLeft"
                              overlayStyle={{ maxWidth: 400 }}
                            >
                              <InfoCircleOutlined style={{ color: '#1890ff' }} />
                            </Tooltip>
                          </Space>
                        }
                        validateStatus={getChannelError(index, 'priceRule').hasError ? 'error' : ''}
                        help={getChannelError(index, 'priceRule').errorMessage ? t(getChannelError(index, 'priceRule').errorMessage) : undefined}
                      >
                        <Input
                          value={channel.priceRule}
                          onChange={(e) => formik.setFieldValue(`channels.${index}.priceRule`, e.target.value)}
                          disabled={!isEditing}
                          placeholder="örn: basePrice * 1.2"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item 
                        label={
                          <Space>
                            {t('channels.labels.stockRule')}
                            <Tooltip 
                              title={renderTooltipContent('stockRule')} 
                              placement="topLeft"
                              overlayStyle={{ maxWidth: 400 }}
                            >
                              <InfoCircleOutlined style={{ color: '#1890ff' }} />
                            </Tooltip>
                          </Space>
                        }
                        validateStatus={getChannelError(index, 'stockRule').hasError ? 'error' : ''}
                        help={getChannelError(index, 'stockRule').errorMessage ? t(getChannelError(index, 'stockRule').errorMessage) : undefined}
                      >
                        <Input
                          value={channel.stockRule}
                          onChange={(e) => formik.setFieldValue(`channels.${index}.stockRule`, e.target.value)}
                          disabled={!isEditing}
                          placeholder="örn: baseStock * 0.8"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isEditing && (
                    <Button
                      type="text"
                      danger
                      icon={<MinusCircleOutlined />}
                      onClick={() => {
                        const newChannels = [...formik.values.channels];
                        newChannels.splice(index, 1);
                        formik.setFieldValue('channels', newChannels);
                      }}
                    >
                      {t('channels.remove')}
                    </Button>
                  )}
                </S.ChannelCard>
              ))}
            </S.ChannelsGrid>
          ) : (
            <Empty
              description={t('channels.noChannelsAvailable')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Card>

        {/* Features Section */}
        <Card 
          title={t('features.title')} 
          style={{ marginTop: 16 }}
          extra={isEditing && (
            <Button
              type="dashed"
              onClick={() => formik.setFieldValue('features', [...formik.values.features, ''])}
              icon={<PlusOutlined />}
            >
              {t('features.add')}
            </Button>
          )}
        >
          <List
            dataSource={formik.values.features}
            renderItem={(feature, index) => (
              <List.Item
                actions={isEditing ? [
                  <Button
                    key="delete"
                    type="text"
                    danger
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      const newFeatures = [...formik.values.features];
                      newFeatures.splice(index, 1);
                      formik.setFieldValue('features', newFeatures);
                    }}
                  />
                ] : []}
              >
                {isEditing ? (
                  <Form.Item
                    style={{ margin: 0, width: '100%' }}
                    validateStatus={
                      formik.errors.features?.[index] ? 'error' : ''
                    }
                    help={formik.errors.features?.[index]}
                  >
                    <Input
                      value={feature}
                      onChange={(e) => formik.setFieldValue(`features.${index}`, e.target.value)}
                      placeholder={t('features.placeholder')}
                    />
                  </Form.Item>
                ) : (
                  <span>{feature}</span>
                )}
              </List.Item>
            )}
          />
        </Card>

        {/* Variants Section */}
        <Card 
          title={t('variants.title')} 
          style={{ marginTop: 16 }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item>
                <Switch
                  checked={formik.values.hasVariants}
                  onChange={(checked) => {
                    formik.setFieldValue('hasVariants', checked);
                    if (!checked) {
                      formik.setFieldValue('variants', []);
                      formik.setFieldValue('variantOptions', {});
                    }
                  }}
                  disabled={!isEditing}
                />
                <span style={{ marginLeft: 8 }}>{t('variants.hasVariants')}</span>
              </Form.Item>
            </Col>

            {formik.values.hasVariants && (
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Card 
                    size="small"
                    title={
                      <Space>
                        {t('variants.options.title')}
                        <VariantHelpCard />
                      </Space>
                    }
                    style={{ 
                      borderRadius: '8px',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.03)'
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      {Object.entries(formik.values.variantOptions).map(([optionName, values], index) => (
                        <Col span={24} key={index}>
                          <Row gutter={16} align="middle" style={{ background: '#fafafa', padding: '12px', borderRadius: '6px' }}>
                            <Col xs={24} sm={6}>
                              <Form.Item 
                                label={t('variants.options.name')}
                                style={{ marginBottom: 0 }}
                              >
                                <Input
                                  value={optionName}
                                  onChange={(e) => {
                                    const newOptions = { ...formik.values.variantOptions };
                                    const values = newOptions[optionName];
                                    delete newOptions[optionName];
                                    newOptions[e.target.value] = values;
                                    formik.setFieldValue('variantOptions', newOptions);
                                  }}
                                  disabled={!isEditing}
                                  placeholder={t('variants.options.placeholder.name')}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={16}>
                              <Form.Item 
                                label={t('variants.options.values')}
                                style={{ marginBottom: 0 }}
                              >
                                <Select
                                  mode="tags"
                                  value={values}
                                  onChange={(newValues) => {
                                    formik.setFieldValue(`variantOptions.${optionName}`, newValues);
                                  }}
                                  disabled={!isEditing}
                                  placeholder={t('variants.options.placeholder.values')}
                                  style={{ width: '100%' }}
                                  maxTagCount="responsive"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={2} style={{ textAlign: 'right' }}>
                              {isEditing && (
                                <Button
                                  type="text"
                                  danger
                                  icon={<MinusCircleOutlined />}
                                  onClick={() => {
                                    const newOptions = { ...formik.values.variantOptions };
                                    delete newOptions[optionName];
                                    formik.setFieldValue('variantOptions', newOptions);
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      ))}

                      {isEditing && Object.keys(formik.values.variantOptions).length < 3 && (
                        <Col span={24}>
                          <Button
                            type="dashed"
                            onClick={() => {
                              const newOptions = { ...formik.values.variantOptions };
                              const newOptionName = `Seçenek ${Object.keys(newOptions).length + 1}`;
                              newOptions[newOptionName] = [];
                              formik.setFieldValue('variantOptions', newOptions);
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            {t('variants.options.addOption')}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>

                <Col span={24}>
                  <Card 
                    size="small"
                    title={t('variants.list.title')}
                    extra={isEditing && variantListExtra}
                    style={{ 
                      borderRadius: '8px',
                      boxShadow: '0 1px 2px rgba(0,0,0,0.03)'
                    }}
                  >
                    <Table
                      rowSelection={isEditing ? {
                        selectedRowKeys: selectedVariantRows,
                        onChange: (selectedRowKeys) => setSelectedVariantRows(selectedRowKeys as string[]),
                      } : undefined}
                      columns={[optionsColumn, ...variantColumns]}
                      dataSource={filteredVariants}
                      rowKey={(record, index) => index?.toString() || '0'}
                      pagination={{
                        total: filteredVariants.length,
                        pageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total) => t('variants.pagination.total', { total })
                      }}
                      scroll={{ x: 'max-content' }}
                      onChange={(pagination, filters, sorter) => {
                        setSortedInfo(sorter as { columnKey?: string; order?: SortOrder });
                      }}
                      size="middle"
                      bordered={false}
                    />
                  </Card>
                </Col>
              </Row>
            )}
          </Row>
        </Card>

        {isEditing && (
          <S.ActionSection>
            <Space>
              <Button onClick={handleCancel} icon={<CloseOutlined />}>
                {t('actions.cancel')}
              </Button>
              <Button 
                htmlType="submit" 
                type="primary"
                onClick={handleSave}
                loading={saving}
                icon={<SaveOutlined />}
              >
                {t('actions.save')}
              </Button>
            </Space>
          </S.ActionSection>
        )}
      </Form>
    </S.ProductDetailContainer>
  );
};

export const ProductDetail = ProductDetailComponent;