import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Design/design.styled';
import { FormContainer as LoginFormContainer } from '../UserLogin/login.styled';

export const RegisterContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0.5rem;
  }
`;

export const RegisterFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    max-width: 100%;
  }
`;

export const RegisterForm = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  padding: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding: 1.5rem;
    border-radius: 16px;
  }
`;

// FormContainer'ı extend ediyoruz
export const FormContainer = styled(LoginFormContainer)`
  // Register'a özel stil override'ları buraya eklenebilir
`;

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
`;

export const StyledCheckbox = styled.input`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;

interface RegisterButtonProps {
  $buttonColor: string;
  'aria-label': string;
}

export const RegisterButton = styled(motion.button)<RegisterButtonProps>`
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.login.buttons.primary.color};
  background: ${({ theme, $buttonColor }) => $buttonColor || theme.login.buttons.primary.gradient};
  position: relative;
  overflow: hidden;
  
  &:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: ${({ theme }) => theme.login.buttons.primary.hoverGradient};
  }

  &:disabled {
    opacity: ${({ theme }) => theme.login.buttons.primary.disabledOpacity};
    cursor: not-allowed;
  }
`;

export const LinkContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.fonts.weights.semiBold};
  text-decoration: none;
  transition: all 0.2s ease;
  margin-left: 0.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  font-weight: 500;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    margin: 1.5rem 0;
    font-size: 0.8rem;
  }

  &::before,
  &::after {
    content: '';
    flex: 1;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 1rem;
  }

  span {
    padding: 0 0.5rem;
    white-space: nowrap;
    text-transform: none;
    letter-spacing: 0.2px;
  }
`;

export const WelcomeText = styled(motion.div)`
  text-align: center;
  margin-bottom: 2.5rem;
  
  h1 {
    background: linear-gradient(to right, #4F46E5, #7C3AED);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.5px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .description {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
    line-height: 1.7;
    max-width: 85%;
    margin: 0 auto;
    letter-spacing: 0.2px;
    font-weight: 400;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
      font-size: 0.9rem;
      max-width: 95%;
      line-height: 1.6;
    }
  }

  .features {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
      gap: 1rem;
      margin-top: 1.5rem;
    }
  }

  .feature {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: rgba(255, 255, 255, 0.85);
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.1px;
    transition: all 0.3s ease;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
      font-size: 0.8rem;
      gap: 0.5rem;
    }

    svg {
      color: ${({ theme }) => theme.colors.secondary};
      opacity: 1;
      transition: all 0.3s ease;

      @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      color: rgba(255, 255, 255, 1);
      
      svg {
        color: ${({ theme }) => theme.colors.accent};
        transform: scale(1.1);
      }
    }
  }
`;
