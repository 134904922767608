import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/reducers';
import { REMOVE_NOTIFICATION } from './notification.actions';
import styled, { useTheme } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader } from 'lucide-react';

const NotificationWrapper = styled(motion.div)`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
`;

const NotificationTitle = styled.h4`
  margin: 0 0 8px;
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  font-size: 1.2rem;
`;

const NotificationMessage = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const getBackgroundColor = (type: string, theme: any) => {
  switch (type) {
    case 'success':
      return theme.colors.success;
    case 'error':
      return theme.colors.error;
    case 'warning':
      return theme.colors.warning;
    default:
      return theme.colors.primary;
  }
};

export const Notification: React.FC = memo(() => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: IGlobalState) => state.notifications);
  const theme = useTheme();

  useEffect(() => {
    if (notifications.length > 0) {
      const timer = setTimeout(() => {
        dispatch(REMOVE_NOTIFICATION() as any);
      }, notifications[0].duration || 5000);

      return () => clearTimeout(timer);
    }
  }, [notifications, dispatch]);

  return (
    <AnimatePresence>
      {notifications.length > 0 && (
        <NotificationWrapper
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          style={{ backgroundColor: getBackgroundColor(notifications[0].type, theme) }}
          onClick={() => dispatch(REMOVE_NOTIFICATION() as any)}
        >
          <div className="flex items-center">
            <Loader className="animate-spin mr-2" size={20} />
            <NotificationTitle>{notifications[0].message}</NotificationTitle>
          </div>
          {notifications[0].description && (
            <NotificationMessage>{notifications[0].description}</NotificationMessage>
          )}
        </NotificationWrapper>
      )}
    </AnimatePresence>
  );
});
