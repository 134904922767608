import * as Yup from 'yup';

export const loginSchema = Yup.object({
  email: Yup.string().email('hatalı mail adresi').required('email zorunlu'),
  password: Yup.string().required('şifre zorunlu'),
});

export const registerSchema = Yup.object().shape({
  name: Yup.string().required('İsim zorunlu'),
  surname: Yup.string().required('Soyisim zorunlu'),
  email: Yup.string().email('Geçerli bir email girin').required('Email zorunlu'),
  password: Yup.string().min(8, 'Şifre en az 8 karakter olmalı').required('Şifre zorunlu'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Şifreler eşleşmiyor')
    .required('Şifre tekrarı zorunlu'),
});

export const forgotSchema = Yup.object({
  email: Yup.string().email('Geçerli bir email girin').required('Email zorunlu'),
});

export const resetSchema = Yup.object({
  password: Yup.string().min(8, 'Şifre en az 8 karakter olmalı').required('Şifre zorunlu'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Şifreler eşleşmiyor')
    .required('Şifre tekrarı zorunlu'),
});
