import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChannelManagement from './ChannelManagement';
import ChannelDetail from './ChannelDetail';

const Channels: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ChannelManagement />} />
      <Route path=":id" element={<ChannelDetail />} />
    </Routes>
  );
};

export default Channels;
