import axios, { AxiosResponse } from 'axios';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { SET_NOTIFICATION } from '../../components/Notification/notification.actions';
import {
  loginActionCreator,
  loginErrorActionCreator,
  setUserActionCreator,
  registerUserActionCreator,
  registrationErrorActionCreator,
  forgotPasswordActionCreator,
  forgotPasswordErrorActionCreator,
  resetPasswordActionCreator,
  resetPasswordErrorActionCreator,
  LoginActionTypes,
  resetLoginStateActionCreator,
} from './login.action';
import UserService from './login.services';
import { navigationService } from '../../lib/navigation-service';
import { loginStart, loginSuccess, loginFailure, resetLoginState, IUser } from './login.reducer';
import { jwtDecode } from 'jwt-decode';
import { setCookie } from '../../utils/cookieUtils';
import axiosInstance from '../../api/axios-config';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; // i18n instance'ını import ediyoruz

interface IRegisterResponse {
  status: number;
  data: {
    user_id: string;
    email: string;
    name: string;
    surname: string;
    token: string;
  };
}

interface IJwtPayload {
  user_id: string;
  name: string;
  surname: string;
  email: string;
  exp: number;
  iat: number;
  iss: string; // Add issuer property
}

// Varsayılan tenant objesi
const defaultTenant = {
  id: '',
  name: '',
  code: '',
  status: 'active',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString()
};

function* loginSaga(action: ReturnType<typeof loginActionCreator>): Generator {
  try {
    yield put(loginStart());

    const response: any = yield call(UserService.login, action.payload.email, action.payload.password);

    const { id, token, refresh_token, email, name, surname, tenant } = response.data;

    if (token && refresh_token) {
      const user: IUser = {
        id,
        email,
        name,
        surname,
        token,
        refresh_token,
        tenant,
      };

      yield put(loginSuccess(user));

      setCookie('token', token, { secure: true, sameSite: 'Strict' });
      setCookie('refresh_token', refresh_token, { secure: true, sameSite: 'Strict' });
      
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      yield put(SET_NOTIFICATION({
        message: 'Giriş başarılı',
        type: 'success',
        description: 'Hoş geldiniz!',
      }));

      navigationService.navigate('/dashboard');
    } else {
      throw new Error('Token or refresh token is missing');
    }
  } catch (error) {
    console.error('Login saga error:', error);
    let errorMessage = 'Giriş başarısız';

    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        errorMessage = 'Hatalı kullanıcı adı veya şifre';
      } else {
        errorMessage = error.response?.data?.message || error.response?.data?.error || 'Bir hata oluştu';
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    yield put(loginFailure(errorMessage));
    
    yield put(SET_NOTIFICATION({
      message: 'Giriş başarısız',
      type: 'error',
      description: errorMessage,
    }));
  }
}

function* registerUserSaga(action: IRegisterUserAction): Generator {
  try {
    yield put(loginStart());

    const response = (yield call(
      UserService.register,
      action.payload.name,
      action.payload.surname,
      action.payload.email,
      action.payload.password
    )) as IRegisterResponse;
    
    if (response.status === 201) {
      const { user_id, email, name, surname, token } = response.data;
      
      // IUser tipine uygun şekilde user objesi oluşturuyoruz
      const user: IUser = {
        id: user_id,
        email,
        name,
        surname,
        token,
        // tenant ve refresh_token opsiyonel olduğu için eklemiyoruz
      };

      yield put(setUserActionCreator(user_id, email, name, surname, token));
      yield put(loginSuccess(user));
      
      yield put(
        SET_NOTIFICATION({
          message: i18n.t('login:registrationSuccess'),
          type: 'success',
          description: i18n.t('login:redirectingToLogin'),
        })
      );

      yield delay(2000);
      navigationService.navigate('/giris');
    } 
  } catch (error) {
    let errorMessage = 'Bir hata oluştu';

    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 409) {
        errorMessage = i18n.t('login:emailAlreadyExists');
      } else {
        errorMessage = error.response.data.message || i18n.t('login:registrationError');
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    yield put(loginFailure(errorMessage));
    yield put(
      SET_NOTIFICATION({
        message: i18n.t('login:registrationError'),
        type: 'error',
        description: errorMessage,
      })
    );
  }
}

function* forgotPasswordSaga(action: ReturnType<typeof forgotPasswordActionCreator>) {
  try {
    yield call(UserService.forgotPassword, action.payload.email);

    yield put(
      SET_NOTIFICATION({
        message: 'Şifre sıfırlama mail başarılı.',
        type: 'success',
        description: 'Şifre sıfırlama talimatları e-posta adresinize gönderildi.',
      }),
    );
  } catch (error) {
    let errorMessage = 'Bir hata oluştu';

    if (axios.isAxiosError(error) && error.response) {
      if (Array.isArray(error.response.data.errors)) {
        errorMessage = error.response.data.errors.join(', ');
      } else {
        errorMessage = error.response.data.message || 'Bir hata oluştu';
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    yield put(forgotPasswordErrorActionCreator(errorMessage));
    yield put(
      SET_NOTIFICATION({
        message: 'Şifre sıfırlama başarısız.',
        type: 'error',
        description: errorMessage,
      }),
    );
  }
}

function* resetPasswordSaga(action: ReturnType<typeof resetPasswordActionCreator>): Generator<any, void, AxiosResponse> {
  try {
    yield put(loginStart());

    const response: AxiosResponse = yield call(
      UserService.resetPassword, 
      action.payload.password, 
      action.payload.token
    );

    if (response.status === 200) {
      // State'i resetle
      yield put(resetLoginState());
      
      // Başarılı bildirimini göster
      yield put(
        SET_NOTIFICATION({
          message: i18n.t('login:passwordResetSuccess'),
          type: 'success',
          description: i18n.t('login:passwordResetSuccessMessage'),
          duration: 3000 // 3 saniye göster
        })
      );

      yield put(
        SET_NOTIFICATION({
          message: i18n.t('login:redirectingToLogin'),
          type: 'info',
          description: i18n.t('login:pleaseLogin'),
          duration: 2000 // 2 saniye göster
        })
      );

      // Toplam 3 saniye bekleyip yönlendir
      yield delay(3000);
      navigationService.navigate('/giris');
    } else {
      throw new Error('Password reset failed');
    }
  } catch (error) {
    let errorMessage = i18n.t('login:genericError');

    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        errorMessage = i18n.t('login:invalidPassword');
      } else if (error.response.status === 401) {
        // "no refresh token available" mesajını daha anlaşılır hale getiriyoruz
        if (error.response.data?.message?.includes('no refresh token available')) {
          errorMessage = i18n.t('login:sessionExpired');
        } else {
          errorMessage = i18n.t('login:invalidToken');
        }
      } else if (Array.isArray(error.response.data.errors)) {
        errorMessage = error.response.data.errors.join(', ');
      } else {
        errorMessage = error.response.data.message || i18n.t('login:resetPasswordError');
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    yield put(loginFailure(errorMessage));
    
    yield put(
      SET_NOTIFICATION({
        message: i18n.t('login:resetPasswordFailed'),
        type: 'error',
        description: errorMessage,
      }),
    );

    // Oturum süresi dolmuşsa kullanıcıyı şifre sıfırlama sayfasına yönlendir
    if (errorMessage === i18n.t('login:sessionExpired')) {
      yield delay(2000);
      navigationService.navigate('/sifre-sifirlama');
    }
  }
}

function* resetLoginStateSaga(): Generator {
  yield put(resetLoginState());
}

export default function* loginModuleSaga() {
  yield takeLatest(LoginActionTypes.LOGIN, loginSaga);
  yield takeLatest(LoginActionTypes.REGISTER_USER, registerUserSaga);
  yield takeLatest(LoginActionTypes.FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(LoginActionTypes.RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(LoginActionTypes.RESET_LOGIN_STATE, resetLoginStateSaga);
}
