import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, Mail, AlertCircle, ArrowLeft, CheckCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { forgotPasswordActionCreator } from '../login.action';
import { forgotSchema } from '../login.validations';
import { useFormik } from 'formik';
import { TextInputLazy } from '../../../components/Input/TextInput/text-input.lazy';
import { ModernBackground } from '../UserLogin/ModernBackground';
import { Text } from '../../../components/Design/design.styled';
import {
  ForgotContainer,
  ForgotFormWrapper,
  ForgotForm,
  FormContainer,
  SuccessIcon,
  Title,
  Description,
  SubText,
  LinkContainer,
  StyledLink,
  ActionButton
} from './forgotPassword.styled';

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation('login');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(forgotPasswordActionCreator(values.email));
      setSubmitting(false);
      setIsSubmitted(true);
    },
  });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <ForgotContainer>
      <ModernBackground />
      <ForgotFormWrapper>
        <ForgotForm
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <AnimatePresence mode="wait">
            {!isSubmitted ? (
              <motion.div
                key="form"
                variants={container}
                initial="hidden"
                animate="show"
                exit={{ opacity: 0, y: -20 }}
              >
                <Title variants={item}>{t('forgotPasswordTitle')}</Title>
                <Description variants={item}>{t('forgotPasswordDescription')}</Description>

                <FormContainer onSubmit={formik.handleSubmit}>
                  <div className="input-wrapper">
                    <TextInputLazy
                      label={t('email')}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && formik.errors.email}
                      icon="mail"
                      aria-label={t('email')}
                    />
                  </div>

                  <motion.div variants={item}>
                    <ActionButton
                      type="submit"
                      disabled={formik.isSubmitting}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {formik.isSubmitting ? t('sending') : t('sendResetLink')}
                      {!formik.isSubmitting && <ArrowRight size={20} />}
                    </ActionButton>
                  </motion.div>
                </FormContainer>
              </motion.div>
            ) : (
              <motion.div
                key="success"
                variants={container}
                initial="hidden"
                animate="show"
                exit={{ opacity: 0, y: -20 }}
              >
                <SuccessIcon variants={item}>
                  <CheckCircle size={48} />
                </SuccessIcon>
                
                <Title variants={item}>{t('checkEmail')}</Title>
                <Description variants={item}>
                  <strong>{formik.values.email}</strong> {t('resetLinkSent')}
                </Description>
                <SubText variants={item}>{t('checkSpam')}</SubText>

                <motion.div variants={item}>
                  <ActionButton
                    onClick={() => setIsSubmitted(false)}
                    $variant="secondary"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <ArrowLeft size={20} />
                    {t('backToReset')}
                  </ActionButton>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          <LinkContainer>
            <Text size="small" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {t('rememberPassword')}{' '}
              <StyledLink to="/giris">
                {t('loginInstead')}
              </StyledLink>
            </Text>
          </LinkContainer>
        </ForgotForm>
      </ForgotFormWrapper>
    </ForgotContainer>
  );
};

export default ForgotPassword;
