import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ExampleActionTypes, ExampleActions } from './example.action';
import { ExampleService } from './example.services';
import { fetchStart, fetchSuccess, fetchError } from './example.reducer';
import { SET_NOTIFICATION } from '../../components/Notification/notification.actions';

function* exampleSaga(action: ExampleActions): Generator<any, void, AxiosResponse> {
  try {
    yield put(fetchStart());
    
    const response: AxiosResponse = yield call(ExampleService.fetchData, action.payload);
    
    yield put(fetchSuccess(response.data));
    
    yield put(SET_NOTIFICATION({
      message: 'Success',
      type: 'success',
      description: 'Operation completed successfully',
    }));
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'An error occurred';
    
    yield put(fetchError(errorMessage));
    
    yield put(SET_NOTIFICATION({
      message: 'Error',
      type: 'error',
      description: errorMessage,
    }));
  }
}

export function* exampleWatcher() {
  yield takeLatest(ExampleActionTypes.FETCH_DATA, exampleSaga);
} 