import { createAction } from '@reduxjs/toolkit';
import {
	IProductForm,
	ICreateMetadataRequest,
	IMetadataResponse,
	IAddAttributeValueRequest,
	IAttributeValueResponse,
} from './productDetail.d';

export enum ProductDetailActionTypes {
	FETCH_PRODUCT_DETAIL_REQUEST = '[Product Detail] Fetch Product Detail Request',
	FETCH_PRODUCT_DETAIL_SUCCESS = '[Product Detail] Fetch Product Detail Success',
	FETCH_PRODUCT_DETAIL_FAILURE = '[Product Detail] Fetch Product Detail Failure',
	SAVE_PRODUCT_REQUEST = '[Product Detail] Save Product Request',
	SAVE_PRODUCT_SUCCESS = '[Product Detail] Save Product Success',
	SAVE_PRODUCT_FAILURE = '[Product Detail] Save Product Failure',
	UPLOAD_IMAGE_REQUEST = '[Product Detail] Upload Image Request',
	UPLOAD_IMAGE_SUCCESS = '[Product Detail] Upload Image Success',
	UPLOAD_IMAGE_FAILURE = '[Product Detail] Upload Image Failure',
	DELETE_IMAGE_REQUEST = '[Product Detail] Delete Image Request',
	DELETE_IMAGE_SUCCESS = '[Product Detail] Delete Image Success',
	DELETE_IMAGE_FAILURE = '[Product Detail] Delete Image Failure',
	UPDATE_VARIANT_STATUS_REQUEST = '[Product Detail] Update Variant Status Request',
	UPDATE_VARIANT_STATUS_SUCCESS = '[Product Detail] Update Variant Status Success',
	UPDATE_VARIANT_STATUS_FAILURE = '[Product Detail] Update Variant Status Failure',
	UPDATE_CHANNEL_SETTINGS_REQUEST = '[Product Detail] Update Channel Settings Request',
	UPDATE_CHANNEL_SETTINGS_SUCCESS = '[Product Detail] Update Channel Settings Success',
	UPDATE_CHANNEL_SETTINGS_FAILURE = '[Product Detail] Update Channel Settings Failure',
	BULK_UPDATE_VARIANTS_REQUEST = '[Product Detail] Bulk Update Variants Request',
	BULK_UPDATE_VARIANTS_SUCCESS = '[Product Detail] Bulk Update Variants Success',
	BULK_UPDATE_VARIANTS_FAILURE = '[Product Detail] Bulk Update Variants Failure',
	CREATE_METADATA_REQUEST = '[ProductDetail] Create Metadata Request',
	CREATE_METADATA_SUCCESS = '[ProductDetail] Create Metadata Success',
	CREATE_METADATA_FAILURE = '[ProductDetail] Create Metadata Failure',
	ADD_ATTRIBUTE_VALUE_REQUEST = '[ProductDetail] Add Attribute Value Request',
	ADD_ATTRIBUTE_VALUE_SUCCESS = '[ProductDetail] Add Attribute Value Success',
	ADD_ATTRIBUTE_VALUE_FAILURE = '[ProductDetail] Add Attribute Value Failure',
	ADD_IMAGE_REQUEST = '[ProductDetail] Add Image Request',
	ADD_IMAGE_SUCCESS = '[ProductDetail] Add Image Success',
	ADD_IMAGE_FAILURE = '[ProductDetail] Add Image Failure',
	MANAGE_ACTIVE_REQUEST = '[ProductDetail] Manage Active Request',
	MANAGE_ACTIVE_SUCCESS = '[ProductDetail] Manage Active Success',
	MANAGE_ACTIVE_FAILURE = '[ProductDetail] Manage Active Failure',
}

export const fetchProductDetailRequest = createAction<number>(
	ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_REQUEST
);

export const fetchProductDetailSuccess = createAction<IProductForm>(
	ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_SUCCESS
);

export const fetchProductDetailFailure = createAction<string>(
	ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_FAILURE
);

export const saveProductRequest = createAction<IProductForm & { id?: number }>(
	ProductDetailActionTypes.SAVE_PRODUCT_REQUEST
);

export const saveProductSuccess = createAction<IProductForm>(
	ProductDetailActionTypes.SAVE_PRODUCT_SUCCESS
);

export const saveProductFailure = createAction<string>(
	ProductDetailActionTypes.SAVE_PRODUCT_FAILURE
);

export const uploadImageRequest = createAction<File>(ProductDetailActionTypes.UPLOAD_IMAGE_REQUEST);

export const uploadImageSuccess = createAction<string>(
	ProductDetailActionTypes.UPLOAD_IMAGE_SUCCESS
);

export const uploadImageFailure = createAction<string>(
	ProductDetailActionTypes.UPLOAD_IMAGE_FAILURE
);

export const deleteImageRequest = createAction<string>(
	ProductDetailActionTypes.DELETE_IMAGE_REQUEST
);

export const deleteImageSuccess = createAction<string>(
	ProductDetailActionTypes.DELETE_IMAGE_SUCCESS
);

export const deleteImageFailure = createAction<string>(
	ProductDetailActionTypes.DELETE_IMAGE_FAILURE
);

export const updateVariantStatusRequest = createAction<{
	productId: number;
	variantId: string;
	status: boolean;
}>(ProductDetailActionTypes.UPDATE_VARIANT_STATUS_REQUEST);

export const updateVariantStatusSuccess = createAction<{
	variantId: string;
	status: boolean;
}>(ProductDetailActionTypes.UPDATE_VARIANT_STATUS_SUCCESS);

export const updateVariantStatusFailure = createAction<string>(
	ProductDetailActionTypes.UPDATE_VARIANT_STATUS_FAILURE
);

export const updateChannelSettingsRequest = createAction<{
	productId: number;
	channelId: string;
	settings: {
		active: boolean;
		price: number;
		stock: number;
		priceRule: string;
		stockRule: string;
	};
}>(ProductDetailActionTypes.UPDATE_CHANNEL_SETTINGS_REQUEST);

export const updateChannelSettingsSuccess = createAction<{
	channelId: string;
	settings: {
		active: boolean;
		price: number;
		stock: number;
		priceRule: string;
		stockRule: string;
	};
}>(ProductDetailActionTypes.UPDATE_CHANNEL_SETTINGS_SUCCESS);

export const updateChannelSettingsFailure = createAction<string>(
	ProductDetailActionTypes.UPDATE_CHANNEL_SETTINGS_FAILURE
);

export const bulkUpdateVariantsRequest = createAction<{
	productId: number;
	updates: Array<{
		id: string;
		price?: number;
		stock?: number;
		isActive?: boolean;
	}>;
}>(ProductDetailActionTypes.BULK_UPDATE_VARIANTS_REQUEST);

export const bulkUpdateVariantsSuccess = createAction<
	Array<{
		id: string;
		price?: number;
		stock?: number;
		isActive?: boolean;
	}>
>(ProductDetailActionTypes.BULK_UPDATE_VARIANTS_SUCCESS);

export const bulkUpdateVariantsFailure = createAction<string>(
	ProductDetailActionTypes.BULK_UPDATE_VARIANTS_FAILURE
);

export const createMetadataRequest = createAction<ICreateMetadataRequest>(
	ProductDetailActionTypes.CREATE_METADATA_REQUEST
);

export const createMetadataSuccess = createAction<IMetadataResponse>(
	ProductDetailActionTypes.CREATE_METADATA_SUCCESS
);

export const createMetadataFailure = createAction<string>(
	ProductDetailActionTypes.CREATE_METADATA_FAILURE
);

export const addAttributeValueRequest = createAction<IAddAttributeValueRequest>(
	ProductDetailActionTypes.ADD_ATTRIBUTE_VALUE_REQUEST
);

export const addAttributeValueSuccess = createAction<IAttributeValueResponse>(
	ProductDetailActionTypes.ADD_ATTRIBUTE_VALUE_SUCCESS
);

export const addAttributeValueFailure = createAction<string>(
	ProductDetailActionTypes.ADD_ATTRIBUTE_VALUE_FAILURE
);

export const addImageRequest = createAction<{
	tenantId: string;
	metadataId: string;
	image: File;
	updatedBy: string;
}>(ProductDetailActionTypes.ADD_IMAGE_REQUEST);

export const addImageSuccess = createAction<{
	metadataId: string;
	imageUrl: string;
}>(ProductDetailActionTypes.ADD_IMAGE_SUCCESS);

export const addImageFailure = createAction<string>(ProductDetailActionTypes.ADD_IMAGE_FAILURE);

export const manageActiveRequest = createAction<{
	tenantId: string;
	metadataId: string;
	isActive: boolean;
	updatedBy: string;
}>(ProductDetailActionTypes.MANAGE_ACTIVE_REQUEST);

export const manageActiveSuccess = createAction<{
	metadataId: string;
	isActive: boolean;
}>(ProductDetailActionTypes.MANAGE_ACTIVE_SUCCESS);

export const manageActiveFailure = createAction<string>(
	ProductDetailActionTypes.MANAGE_ACTIVE_FAILURE
);
