import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const AnimatedBackgroundContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  background-color: #262626;
`;

const AnimatedCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const AnimatedBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const curves: { start: { x: number; y: number }; end: { x: number; y: number }; offset: number; speed: number }[] = [];
    const particles: { x: number; y: number; speed: number; curve: number; progress: number }[] = [];

    // Curve oluşturma
    for (let i = 0; i < 15; i++) {
      curves.push({
        start: { x: 0, y: Math.random() * canvas.height },
        end: { x: canvas.width, y: Math.random() * canvas.height },
        offset: Math.random() * Math.PI * 2,
        speed: 0.001 + Math.random() * 0.003
      });
    }

    // Particle oluşturma
    for (let i = 0; i < 100; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: 0,
        speed: 0.2 + Math.random() * 0.8,
        curve: Math.floor(Math.random() * curves.length),
        progress: Math.random(),
      });
    }

    const drawCurve = (curve: typeof curves[0], time: number) => {
      ctx.beginPath();

      const waveAmplitude = 20 + Math.sin(time * 0.001 + curve.offset) * 10;
      const controlPointY = curve.start.y < curve.end.y ?
        -waveAmplitude + Math.sin(time * curve.speed + curve.offset) * waveAmplitude :
        canvas.height + waveAmplitude + Math.sin(time * curve.speed + curve.offset) * waveAmplitude;

      ctx.moveTo(curve.start.x, curve.start.y);
      ctx.quadraticCurveTo(
        canvas.width / 2,
        controlPointY,
        curve.end.x,
        curve.end.y
      );

      ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)'; // Çizgi rengini biraz daha şeffaf yaptık
      ctx.lineWidth = 1; // Çizgi kalınlığını azalttık
      ctx.stroke();
    };

    const drawParticle = (particle: typeof particles[0], time: number) => {
      const curve = curves[particle.curve];
      const t = particle.progress;

      const x = (1 - t) * (1 - t) * curve.start.x + 2 * (1 - t) * t * (canvas.width / 2) + t * t * curve.end.x;
      const y = (1 - t) * (1 - t) * curve.start.y +
        2 * (1 - t) * t * (curve.start.y < curve.end.y ? 0 : canvas.height) +
        t * t * curve.end.y;

      const waveOffset = Math.sin(time * 0.002 + curve.offset + t * Math.PI * 2) * 10;

      ctx.beginPath();
      ctx.arc(x, y + waveOffset, 1, 0, Math.PI * 2); // Parçacık boyutunu küçülttük
      ctx.fillStyle = `rgba(255, 255, 255, ${0.3 - Math.abs(0.5 - t) * 0.2})`; // Parçacık opaklığını azalttık
      ctx.fill();

      particle.progress += particle.speed / 1000;
      if (particle.progress > 1) {
        particle.progress = 0;
        particle.curve = Math.floor(Math.random() * curves.length);
      }
    };

    const animate = (time: number) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      curves.forEach(curve => drawCurve(curve, time));
      particles.forEach(particle => drawParticle(particle, time));

      requestAnimationFrame(animate);
    };

    animate(0);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <AnimatedBackgroundContainer>
      <AnimatedCanvas ref={canvasRef} />
    </AnimatedBackgroundContainer>
  );
};

export default AnimatedBackground;
