import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Loader2 } from 'lucide-react';

export type ButtonState = 'default' | 'loading' | 'success' | 'error';

interface LoadingButtonProps {
  onClick: () => void;
  buttonState: ButtonState;
  getButtonContent: (state: ButtonState) => React.ReactNode;
  disabled?: boolean;
  'aria-label'?: string;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledButton = styled.button<{ $buttonState: ButtonState }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.white};
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => `${theme.colors.primaryDark}`} 100%
  );
  
  &:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.primaryDark} 0%,
      ${({ theme }) => theme.colors.primary} 100%
    );
  }

  &:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.primaryLight};
  }

  ${({ $buttonState, theme }) => {
    switch ($buttonState) {
      case 'success':
        return `
          background: linear-gradient(135deg, ${theme.colors.success} 0%, ${theme.colors.successDark} 100%);
          &:hover:not(:disabled) {
            background: linear-gradient(135deg, ${theme.colors.successDark} 0%, ${theme.colors.success} 100%);
          }
        `;
      case 'error':
        return `
          background: linear-gradient(135deg, ${theme.colors.error} 0%, ${theme.colors.errorDark} 100%);
          &:hover:not(:disabled) {
            background: linear-gradient(135deg, ${theme.colors.errorDark} 0%, ${theme.colors.error} 100%);
          }
        `;
      default:
        return '';
    }
  }}
`;

const LoadingIcon = styled(Loader2)`
  animation: ${spin} 1s linear infinite;
`;

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  onClick,
  buttonState,
  getButtonContent,
  disabled = false,
  'aria-label': ariaLabel,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled || buttonState === 'loading'}
      $buttonState={buttonState}
      aria-label={ariaLabel}
    >
      {buttonState === 'loading' && <LoadingIcon size={20} />}
      {getButtonContent(buttonState)}
    </StyledButton>
  );
};
