import React from 'react';
import { Button, Modal, Steps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { 
  QuestionCircleOutlined, 
  InfoCircleOutlined,
  VideoCameraOutlined,
  BulbOutlined 
} from '@ant-design/icons';

export const VariantHelpCard: React.FC = () => {
  const { t } = useTranslation('productDetail');
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <>
      <Tooltip title={t('variants.help.quickHelp')}>
        <Button 
          type="text" 
          icon={<QuestionCircleOutlined />}
          onClick={() => setIsModalVisible(true)}
        />
      </Tooltip>

      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <BulbOutlined style={{ color: '#1890ff' }} />
            {t('variants.help.title')}
          </div>
        }
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button 
            key="tutorial"
            type="primary" 
            icon={<VideoCameraOutlined />}
            onClick={() => window.open('https://example.com/tutorial', '_blank')}
          >
            {t('variants.help.watchTutorial')}
          </Button>
        ]}
        width={600}
      >
        <Steps
          direction="vertical"
          current={-1}
          items={[1, 2, 3, 4].map((step) => ({
            title: t(`variants.help.steps.${step}`),
            description: t(`variants.help.steps.${step}desc`),
            icon: <InfoCircleOutlined />
          }))}
        />
      </Modal>
    </>
  );
}; 