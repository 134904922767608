import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { GenericInputProps } from './genericInput.types';

const GenericInputComponent = React.lazy(() => import('./'));

export const GenericInputLazy: React.FC<GenericInputProps> = (props) => {
  return (
    <Suspense fallback={<Spin size="small" />}>
      <GenericInputComponent {...props} />
    </Suspense>
  );
};
