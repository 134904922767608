export enum ExampleActionTypes {
  FETCH_DATA = '[Example] Fetch Data',
  SET_DATA = '[Example] Set Data',
  SET_ERROR = '[Example] Set Error',
}

export interface IExamplePayload {
  field1: string;
  field2: string;
}

export const exampleActionCreator = (payload: IExamplePayload) => ({
  type: ExampleActionTypes.FETCH_DATA,
  payload,
});

export const setDataAction = (data: any) => ({
  type: ExampleActionTypes.SET_DATA,
  payload: data,
});

export const setErrorAction = (error: string) => ({
  type: ExampleActionTypes.SET_ERROR,
  payload: error,
});

export type ExampleActions = 
  | ReturnType<typeof exampleActionCreator>
  | ReturnType<typeof setDataAction>
  | ReturnType<typeof setErrorAction>; 