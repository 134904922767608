import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { changeLanguage } from '../redux/actions/languageActions';
import { IGlobalState } from '../redux/reducers';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const LanguageSwitcher: React.FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector((state: IGlobalState) => state.language.currentLanguage);

  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
    dispatch(changeLanguage(value));
  };

  return (
    <Select value={language} style={{ width: 120 }} onChange={handleLanguageChange}>
      <Option value="en">English</Option>
      <Option value="tr">Türkçe</Option>
    </Select>
  );
};

export default LanguageSwitcher;
