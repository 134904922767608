import { call, put, takeLatest, select } from 'redux-saga/effects';
import { ProductDetailActionTypes } from './productDetail.action';
import * as actions from './productDetail.action';
import { ProductDetailService } from './productDetail.service';
import { message } from 'antd';
import i18n from '../../../i18n';
import { navigationService } from '../../../lib/navigation-service';
import { IGlobalState } from '../../../redux/reducers';

const t = (key: string) => i18n.t(key, { ns: 'productDetail' });

// Selector to get tenantId from Redux store
const selectTenantId = (state: IGlobalState): string | undefined => state.LOGIN.user?.tenant?.ID;
const selectUserId = (state: IGlobalState): string | undefined => state.LOGIN.user?.id;

function* fetchProductDetailSaga(
	action: ReturnType<typeof actions.fetchProductDetailRequest>
): Generator<any, void, any> {
	try {
		const tenantId: string | undefined = yield select(selectTenantId);
		if (!tenantId) {
			throw new Error(t('errors.noTenant'));
		}

		console.log('Fetching product detail:', action.payload);
		const response = yield call(
			{
				context: ProductDetailService,
				fn: ProductDetailService.getProductDetail,
			},
			tenantId,
			action.payload.toString()
		);
		yield put(actions.fetchProductDetailSuccess(response.data));
	} catch (error: any) {
		console.error('Error fetching product:', error);
		yield put(
			actions.fetchProductDetailFailure(error.response?.data?.message || t('messages.error'))
		);
		message.error(t('messages.fetchError'));
	}
}

function* saveProductSaga(
	action: ReturnType<typeof actions.saveProductRequest>
): Generator<any, void, any> {
	try {
		const tenantId: string | undefined = yield select(selectTenantId);
		const userId: string | undefined = yield select(selectUserId);
		if (!tenantId || !userId) {
			throw new Error(t('errors.noTenant'));
		}

		const { id, ...productData } = action.payload;
		let response;

		// Prepare product data
		const productWithUser = {
			...productData,
			metadata: {
				...productData.metadata,
				createdBy: userId,
				updatedBy: userId,
			},
		};

		if (id) {
			response = yield call(
				{
					context: ProductDetailService,
					fn: ProductDetailService.updateProduct,
				},
				tenantId,
				id.toString(),
				productWithUser
			);
		} else {
			response = yield call(
				{
					context: ProductDetailService,
					fn: ProductDetailService.createProduct,
				},
				tenantId,
				productWithUser
			);
		}

		if (response?.data) {
			yield put(actions.saveProductSuccess(response.data));
			message.success(t(id ? 'messages.updateSuccess' : 'messages.createSuccess'));

			if (!id) {
				yield call(
					{
						context: navigationService,
						fn: navigationService.navigate,
					},
					'/products'
				);
			}
		} else {
			throw new Error(t('messages.invalidResponse'));
		}
	} catch (error: any) {
		const errorMessage = error.response?.data?.message || error.message || t('messages.error');
		yield put(actions.saveProductFailure(errorMessage));
		message.error(errorMessage);
	}
}

function* uploadImageSaga(
	action: ReturnType<typeof actions.uploadImageRequest>
): Generator<any, void, any> {
	try {
		const response = yield call(
			{
				context: ProductDetailService,
				fn: ProductDetailService.uploadImage,
			},
			action.payload
		);
		yield put(actions.uploadImageSuccess(response.data.url));
	} catch (error: any) {
		yield put(actions.uploadImageFailure(error.response?.data?.message || t('messages.error')));
		message.error(t('messages.uploadError'));
	}
}

export function* productDetailWatcher() {
	yield takeLatest(ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_REQUEST, fetchProductDetailSaga);
	yield takeLatest(ProductDetailActionTypes.SAVE_PRODUCT_REQUEST, saveProductSaga);
	yield takeLatest(ProductDetailActionTypes.UPLOAD_IMAGE_REQUEST, uploadImageSaga);
}
