import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITenant {
  ID: string;
  name: string;
  description: string;
  domain: string;
  owner_id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  is_active: boolean;
  team_ids: string[] | null;
  kep_address: string;
  tax_office: string;
  company_type: string;
  company_trade_name: string;
  mersis_number: string;
  commercial_registry_number: string;
  invoice_type: string;
  tax_or_identity_number: string;
}

export interface IUser {
  id: string;
  email: string;
  name: string;
  surname: string;
  token: string;
  refresh_token?: string; // opsiyonel
  tenant?: ITenant; // opsiyonel
}

export interface ILoginState {
  user: IUser | null;
  error: string | null;
  isFetching: boolean;
  isFetched: boolean;
}

const initialState: ILoginState = {
  user: null,
  isFetching: false,
  isFetched: false,
  error: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    loginSuccess: (state, action: PayloadAction<IUser>) => {
      state.isFetching = false;
      state.isFetched = true;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
      state.isFetched = true;
      state.error = action.payload;
    },
    resetLoginState: (state) => {
      state.isFetching = false;
      state.isFetched = false;
      state.error = null;
    },
    logout: (state) => {
      state.user = null;
      state.isFetching = false;
      state.isFetched = false;
      state.error = null;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, resetLoginState, logout } = loginSlice.actions;

export default loginSlice.reducer;
