import axiosInstance from '../../api/axios-config';
import { IExamplePayload } from './example.action';

export const ExampleService = {
  fetchData: (payload: IExamplePayload) => {
    return axiosInstance.post('/api/example', payload);
  },
  
  getData: () => {
    return axiosInstance.get('/api/example');
  },
  
  updateData: (id: string, data: Partial<IExamplePayload>) => {
    return axiosInstance.put(`/api/example/${id}`, data);
  },
  
  deleteData: (id: string) => {
    return axiosInstance.delete(`/api/example/${id}`);
  },
}; 