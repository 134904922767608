import { Input as AntInput, Button as AntButton, ButtonProps, Select as AntSelect, Checkbox as AntCheckbox } from 'antd';
import styled, { css, keyframes, Theme } from 'styled-components';
import { IColorProps, TextProps, IButtonProps } from './design.d';
import React from 'react';

interface CheckboxProps {
  $variant?: 'primary' | 'secondary' | 'accent';
  $textColor?: string;
}

export const DesignContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

export const DesignInnerContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 10px;
  }
`;

export const DesignSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

export const Colors = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.medium};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center;
  }
`;

export const Color = styled.span<IColorProps>`
  border-radius: 1rem;
  height: 120px;
  width: 90px;
  background-color: ${({ theme, color }) => theme.colors[color]};
`;

export const TypeFaceBlocks = styled.div`
  display: grid;
  padding: 8px;
  place-items: flex-start;
  border-radius: 1rem;
  height: 12rem;
  width: 8rem;
  background-color: ${({ theme, color }) => theme.colors.lightgrey};
`;

// Heading components with various levels

const baseHeadingStyles = css<TextProps>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.text)};
  margin: ${({ theme, spacing }) => (spacing ? theme.spacings[spacing] : theme.spacings.small)} 0;
  font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.fontSizes.medium)};
  font-weight: ${({ theme, weight }) => (weight ? theme.fonts.weights[weight] : theme.fonts.weights.regular)};
  line-height: ${({ lineHeight }) => lineHeight || 'normal'};
`;
export const H1 = styled.h1<TextProps>`
  ${baseHeadingStyles};
  font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.fontSizes.large)};
  font-weight: ${({ theme, weight }) => (weight ? theme.fonts.weights[weight] : theme.fonts.weights.bold)};
`;

export const H2 = styled.h2<TextProps>`
  ${baseHeadingStyles};
  font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.fontSizes.large)};
`;

export const H3 = styled.h3<TextProps>`
  ${baseHeadingStyles};
  font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.fontSizes.medium)};
`;

export const H4 = styled.h4<TextProps>`
  ${baseHeadingStyles};
  font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.fontSizes.medium)};
`;

export const H5 = styled.h5<TextProps>`
  ${baseHeadingStyles};
  font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.fontSizes.small)};
`;

export const Text = styled.p<TextProps>`
  ${baseHeadingStyles};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.text)};
`;

export const Bold = styled(Text)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const Caption = styled.span<TextProps>`
  ${baseHeadingStyles};
  font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.fontSizes.small)};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.secondary)};
`;

// Button with GPU acceleration on hover effects

const rippleEffect = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Button = styled(({ $buttonstyle, $size, $fullWidth, $loading, ...props }: IButtonProps) => <AntButton {...props} />)<IButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  position: relative;
  overflow: hidden;
  background-color: #262626; // Tüm butonlar için varsayılan arka plan rengi
  color: ${({ theme }) => theme.colors.white}; // Metin rengini beyaz yapıyoruz

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 32px;
          padding: 0 12px;
          font-size: 14px;
        `;
      case 'large':
        return css`
          height: 48px;
          padding: 0 20px;
          font-size: 18px;
        `;
      default: // medium
        return css`
          height: 40px;
          padding: 0 16px;
          font-size: 16px;
        `;
    }
  }}

  ${({ $buttonstyle, theme, $loading }) => {
    const baseColor = theme.colors.primary;
    const hoverColor = theme.colors.hover;
    const textColor = theme.colors.white;

    return css`
      background-color: ${baseColor};
      color: ${textColor};
      position: relative;
      overflow: hidden;

      &:hover:not(:disabled) {
        background-color: ${hoverColor};
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      ${$loading && css`
        color: transparent !important;
        pointer-events: none;

        &::after {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          top: 50%;
          left: 50%;
          margin-top: -8px;
          margin-left: -8px;
          border: 2px solid ${textColor};
          border-top-color: ${baseColor};
          border-radius: 50%;
          animation: ${spin} 1s linear infinite;
        }
      `}
    `;
  }}

  ${({ htmlType }) => htmlType && `type: ${htmlType};`}
`;

// Password input specifically styled
export const PasswordInput = styled(AntInput.Password)`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: ${({ theme }) => theme.spacings.small};  
  border-radius: 20px;
  height: 40px;
  border: none;
  transition: all 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(146, 38, 255, 0.2);
  }
`;

export const TestButton = styled.button`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacings.medium};
  border-radius: ${({ theme }) => theme.borderRadius};
  font-family: ${({ theme }) => theme.fonts.weights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.medium};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

// Styled Select component to match the height and border styling of other inputs
export const Select = styled(AntSelect)`
  .ant-select-selector {
    height: 40px !important; // Match the height of StyledInput
    display: flex;
    align-items: center;
    border-radius: 6px; // Match the border radius of StyledInput
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    border: 1px solid ${({ theme }) => theme.colors.divider}; // Consistent border styling
    padding: 0 ${({ theme }) => theme.spacings.small}; // Match padding of StyledInput
    transition: all 0.3s ease;

    &:focus, &:hover {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary}33`};
    }
  }
`;

export const Checkbox = styled(AntCheckbox)<CheckboxProps>`
  &&.ant-checkbox-wrapper {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  && .ant-checkbox {
    .ant-checkbox-inner {
      background-color: transparent;
      border-color: ${({ theme, $variant }) => {
        switch ($variant) {
          case 'primary':
            return theme.colors.primary;
          case 'secondary':
            return theme.colors.secondary;
          case 'accent':
            return theme.colors.accent;
          default:
            return theme.colors.secondary;
        }
      }};
    }
  }

  && .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${({ theme, $variant }) => {
        switch ($variant) {
          case 'primary':
            return theme.colors.primary;
          case 'secondary':
            return theme.colors.secondary;
          case 'accent':
            return theme.colors.accent;
          default:
            return theme.colors.secondary;
        }
      }};
      border-color: transparent;
    }

    &::after {
      border-color: transparent;
    }
  }

  && .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  && .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${({ theme, $variant }) => {
      switch ($variant) {
        case 'primary':
          return theme.colors.hover;
        case 'secondary':
          return theme.colors.accent;
        case 'accent':
          return theme.colors.hover;
        default:
          return theme.colors.accent;
      }
    }};
  }

  && + span {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 0.875rem;
    transition: color 0.3s ease;
    padding-left: 8px;
  }

  &&:hover + span {
    color: ${({ theme }) => theme.colors.text};
  }

  ${({ $textColor }) => $textColor && css`
    && + span {
      color: ${$textColor};
    }
  `}
`;
