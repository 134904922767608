import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu, Typography, Avatar } from 'antd';
import { UserOutlined, LockOutlined, TeamOutlined, BellOutlined, DollarOutlined, ExportOutlined, BankOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { fetchUserData } from './accountSettings.actions';
import { IGlobalState } from '../../redux/reducers';
import { IAccountSettingsState } from './accountSettings.reducer';
import { Outlet, useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import MyProfile from './components/MyProfile';
import MyCompany from './components/MyCompany';
import Security from './Security';

import enTranslations from './translations/en.json';
import trTranslations from './translations/tr.json';

const { Sider, Content } = Layout;
const { Title, Text } = Typography;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.background};
  border-right: none;
`;

const StyledContent = styled(Content)`
  padding: 24px;
`;

const ProfileSection = styled.div`
  padding: 24px;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.colors.divider};
`;

const StyledMenu = styled(Menu)`
  &&& {
    background: transparent;
    border-right: none;

    .ant-menu-item {
      margin: 4px 8px !important;
      padding: 8px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      height: 36px;
      line-height: 20px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.hoverBackgroundLight};
      }

      &.ant-menu-item-selected {
        background-color: ${({ theme }) => theme.colors.activeBackground};
        color: #ffffff;
      }

      .anticon {
        font-size: 15px;
        margin-right: 8px;
      }

      .ant-menu-title-content {
        flex: 1;
        text-align: left;
      }
    }
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 16px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.text};
  font-size: 16px;
`;

const StyledText = styled(Text)`
  color: ${props => props.theme.colors.secondary};
  font-size: 13px;
`;

const AccountSettings: React.FC = () => {
  const { t, i18n } = useTranslation('accountSettings');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accountSettings = useSelector<IGlobalState, IAccountSettingsState>(state => state.ACCOUNT_SETTINGS);
  const [selectedKey, setSelectedKey] = useState('my-profile');

  useEffect(() => {
    i18n.addResourceBundle('en', 'accountSettings', enTranslations.accountSettings, true, true);
    i18n.addResourceBundle('tr', 'accountSettings', trTranslations.accountSettings, true, true);
  }, [i18n]);

  useEffect(() => {
    if (!accountSettings.user) {
      dispatch(fetchUserData());
    }
  }, [dispatch, accountSettings.user]);

  useEffect(() => {
    const path = location.pathname.split('/').pop() || 'my-profile';
    setSelectedKey(path);
  }, [location]);

  const handleMenuClick = useCallback(({ key }: { key: string }) => {
    navigate(key);
  }, [navigate]);

  const menuItems = [
    { key: 'my-profile', icon: <UserOutlined />, label: t('myProfile') },
    { key: 'my-company', icon: <BankOutlined />, label: t('myCompany') },
    { key: 'security', icon: <LockOutlined />, label: t('security') },
    { key: 'teams', icon: <TeamOutlined />, label: t('teams') },
    { key: 'notifications', icon: <BellOutlined />, label: t('notifications') },
    { key: 'billing', icon: <DollarOutlined />, label: t('billing') },
    { key: 'data-export', icon: <ExportOutlined />, label: t('dataExport') },
  ];

  return (
    <StyledLayout>
      <StyledSider width={200}>
        <ProfileSection>
          <Avatar size={48} icon={<UserOutlined />} src={accountSettings.user?.avatarUrl} />
          <StyledTitle level={4}>
            {accountSettings.user ? `${accountSettings.user.name} ${accountSettings.user.surname}` : ''}
          </StyledTitle>
          <StyledText>
            {accountSettings.user?.email}
          </StyledText>
        </ProfileSection>
        <StyledMenu
          mode="inline"
          selectedKeys={[selectedKey]}
          onClick={handleMenuClick}
        >
          {menuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </StyledMenu>
      </StyledSider>
      <StyledContent>
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedKey}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <Routes>
              <Route index element={<Navigate to="my-profile" replace />} />
              <Route path="my-profile" element={<MyProfile />} />
              <Route path="my-company" element={<MyCompany />} />
              <Route path="security" element={<Security />} />
              {/* Add other routes here */}
            </Routes>
          </motion.div>
        </AnimatePresence>
      </StyledContent>
    </StyledLayout>
  );
};

export default AccountSettings;
