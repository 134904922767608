import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDashboardState {
  data: any; // Replace 'any' with your dashboard data type
  error: string | null;
  isFetching: boolean;
}

const initialState: IDashboardState = {
  data: null,
  error: null,
  isFetching: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchDashboardDataStart(state) {
      state.isFetching = true;
      state.error = null;
    },
    fetchDashboardDataSuccess(state, action: PayloadAction<any>) {
      state.data = action.payload;
      state.isFetching = false;
      state.error = null;
    },
    fetchDashboardDataFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

export const {
  fetchDashboardDataStart,
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
