import { call, put, takeLatest, select } from 'redux-saga/effects';
import { 
  AccountSettingsActionTypes, 
  UpdateUserDataAction, 
  createCompany,
  createCompanySuccess,
  createCompanyFailure,
  fetchCompanyDataSuccess,
  fetchCompanyDataFailure,
  getTenant,
  getTenantSuccess,
  getTenantFailure
} from './accountSettings.actions';
import { accountSettingsService } from './accountSettings.services';
import { IGlobalState } from '../../redux/reducers';
import { UserData, CompanyData } from './accountSettings.types';
import { SET_NOTIFICATION } from '../../components/Notification/notification.actions';
import i18n from '../../i18n';
import { snakeToCamel } from '../../utils/caseTransform';

// Kullanıcı ID'sini store'dan almak için bir selector
const selectUserId = (state: IGlobalState) => state.LOGIN.user?.id;

// Optional chaining ile tenant'ın olup olmadığını kontrol ediyoruz
const selectTenantId = (state: IGlobalState) => state.LOGIN.user?.tenant?.ID;

function* fetchUserDataSaga(): Generator<any, void, any> {
  try {
    const tenantId = yield select(selectTenantId);
    
    // Eğer tenant ID varsa kullan, yoksa null veya undefined olarak devam et
    const response = yield call(accountSettingsService.fetchUserData, tenantId);
    
    const userData: UserData = response.data;
    yield put({ type: AccountSettingsActionTypes.FETCH_USER_DATA_SUCCESS, payload: userData });
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield put({ type: AccountSettingsActionTypes.FETCH_USER_DATA_FAILURE, payload: error.message });
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:fetchError'),
      }));
    } else {
      yield put({ type: AccountSettingsActionTypes.FETCH_USER_DATA_FAILURE, payload: 'An unknown error occurred' });
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:unknownError'),
      }));
    }
  }
}

function* updateUserDataSaga(action: UpdateUserDataAction): Generator<any, void, any> {
  try {
    const userId = yield select(selectUserId);
    if (!userId) {
      throw new Error('User ID not found');
    }

    const updatedUserData: UserData = yield call(accountSettingsService.updateUserData, userId, action.payload);
    yield put({ type: AccountSettingsActionTypes.UPDATE_USER_DATA_SUCCESS, payload: updatedUserData });
    yield put(SET_NOTIFICATION({
      message: i18n.t('accountSettings:success'),
      type: 'success',
      description: i18n.t('accountSettings:updateSuccess'),
    }));
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield put({ type: AccountSettingsActionTypes.UPDATE_USER_DATA_FAILURE, payload: error.message });
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:updateError'),
      }));
    } else {
      yield put({ type: AccountSettingsActionTypes.UPDATE_USER_DATA_FAILURE, payload: 'An unknown error occurred' });
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:unknownError'),
      }));
    }
  }
}

function* fetchCompanyDataSaga(): Generator<any, void, any> {
  try {
    const companyData: CompanyData = yield call(accountSettingsService.fetchCompanyData);
    yield put(fetchCompanyDataSuccess(companyData));
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield put(fetchCompanyDataFailure(error.message));
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:fetchCompanyError'),
      }));
    } else {
      yield put(fetchCompanyDataFailure('An unknown error occurred'));
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:unknownError'),
      }));
    }
  }
}

function* createCompanySaga(action: ReturnType<typeof createCompany>): Generator<any, void, any> {
  try {
    const company: CompanyData = yield call(accountSettingsService.createCompany, action.payload);
    yield put(createCompanySuccess(company));
    yield put(SET_NOTIFICATION({
      message: i18n.t('accountSettings:companyCreatedSuccess'),
      type: 'success',
      description: i18n.t('accountSettings:companyCreatedDescription'),
    }));
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield put(createCompanyFailure(error.message));
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:companyCreationError'),
        type: 'error',
        description: error.message,
      }));
    } else {
      yield put(createCompanyFailure('An unknown error occurred'));
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:companyCreationError'),
        type: 'error',
        description: i18n.t('accountSettings:unknownError'),
      }));
    }
  }
}

function* getTenantSaga(): Generator<any, void, any> {
  try {
    const tenantId = yield select(selectTenantId);
    if (!tenantId) {
      throw new Error('Tenant ID not found');
    }

    const tenant: CompanyData = yield call(accountSettingsService.getTenant, tenantId);
    const camelCaseTenant = snakeToCamel(tenant);
    yield put(getTenantSuccess(camelCaseTenant));
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield put(getTenantFailure(error.message));
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:fetchTenantError'),
      }));
    } else {
      yield put(getTenantFailure('An unknown error occurred'));
      yield put(SET_NOTIFICATION({
        message: i18n.t('accountSettings:error'),
        type: 'error',
        description: i18n.t('accountSettings:unknownError'),
      }));
    }
  }
}

export function* accountSettingsSaga() {
  yield takeLatest(AccountSettingsActionTypes.FETCH_USER_DATA, fetchUserDataSaga);
  yield takeLatest(AccountSettingsActionTypes.UPDATE_USER_DATA, updateUserDataSaga);
  yield takeLatest(AccountSettingsActionTypes.FETCH_COMPANY_DATA, fetchCompanyDataSaga);
  yield takeLatest(AccountSettingsActionTypes.CREATE_COMPANY, createCompanySaga);
  yield takeLatest(AccountSettingsActionTypes.GET_TENANT, getTenantSaga);
}
