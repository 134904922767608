import { axiosInstance } from '../../../api/axios-config';
import { IProductForm } from './productDetail.d';

export const ProductDetailService = {
  getProductDetail: async (tenantId: string, id: string) => {
    return axiosInstance.get(`/api/v1/marketplace/${tenantId}/metadata/${id}`);
  },

  createProduct: async (tenantId: string, product: IProductForm) => {
    const createRequest = {
      channelId: product.metadata.channelId,
      familyId: product.metadata.familyId,
      type: "Product",
      origin: product.metadata.origin,
      createdBy: product.metadata.createdBy
    };

    return axiosInstance.post(`/api/v1/marketplace/${tenantId}/metadata`, createRequest);
  },

  updateProduct: async (tenantId: string, id: string, product: IProductForm) => {
    const updateRequest = {
      channelId: product.metadata.channelId,
      familyId: product.metadata.familyId,
      type: "Product",
      origin: product.metadata.origin,
      updatedBy: product.metadata.updatedBy
    };

    return axiosInstance.put(`/api/v1/marketplace/${tenantId}/metadata/${id}`, updateRequest);
  },

  uploadImage: async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axiosInstance.post('/api/v1/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.url;
  },

  getChannels: () => {
    return axiosInstance.post('/api/v1/channel/filter', {
      page: 1,
      page_size: 100
    });
  }
}; 