import { axiosInstance } from '../../api/axios-config';
import { IProductsResponse, IFetchProductsRequest } from './products.d';

interface IAttributeValue {
	attributeId: string;
	value: string;
}

interface IProductMetadata {
	channelId: string;
	familyId: string;
	type: string;
	origin: string;
	images: string[];
	attributeValues: IAttributeValue[];
	createdBy: string;
}

export const ProductsService = {
	getProducts: async (tenantId: string, params: IFetchProductsRequest) => {
		const { page, pageSize, search, category, status, sortField, sortOrder } = params;

		return axiosInstance.post<IProductsResponse>(
			`/api/v1/marketplace/${tenantId}/metadata/filter`,
			{
				page,
				page_size: pageSize,
				type: 'Product',
				search,
				category,
				status,
				sortField,
				sortOrder,
			}
		);
	},

	deleteProduct: async (tenantId: string, id: string) => {
		return axiosInstance.delete(`/api/v1/marketplace/${tenantId}/metadata/${id}`);
	},

	updateProductStatus: async (
		tenantId: string,
		id: string,
		isActive: boolean,
		updatedBy: string
	) => {
		return axiosInstance.post(`/api/v1/marketplace/${tenantId}/metadata/${id}/manage-active`, {
			isActive,
			updatedBy,
		});
	},

	bulkUpdateStatus: async (
		tenantId: string,
		ids: string[],
		isActive: boolean,
		updatedBy: string
	) => {
		const updatePromises = ids.map((id) =>
			axiosInstance.post(`/api/v1/marketplace/${tenantId}/metadata/${id}/manage-active`, {
				isActive,
				updatedBy,
			})
		);
		return Promise.all(updatePromises);
	},

	bulkDelete: async (tenantId: string, ids: string[]) => {
		const deletePromises = ids.map((id) =>
			axiosInstance.delete(`/api/v1/marketplace/${tenantId}/metadata/${id}`)
		);
		return Promise.all(deletePromises);
	},

	duplicateProduct: async (tenantId: string, id: string, createdBy: string) => {
		// 1. Get the original product
		const originalProduct = await axiosInstance.get<{ metadata: IProductMetadata }>(
			`/api/v1/marketplace/${tenantId}/metadata/${id}`
		);
		const product = originalProduct.data;

		// 2. Create new metadata
		const newMetadata = await axiosInstance.post(`/api/v1/marketplace/${tenantId}/metadata`, {
			channelId: product.metadata.channelId,
			familyId: product.metadata.familyId,
			type: 'Product',
			origin: product.metadata.origin,
			createdBy,
		});

		// 3. Copy attribute values
		const attributeValuePromises = product.metadata.attributeValues.map((attr: IAttributeValue) =>
			axiosInstance.post(`/api/v1/marketplace/${tenantId}/attribute-value`, {
				attributeId: attr.attributeId,
				metadataId: newMetadata.data.metadata.id,
				value: attr.value,
			})
		);

		await Promise.all(attributeValuePromises);

		// 4. Copy images
		if (product.metadata.images.length > 0) {
			const imagePromises = product.metadata.images.map((image: string) =>
				axiosInstance.post(
					`/api/v1/marketplace/${tenantId}/metadata/${newMetadata.data.metadata.id}/add-image`,
					{
						image,
						updatedBy: createdBy,
					}
				)
			);

			await Promise.all(imagePromises);
		}

		return newMetadata;
	},

	exportProducts: async (filters: Partial<IFetchProductsRequest>) => {
		return axiosInstance.get('/api/v1/products/export', {
			params: filters,
			responseType: 'blob',
		});
	},

	importProducts: async (file: File) => {
		const formData = new FormData();
		formData.append('file', file);

		return axiosInstance.post('/api/v1/products/import', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},

	bulkUpdateChannelStatus: async (productIds: number[], channelId: string, active: boolean) => {
		return axiosInstance.patch('/api/v1/products/channels/bulk-status', {
			productIds,
			channelId,
			active,
		});
	},

	bulkUpdateChannelPrices: async (
		productIds: number[],
		channelId: string,
		priceChange: {
			type: 'percentage' | 'fixed';
			value: number;
		}
	) => {
		return axiosInstance.patch('/api/v1/products/channels/bulk-prices', {
			productIds,
			channelId,
			priceChange,
		});
	},

	getChannels: () => {
		return axiosInstance.post('/api/v1/channel/filter', {
			page: 1,
			page_size: 100,
		});
	},
};
