import React, { useCallback } from 'react';
import { Table, Badge, Tooltip } from 'antd';
import { TableProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { EditOutlined, InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { IProduct, IChannel } from '../products.d';
import { TableWrapper } from '../products.styled';
import { ExpandedRow } from './ExpandedRow';
import { useNavigate } from 'react-router-dom';
import { ActionButtons, ChannelCell, ChannelPrice, ChannelStock, TableActionButton } from './productTable.styled';

interface ProductTableProps {
  products: IProduct[];
  loading: boolean;
  pagination: {
    current: number;
    pageSize: number;
    total: number;
  };
  selectedRowKeys: number[];
  expandedRowKeys: number[];
  onSelectChange: (selectedKeys: number[]) => void;
  onExpand: (expanded: boolean, record: IProduct) => void;
  onTableChange: TableProps<IProduct>['onChange'];
  channels: ReadonlyArray<{ readonly id: string; readonly name: string; }>;
  onEditChannel?: (channelId: string, productId: number) => void;
  onAddToChannel?: (channelId: string, productId: number) => void;
}

export const ProductTable: React.FC<ProductTableProps> = ({
  products,
  loading,
  pagination,
  selectedRowKeys,
  expandedRowKeys,
  onSelectChange,
  onExpand,
  onTableChange,
  channels,
  onEditChannel,
  onAddToChannel
}) => {
  const { t } = useTranslation('products');
  const navigate = useNavigate();

  const renderChannelCell = useCallback((channelData: IChannel | undefined, channelId: string) => {
    if (!channelData) {
      return (
        <Badge 
          status="default" 
          text={t('channel.cell.status.unlisted')}
        />
      );
    }

    return (
      <ChannelCell isActive={channelData.active}>
        <div>
          <Badge 
            status={channelData.active ? 'success' : 'error'} 
            text={channelData.active ? 
              t('channel.cell.status.active') : 
              t('channel.cell.status.inactive')} 
          />
        </div>
        <ChannelPrice>
          ₺{channelData.price.toFixed(2)}
        </ChannelPrice>
        <ChannelStock>
          ({channelData.stock})
        </ChannelStock>
      </ChannelCell>
    );
  }, [t]);

  const columns = [
    {
      title: t('table.product'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left' as const,
      width: 200,
    },
    {
      title: t('table.category'),
      dataIndex: 'category',
      key: 'category',
      width: 120,
      render: (category: string) => (
        <Badge 
          color={category === 'Elektronik' ? 'blue' : 'green'} 
          text={category} 
        />
      ),
    },
    {
      title: t('table.basePrice'),
      dataIndex: 'price',
      key: 'price',
      width: 120,
      render: (price: number) => (
        <span>₺{price.toFixed(2)}</span>
      ),
    },
    {
      title: t('table.stock'),
      dataIndex: 'stock',
      key: 'stock',
      width: 80,
      align: 'center' as const,
    },
    ...channels.map(channel => ({
      title: t(`table.channels.${channel.id}`),
      key: channel.id,
      width: 150,
      render: (_: unknown, record: IProduct) => {
        const channelData = record.channels.find((c: IChannel) => c.id === channel.id);
        return renderChannelCell(channelData, channel.id);
      },
    })),
    {
      title: t('table.actions'),
      key: 'actions',
      fixed: 'right' as const,
      width: 120,
      render: (_: unknown, record: IProduct) => (
        <ActionButtons>
          <Tooltip title={t('actions.info')} placement="top">
            <TableActionButton
              $buttonStyle="primary"
              icon={<InfoCircleOutlined />}
              onClick={() => onExpand(!expandedRowKeys.includes(record.id), record)}
              aria-label={t('actions.info')}
            />
          </Tooltip>
          <Tooltip title={t('actions.edit')} placement="top">
            <TableActionButton
              $buttonStyle="primary"
              icon={<EditOutlined />}
              onClick={() => navigate(`/products/${record.id}`)}
              aria-label={t('actions.edit')}
            />
          </Tooltip>
          <Tooltip title={t('actions.more')} placement="top">
            <TableActionButton
              $buttonStyle="primary"
              icon={<MoreOutlined />}
              aria-label={t('actions.more')}
            />
          </Tooltip>
        </ActionButtons>
      ),
    }
  ];

  const renderExpandedRow = useCallback((record: IProduct) => (
    <ExpandedRow
      record={record}
      channels={channels}
      onEditChannel={onEditChannel}
      onAddToChannel={onAddToChannel}
    />
  ), [channels, onEditChannel, onAddToChannel]);

  return (
    <TableWrapper>
      <Table<IProduct>
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedKeys) => onSelectChange(selectedKeys as number[]),
          selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE
          ]
        }}
        dataSource={products}
        columns={columns}
        loading={loading}
        rowKey="id"
        expandable={{
          expandedRowRender: renderExpandedRow,
          expandedRowKeys: expandedRowKeys,
          onExpand: onExpand,
        }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          showTotal: (total) => t('table.pagination.total', { total }),
        }}
        onChange={onTableChange}
        scroll={{ x: 'max-content' }}
      />
    </TableWrapper>
  );
}; 