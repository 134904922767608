import { notification } from 'antd';
import { t } from 'i18next';

let currentVersion: string | null = null;

export const checkForUpdates = async () => {
  try {
    const response = await fetch('/manifest.json', { cache: 'no-cache' });
    const data = await response.json();
    
    if (!currentVersion) {
      currentVersion = data.version;
      return;
    }

    if (data.version !== currentVersion) {
      notification.info({
        message: t('New version available'),
        description: t('A new version of the app is available. Please refresh to update.'),
        duration: 0,
        key: 'updateNotification',
        onClick: () => {
          window.location.reload();
        },
      });
      currentVersion = data.version;
    }
  } catch (error) {
    console.error('Failed to check for updates:', error);
  }
};
