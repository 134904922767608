import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, Badge, Switch } from 'antd';
import { RightOutlined, ShoppingCartOutlined, DollarOutlined, InboxOutlined } from '@ant-design/icons';
import { ChannelContainer, ChannelGrid, ChannelHeader, ChannelMain, ChannelCardContent, ChannelCardFooter, ChannelDescription, ChannelMetrics, MetricItem, AnimatedCard, StyledButton } from './channels.styled';
import { useTranslation } from 'react-i18next';
import { H1, Text, Bold } from '../../components/Design';
import anime from 'animejs';

const ChannelManagement: React.FC = () => {
  const { t } = useTranslation('channels');
  const [channels, setChannels] = useState([
    { id: 1, name: 'Trendyol', active: true, orders: 150, revenue: 25000, products: 500, status: t('status.synchronized') },
    { id: 2, name: 'Hepsiburada', active: true, orders: 100, revenue: 18000, products: 450, status: t('status.synchronized') },
    { id: 3, name: 'Amazon', active: false, orders: 0, revenue: 0, products: 300, status: t('status.inactive') },
    { id: 4, name: 'N11', active: true, orders: 80, revenue: 12000, products: 400, status: t('status.synchronized') },
    { id: 5, name: 'GittiGidiyor', active: true, orders: 60, revenue: 9000, products: 350, status: t('status.needsSynchronization') },
  ]);

  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Header animation
    anime({
      targets: headerRef.current,
      translateY: [-50, 0],
      opacity: [0, 1],
      duration: 600,
      easing: 'easeOutExpo'
    });

    // Card animations
    anime({
      targets: cardRefs.current,
      scale: [0.8, 1],
      opacity: [0, 1],
      translateY: [50, 0],
      delay: anime.stagger(100),
      duration: 800,
      easing: 'easeOutElastic(1, 0.5)',
      begin: () => {
        cardRefs.current.forEach(ref => {
          if (ref) ref.style.visibility = 'visible';
        });
      }
    });
  }, []);

  const toggleChannel = (id: number) => {
    setChannels(channels.map(channel => 
      channel.id === id ? {...channel, active: !channel.active, status: !channel.active ? t('status.synchronizing') : t('status.inactive')} : channel
    ));

    // Toggle animation
    anime({
      targets: cardRefs.current[id - 1],
      scale: [1, 0.95, 1],
      duration: 300,
      easing: 'easeInOutQuad'
    });
  };

  return (
    <ChannelContainer>
      <ChannelHeader ref={headerRef}>
        <H1>{t('title')}</H1>
        <ChannelDescription>
          {t('channelManagementDescription')}
        </ChannelDescription>
      </ChannelHeader>
      <ChannelMain>
        <ChannelGrid>
          {channels.map((channel, index) => (
            <AnimatedCard
              key={channel.id}
              ref={el => cardRefs.current[index] = el}
            >
              <Card 
                title={<Bold>{channel.name}</Bold>} 
                extra={<Switch checked={channel.active} onChange={() => toggleChannel(channel.id)} />}
              >
                <ChannelCardContent>
                  <Badge status={channel.active ? "success" : "default"} text={channel.status} />
                  <ChannelMetrics>
                    <MetricItem>
                      <ShoppingCartOutlined />
                      <Text><Bold>{t('orders')}</Bold> {channel.orders}</Text>
                    </MetricItem>
                    <MetricItem>
                      <DollarOutlined />
                      <Text><Bold>{t('revenue')}</Bold> ₺{channel.revenue.toLocaleString()}</Text>
                    </MetricItem>
                    <MetricItem>
                      <InboxOutlined />
                      <Text><Bold>{t('products')}</Bold> {channel.products}</Text>
                    </MetricItem>
                  </ChannelMetrics>
                </ChannelCardContent>
                <ChannelCardFooter>
                  <Link to={`/channels/${channel.id}`}>
                    <StyledButton $buttonstyle="primary" icon={<RightOutlined />}>
                      {t('viewDetails')}
                    </StyledButton>
                  </Link>
                </ChannelCardFooter>
              </Card>
            </AnimatedCard>
          ))}
        </ChannelGrid>
      </ChannelMain>
    </ChannelContainer>
  );
};

export default ChannelManagement;
