import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { ISelectInputProps } from './Select.d';

const SelectInputComponent = React.lazy(() => import('./index'));

export const SelectInputLazy: React.FC<ISelectInputProps> = (props) => {
  return (
    <Suspense fallback={<Spin size="small" />}>
      <SelectInputComponent {...props} />
    </Suspense>
  );
};

export default SelectInputLazy;
