import { INotficationState } from './notification';

export enum NotificationActionTypes {
  SET_NOTIFICATION = '[NotificationActionTypes] Set Notification',
  REMOVE_NOTIFICATION = '[NotificationActionTypes] Remove Notification',
}

export interface SetNotificationActionType {
  type: NotificationActionTypes.SET_NOTIFICATION;
  data: INotficationState;
}

export interface RemoveNotificationActionType {
  type: NotificationActionTypes.REMOVE_NOTIFICATION;
}

export const SET_NOTIFICATION: (
  data: INotficationState,
) => SetNotificationActionType = (data: INotficationState) => ({
  type: NotificationActionTypes.SET_NOTIFICATION,
  data,
});
export const REMOVE_NOTIFICATION: () => RemoveNotificationActionType = () => ({
  type: NotificationActionTypes.REMOVE_NOTIFICATION,
});

export type NotificationActions =
  | SetNotificationActionType
  | RemoveNotificationActionType;
