import React, { useState, useMemo } from 'react';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';

interface Props {
  images: string[];
  onChange: (images: string[]) => void;
  disabled?: boolean;
  maxCount?: number;
  onImageUpload: (file: File) => Promise<string>;
}

export const ProductImageManager: React.FC<Props> = ({
  images,
  onChange,
  disabled = false,
  maxCount = 10,
  onImageUpload
}) => {
  const { t } = useTranslation('productDetail');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [uploading, setUploading] = useState(false);

  const fileList: UploadFile[] = useMemo(() => 
    images.map((url, index) => ({
      uid: `${index}`,
      name: url.split('/').pop() || '',
      status: 'done' as UploadFileStatus,
      url: url,
      thumbUrl: url,
      type: 'image/jpeg',
      size: 0,
    })), [images]
  );

  const handlePreview = (file: UploadFile) => {
    setPreviewImage(file.url || '');
    setPreviewVisible(true);
  };

  const handleChange = async ({ fileList }: { fileList: UploadFile[] }) => {
    try {
      const newFiles = fileList.filter(file => file.originFileObj);
      
      if (newFiles.length === 0) return;

      setUploading(true);

      const uploadPromises = newFiles.map(async (file) => {
        const base64 = await getBase64(file.originFileObj as File);
        return base64;
      });

      const newUrls = await Promise.all(uploadPromises);
      
      onChange([...images, ...newUrls]);
    } catch (error) {
      message.error(t('product.images.uploadError'));
    } finally {
      setUploading(false);
    }
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Failed to read file'));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemove = (file: UploadFile) => {
    const newImages = images.filter(url => url !== file.url);
    onChange(newImages);
    return true;
  };

  return (
    <div className="product-image-manager">
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        disabled={disabled || uploading}
        multiple={true}
        maxCount={maxCount}
        accept="image/*"
        onRemove={handleRemove}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: !disabled,
          showDownloadIcon: false,
        }}
        beforeUpload={(file, fileList) => {
          const totalFiles = images.length + fileList.length;
          if (totalFiles > maxCount) {
            message.error(t('product.images.maxCountError', { count: maxCount }));
            return Upload.LIST_IGNORE;
          }

          const isImage = file.type.startsWith('image/');
          if (!isImage) {
            message.error(t('product.images.invalidType'));
            return Upload.LIST_IGNORE;
          }

          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            message.error(t('product.images.fileTooLarge'));
            return Upload.LIST_IGNORE;
          }

          return true;
        }}
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess?.('ok');
          }, 0);
        }}
      >
        {fileList.length >= maxCount ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>
              {uploading ? t('product.images.uploading') : t('product.images.upload')}
            </div>
          </div>
        )}
      </Upload>

      <Modal
        open={previewVisible}
        title={t('product.images.preview')}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img 
          alt="preview" 
          style={{ width: '100%' }} 
          src={previewImage}
        />
      </Modal>
    </div>
  );
}; 