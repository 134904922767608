import { NavigateFunction } from 'react-router-dom';

class NavigationService {
  private navigateFunction?: NavigateFunction;

  setNavigateFunction(navigate: NavigateFunction) {
    this.navigateFunction = navigate;
  }

  navigate(path: string, replace: boolean = false) {
    if (this.navigateFunction) {
      this.navigateFunction(path, { replace });
    } else {
      console.warn('Navigate function is not set yet.');
    }
  }
}

export const navigationService = new NavigationService();
