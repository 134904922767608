import styled from 'styled-components';
import StyledButton from '../../../components/Button/button.styled';

export const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  justify-content: flex-end;
`;

export const ChannelCell = styled.div<{ isActive?: boolean }>`
  background-color: ${({ isActive, theme }) => 
    isActive ? 'rgba(82, 196, 26, 0.1)' : 'rgba(255, 77, 79, 0.1)'};
  padding: ${({ theme }) => theme.spacings.medium};
  border-radius: ${({ theme }) => theme.borderRadius};
  text-align: center;
`;

export const ChannelPrice = styled.div`
  margin-top: ${({ theme }) => theme.spacings.small};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const ChannelStock = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-top: ${({ theme }) => theme.spacings.small};
`;

export const ActionButton = styled(StyledButton)`
  min-width: auto;
  height: 32px;
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
`;

export const TableActionButton = styled(ActionButton)`
  margin: 0 ${({ theme }) => theme.spacings.small};
  transition: all 0.3s ease;
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.shadows.small};
  }

  &:active {
    transform: translateY(0);
  }

  .anticon {
    font-size: 16px;
  }
`; 