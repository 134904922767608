import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    transition: background-color 0.3s ease;
  }

  .container {
    width: 100%;
    max-width: ${({ theme }) => theme.layout.maxWidth};
    margin-left: auto;
    margin-right: auto;
    padding-left: ${({ theme }) => theme.layout.containerPadding.mobile};
    padding-right: ${({ theme }) => theme.layout.containerPadding.mobile};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding-left: ${({ theme }) => theme.layout.containerPadding.tablet};
      padding-right: ${({ theme }) => theme.layout.containerPadding.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      padding-left: ${({ theme }) => theme.layout.containerPadding.desktop};
      padding-right: ${({ theme }) => theme.layout.containerPadding.desktop};
    }
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
  }
`;

export default GlobalStyle;
