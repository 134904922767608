import React from 'react';
import { Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface SearchFiltersProps {
  searchValue: string;
  selectedCategory: string | undefined;
  selectedStatus: 'active' | 'inactive' | undefined;
  onSearch: (value: string) => void;
  onCategoryChange: (value: string) => void;
  onStatusChange: (value: 'active' | 'inactive' | undefined) => void;
  categories: Array<{ id: string; name: string; }>;
}

export const SearchFilters: React.FC<SearchFiltersProps> = ({
  searchValue,
  selectedCategory,
  selectedStatus,
  onSearch,
  onCategoryChange,
  onStatusChange,
  categories
}) => {
  const { t } = useTranslation('products');

  return (
    <>
      <Input
        prefix={<SearchOutlined />}
        placeholder={t('search.placeholder')}
        value={searchValue}
        onChange={(e) => onSearch(e.target.value)}
        allowClear
      />
      <Select
        value={selectedCategory}
        onChange={onCategoryChange}
        placeholder={t('category.placeholder')}
        style={{ width: 200 }}
        allowClear
      >
        {categories.map((category) => (
          <Select.Option key={category.id} value={category.id}>
            {category.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={selectedStatus}
        onChange={onStatusChange}
        placeholder={t('status.placeholder')}
        style={{ width: 150 }}
        allowClear
      >
        <Select.Option value="active">{t('status.active')}</Select.Option>
        <Select.Option value="inactive">{t('status.inactive')}</Select.Option>
      </Select>
    </>
  );
}; 