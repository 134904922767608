import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

interface ButtonProps {
  $buttonStyle: 'primary' | 'secondary' | 'success' | 'error' | 'warning';
  $loading?: boolean;
}

const StyledButton = styled(AntdButton)<ButtonProps>`
  background-color: ${({ theme, $buttonStyle }) => theme.button.colors[$buttonStyle]};
  color: ${({ theme }) => theme.colors.lightgrey};
  padding: ${({ theme }) => theme.spacings.medium};
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  min-width: 150px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ theme, $buttonStyle }) => theme.colors.hover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey};
    cursor: not-allowed;
  }

  &::after {
    content: ${({ $loading }) => ($loading ? '"Loading..."' : '""')};
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.modalBackground};
    border-radius: inherit;
    transition: opacity 0.3s ease;
    opacity: ${({ $loading }) => ($loading ? 1 : 0)};
  }
`;

export default StyledButton;
