import React, { useMemo } from 'react';
import { Button, Dropdown, Modal } from 'antd';
import { MenuProps } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface BulkActionsProps {
  selectedRowKeys: number[];
  onBulkUpdateStatus: (status: 'active' | 'inactive') => void;
  onBulkDelete: () => void;
}

export const BulkActions: React.FC<BulkActionsProps> = ({
  selectedRowKeys,
  onBulkUpdateStatus,
  onBulkDelete
}) => {
  const { t } = useTranslation('products');
  const hasSelected = selectedRowKeys.length > 0;

  const bulkActionsMenu = useMemo(() => {
    const items: MenuProps['items'] = [
      {
        key: 'activate',
        label: t('actions.bulk.activate'),
        onClick: () => onBulkUpdateStatus('active')
      },
      {
        key: 'deactivate',
        label: t('actions.bulk.deactivate'),
        onClick: () => onBulkUpdateStatus('inactive')
      },
      {
        key: 'divider',
        type: 'divider'
      },
      {
        key: 'delete',
        label: t('actions.bulk.delete'),
        icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
        danger: true,
        onClick: onBulkDelete
      }
    ];

    return { items };
  }, [onBulkUpdateStatus, onBulkDelete, t]);

  if (!hasSelected) return null;

  return (
    <Dropdown menu={bulkActionsMenu} trigger={['click']}>
      <Button>
        {t('actions.bulk.selected', { count: selectedRowKeys.length })} <MoreOutlined />
      </Button>
    </Dropdown>
  );
}; 