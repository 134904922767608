import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Channel {
  id: number;
  name: string;
  active: boolean;
  status: string;
  orders: number;
  revenue: number;
  products: number;
  conversionRate: number;
}

export interface ChannelsState {
  channels: Channel[];
  isLoading: boolean;
  loading: boolean;
  error: string | null;
}

// Dummy data
const dummyChannels: Channel[] = [
  { id: 1, name: 'Trendyol', active: true, status: 'Aktif', orders: 150, revenue: 25000, products: 500, conversionRate: 3.5 },
  { id: 2, name: 'Hepsiburada', active: true, status: 'Aktif', orders: 100, revenue: 18000, products: 450, conversionRate: 3.2 },
  { id: 3, name: 'Amazon', active: false, status: 'Deaktif', orders: 0, revenue: 0, products: 300, conversionRate: 0 },
  { id: 4, name: 'N11', active: true, status: 'Aktif', orders: 80, revenue: 12000, products: 400, conversionRate: 2.8 },
  { id: 5, name: 'GittiGidiyor', active: true, status: 'Senkronizasyon Gerekli', orders: 60, revenue: 9000, products: 350, conversionRate: 2.5 },
];

const initialState: ChannelsState = {
  channels: dummyChannels,
  isLoading: false,
  loading: false,
  error: null,
};

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    fetchChannelsStart(state) {
      state.isLoading = true;
      state.loading = true;
      state.error = null;
    },
    fetchChannelsSuccess(state, action: PayloadAction<Channel[]>) {
      state.channels = action.payload;
      state.isLoading = false;
      state.loading = false;
    },
    fetchChannelsFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
      state.loading = false;
    },
  },
});

export const { fetchChannelsStart, fetchChannelsSuccess, fetchChannelsFailure } = channelsSlice.actions;
export default channelsSlice.reducer;
