import axiosInstance from '../../api/axios-config';
import { AxiosResponse } from 'axios';
import { IUser } from './login.reducer';

interface LoginResponse {
  message: string;
  id: string;
  token: string;
  refresh_token: string;
  email: string;
  name: string;
  surname: string;
  tenant: IUser['tenant'];
}

const UserService = {
  login(email: string, password: string): Promise<AxiosResponse<LoginResponse>> {
    return axiosInstance.post('/api/v1/login', { email, password });
  },

  register(name: string, surname: string, email: string, password: string): Promise<AxiosResponse> {
    return axiosInstance.post('/api/v1/users', { name, surname, email, password });
  },

  forgotPassword(email: string): Promise<AxiosResponse> {
    return axiosInstance.post('/api/v1/forgot-password', { email });
  },

  resetPassword(password: string, token: string): Promise<AxiosResponse> {
    return axiosInstance.post(
      '/api/v1/reset-password', 
      { 
        password,     // API'nin beklediği şekilde password
        reset_token: token  // token'ı reset_token olarak gönderiyoruz
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
  }
};

export default UserService;
