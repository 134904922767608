import React, { useState, useEffect, useMemo } from 'react';
import { Layout, Menu, Spin } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { 
  DashboardOutlined,
  ShoppingOutlined,
  BellOutlined,
  SettingOutlined,
  UserOutlined,
  GlobalOutlined,
  MenuOutlined,
  CloseOutlined,
  SketchOutlined,
  ClusterOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IGlobalState } from '../../redux/reducers';
import { 
  StyledSider, 
  Logo, 
  CollapseButton, 
  ThemeToggle, 
  BottomSection, 
  SectionTitle,
  LanguageToggle,
  LanguageButton,
  MobileMenuButton,
  StyledDrawer,
  DrawerHeader,
  DrawerLogo,
  DrawerContent,
  SubMenuItem,
  MenuItemStyled,
  ChannelSubmenu,
  MenuItemContent,
  IconWrapper
} from './sidebar.styled';
import { motion, AnimatePresence } from 'framer-motion';
import enTranslations from './translations/en.json';
import trTranslations from './translations/tr.json';
import { toggleTheme } from '../../redux/reducers/theme.reducer';
import styled from 'styled-components';
import type { MenuProps } from 'antd';

const { SubMenu } = Menu;

const ChannelMenuItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const ChannelIcon = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  right: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
    transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: rotate(${props => props.$isOpen ? 180 : 0}deg);
    fill: currentColor;
  }
`;

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const { t, i18n } = useTranslation('sidebar');
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const channels = useSelector((state: IGlobalState) => state.channels.channels);
  const [channelsOpen, setChannelsOpen] = useState(false);
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: IGlobalState) => state.theme.isDarkMode);

  useEffect(() => {
    const loadTranslations = async () => {
      await i18n.addResourceBundle('en', 'sidebar', enTranslations.sidebar, true, true);
      await i18n.addResourceBundle('tr', 'sidebar', trTranslations.sidebar, true, true);
      setIsLoading(false);
    };

    loadTranslations();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [i18n]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const toggleMobileDrawer = () => {
    setMobileDrawerVisible(!mobileDrawerVisible);
  };

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  // Seçili menü öğelerini belirlemek için bir useMemo hook'u ekleyelim
  const selectedKeys = useMemo(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    return pathSegments.map((_, index) => '/' + pathSegments.slice(0, index + 1).join('/'));
  }, [location.pathname]);

  const getProductsPath = () => {
    return i18n.language === 'tr' ? '/urunler' : '/products';
  };

  const handleChannelClick: MenuProps['onClick'] = (info) => {
    if ((info.domEvent.target as HTMLElement).closest('.channel-dropdown-icon')) {
      info.domEvent.preventDefault();
      info.domEvent.stopPropagation();
      setChannelsOpen(!channelsOpen);
    }
  };

  const menuItems = [
    { key: '/dashboard', icon: <DashboardOutlined />, label: t('dashboard'), link: '/dashboard' },
    { key: '/products', icon: <ShoppingOutlined />, label: t('products'), link: '/products' },
    { key: '/design', icon: <SketchOutlined />, label: t('design'), link: '/design' },
    { 
      key: '/channels', 
      icon: <ClusterOutlined />, 
      label: (
        <ChannelMenuItem>
          <Link to="/channels">{t('channels')}</Link>
          {!collapsed && (
            <ChannelIcon 
              $isOpen={channelsOpen}
              className="channel-dropdown-icon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setChannelsOpen(!channelsOpen);
              }}
            >
              <CaretDownOutlined />
            </ChannelIcon>
          )}
        </ChannelMenuItem>
      ), 
      link: '/channels' 
    },
  ];

  const accountItems = [
    { key: '/notifications', icon: <BellOutlined />, label: t('notifications'), link: '/notifications' },
    { 
      key: '/account-settings', 
      icon: <SettingOutlined />, 
      label: t('accountSettings'), 
      link: '/account-settings' 
    },
  ];

  const renderSidebarContent = () => (
    <>
      <DrawerContent>
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          style={{ 
            flex: 1, 
            paddingTop: '16px',
            backgroundColor: 'transparent',
            border: 'none'
          }}
        >
          {menuItems.map((item) => (
            <React.Fragment key={item.key}>
              <Menu.Item 
                key={item.key} 
                icon={item.icon}
                onClick={item.key === '/channels' ? handleChannelClick : undefined}
              >
                {typeof item.label === 'string' ? (
                  <Link to={item.link}>{item.label}</Link>
                ) : (
                  item.label
                )}
              </Menu.Item>
              {item.key === '/channels' && !collapsed && channelsOpen && (
                <AnimatePresence>
                  {channelsOpen && (
                    <ChannelSubmenu
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {channels.map((channel) => (
                        <Menu.Item 
                          key={`/channels/${channel.id}`}
                          style={{
                            margin: '2px 16px 2px 16px',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Link to={`/channels/${channel.id}`} style={{ width: '100%' }}>
                            <MenuItemStyled active={`/channels/${channel.id}` === location.pathname}>
                              {channel.active ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                              <span style={{ marginLeft: '8px', fontSize: '0.9em' }}>{channel.name}</span>
                            </MenuItemStyled>
                          </Link>
                        </Menu.Item>
                      ))}
                    </ChannelSubmenu>
                  )}
                </AnimatePresence>
              )}
            </React.Fragment>
          ))}
        </Menu>
      </DrawerContent>
      <BottomSection collapsed={isMobile ? false : collapsed}>
        <SectionTitle collapsed={isMobile ? false : collapsed}>
          {isMobile || !collapsed ? t('account') : <UserOutlined />}
        </SectionTitle>
        <Menu mode="inline" selectedKeys={selectedKeys}>
          {accountItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.link}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
        <ThemeToggle 
          onClick={handleThemeToggle} 
          collapsed={isMobile ? false : collapsed}
          isDark={isDarkMode}
        >
          <span>{isDarkMode ? '🌙' : '☀️'}</span>
          {(!collapsed || isMobile) && (
            <span style={{ marginLeft: '8px' }}>
              {t(isDarkMode ? 'darkMode' : 'lightMode')}
            </span>
          )}
        </ThemeToggle>
        <LanguageToggle collapsed={isMobile ? false : collapsed}>
          {(isMobile || !collapsed) && <GlobalOutlined style={{ marginRight: '8px' }} />}
          <LanguageButton 
            onClick={() => changeLanguage('tr')} 
            isActive={i18n.language === 'tr'}
            collapsed={isMobile ? false : collapsed}
          >
            TR
          </LanguageButton>
          <LanguageButton 
            onClick={() => changeLanguage('en')} 
            isActive={i18n.language === 'en'}
            collapsed={isMobile ? false : collapsed}
          >
            EN
          </LanguageButton>
        </LanguageToggle>
      </BottomSection>
    </>
  );

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <>
      {!isMobile && (
        <StyledSider
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          trigger={null}
          width={240}
          collapsedWidth={80}
        >
          <Logo $collapsed={collapsed}>
            {collapsed ? (
              <span className="logo-symbol">△</span>
            ) : (
              <>
                <span className="logo-symbol">△</span>
                {t('laplace')}
              </>
            )}
          </Logo>
          <CollapseButton onClick={toggleCollapsed}>
            <motion.svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              animate={{ rotate: collapsed ? 0 : 180 }}
              transition={{ duration: 0.3 }}
            >
              <path
                d="M9 6L15 12L9 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </motion.svg>
          </CollapseButton>
          {renderSidebarContent()}
        </StyledSider>
      )}
      {isMobile && (
        <MobileMenuButton onClick={toggleMobileDrawer}>
          <MenuOutlined />
        </MobileMenuButton>
      )}
      <StyledDrawer
        title={null}
        placement="left"
        closable={false}
        onClose={toggleMobileDrawer}
        visible={isMobile && mobileDrawerVisible}
        width={240}
      >
        <DrawerHeader>
          <DrawerLogo>△ {t('laplace')}</DrawerLogo>
          <CloseOutlined onClick={toggleMobileDrawer} style={{ marginLeft: 'auto' }} />
        </DrawerHeader>
        {renderSidebarContent()}
      </StyledDrawer>
    </>
  );
};

export default Sidebar;
