import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

interface AnimatedTextProps {
  text: string;
}

const AnimatedTextWrapper = styled(motion.h1)`
  text-align: center;
  font-size: 3rem;
  font-weight: extrabold;
  letter-spacing: -0.05em;
  font-family: 'Orbitron', sans-serif;
  color: ${({ theme }) => theme.colors.text};
`;

const AnimatedText: React.FC<AnimatedTextProps> = ({ text }) => {
  return (
    <AnimatedTextWrapper
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {text}
    </AnimatedTextWrapper>
  );
};

export default AnimatedText;
